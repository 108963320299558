import { ReactElement } from "react";

import { TPlanFactStatistic } from "core/shared/dashboard/hooks/interfaces";
import { formattedSafePercent } from "core/shared/tools/safePercent";

interface ICellProps {
  value: TPlanFactStatistic;
  baseValue?: TPlanFactStatistic;
  isMounted?: boolean;
}

function DifferenceCell({ value, baseValue, isMounted = false }: ICellProps): ReactElement {
  const val = value.find((item) => item.is_mounted === isMounted);
  if (!val) {
    return <div />;
  }
  const baseVal = baseValue?.find((item) => item.is_mounted === isMounted) ?? val;
  const difference = baseVal.amount - val.amount;
  return (
    <span>
      {difference} {formattedSafePercent(difference, baseVal.amount)}
    </span>
  );
}

export { DifferenceCell };
