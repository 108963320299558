import { Col } from "antd";

import { useUnitId } from "core/shared/unit/useUnitId";
import dayjs, { apiDateFormat, apiDateTimeFormat } from "core/shared/tools/dayjs";
import { IDictionaryItem } from "core/shared/interfaces";
import { useUnit } from "core/shared/unit/useUnit";
import EffortInput from "core/widgets/CreateTaskModal/Fields/EffortInput";
import AttachmentsField from "core/widgets/CreateTaskModal/Fields/AttachmentsField";
import ShiftSelector from "core/widgets/CreateTaskModal/Fields/ShiftSelector";
import WorkTypeField from "core/widgets/CreateTaskModal/Fields/WorkTypeField";
import { EffortTypeEnum } from "core/models/enums";
import { TSendOtherSlpiTaskBody } from "core/app/api/units/SLPI/types";
import { StateNamesEnum, TaskTypesIdsEnum } from "core/app/api/task/enums";
import { IAttachment } from "core/app/api/task/interfaces";
import { useGetTaskStatesQuery } from "core/app/api/task";
import { useCreateSlpiTaskMutation } from "core/app/api/units/SLPI";

import { CreateTaskBaseForm } from "../../CreateTaskBaseForm";
import { Customer } from "../../../Fields/Customer";
import { Department } from "../../../Fields/Department";
import { Description } from "../../../Fields/Description";

import { Object } from "./Fields/Object";
import { SystemComplex } from "./Fields/SystemComplex";

export function Slpi() {
  const unit = useUnit();
  const [unitId] = useUnitId();
  const [sendTask, sendTaskResult] = useCreateSlpiTaskMutation();
  const { data: states } = useGetTaskStatesQuery("");
  const initialValues = {
    customerId: null,
    equipmentTypeId: null,
    equipmentId: null,
    locationId: null,
    description: "",
    attachments: [],
    effort: 0,
    teamId: null,
    shiftNumber: 1 as 1 | 2 | 3,
    plannedDate: "",
    horizonId: null,
    departmentId: null,
    objectId: null,
    systemComplexId: null,
    workTypeId: null,
    objectIsMissing: false,
  };

  const createTask = async (values: typeof initialValues) => {
    if (!unitId || !states) {
      return Promise.reject();
    }

    const state = states.results.find((item: IDictionaryItem) => item.name === StateNamesEnum.New);

    const body: TSendOtherSlpiTaskBody = {
      type: TaskTypesIdsEnum.OTHER,
      state_id: state?.id,
      unit_id: unitId,
      request_description: values.description,
      effort: values.effort,
      customer_id: values.customerId,
      customer_department_id: values.departmentId,
      service_id: unit?.service.id,
      request_creation_datetime: dayjs().format(apiDateTimeFormat),
      object_id: values.objectId,
      system_complex_id: values.systemComplexId,
      work_type_id: values.workTypeId,
    };
    if (values.equipmentId) {
      body.is_mounted = false;
    }
    if (values.teamId) {
      body.team_id = values.teamId;
    }
    if (values.shiftNumber) {
      body.shift_number = values.shiftNumber;
    }
    if (values.plannedDate) {
      body.planned_date = dayjs(values.plannedDate).format(apiDateFormat);
    }
    if (values.attachments) {
      body.attachments = {
        files_by_task: values.attachments as IAttachment[],
        work_place: [],
        before_or_in_progress: [],
        after_completion: [],
      };
    }

    return sendTask(body).unwrap();
  };

  return (
    <CreateTaskBaseForm
      initialValues={initialValues}
      createTaskFunction={createTask}
      createTaskIsLoading={sendTaskResult.isLoading}
      shouldCancelSubmit={!unitId || !states}
    >
      <Col span={24}>
        <WorkTypeField />
      </Col>
      <Col span={12}>
        <Customer />
      </Col>
      <Col span={12}>
        <Department />
      </Col>
      <Col span={12}>
        <Object />
      </Col>
      <Col span={12}>
        <SystemComplex />
      </Col>
      <Col span={12}>
        <EffortInput effortType={EffortTypeEnum.MAN_HOURS} required />
      </Col>
      {/* <Col span={12}>
        <ObjectIsMissing />
      </Col> */}
      <Col span={24}>
        <Description />
      </Col>
      <Col span={24}>
        <AttachmentsField />
      </Col>
      <Col className="mb-1 mt-1" span={24}>
        <ShiftSelector />
      </Col>
    </CreateTaskBaseForm>
  );
}
