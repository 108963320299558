import { Row, Col, Button } from "antd";
import { useLocation } from "react-router-dom";

import { useSignatureStatus } from "core/shared/task/hooks/useSignatureStatus";
import { useDisabledByLocation } from "core/shared/task/hooks/useDisabledByLocation";
import { RoleEnum } from "core/shared/enums";
import { CanIView } from "core/shared/components/CanIView";
import { SignatureSideEnum } from "core/app/api/enums";
import { StateNamesEnum } from "core/app/api/task/enums";
import { ITask } from "core/app/api/task/interfaces";
import { useGetTaskSignatureQuery } from "core/app/api/task";
import { AppRoutes } from "core/shared/constants.routes";

import { SignatureCompleted } from "./SignatureCompleted";
import useFetchOfflineSignatures from "./hooks/useFetchOfflineSignatories";

interface SignatureButtonsProps {
  task: ITask;
}

function SignatureButtons({ task }: SignatureButtonsProps) {
  const [, setSignatureStatus] = useSignatureStatus();
  const { data, isSuccess, isFetching } = useGetTaskSignatureQuery(task.id);
  const { pathname } = useLocation();
  let isDisabledByLocation = useDisabledByLocation();
  const { customerSignatureInfo, executorSignatureInfo } = useFetchOfflineSignatures(task);

  /**
   * Исключение, кнопки доступны в планировании
   */
  if (pathname === AppRoutes.pspa) {
    isDisabledByLocation = false;
  }

  const showCustomerSignature =
    (isSuccess && !isFetching && data.customer_signature) || customerSignatureInfo.signatory;
  const showExecutorSignature =
    (isSuccess && !isFetching && data.executor_signature) || executorSignatureInfo.signatory;

  const customerName =
    customerSignatureInfo.signatory?.fullName || data?.customer_signature?.customer_representative?.full_name || "";
  const customerPosition =
    customerSignatureInfo.signatory?.position || data?.customer_signature?.customer_representative?.position_name || "";
  const executorName = executorSignatureInfo.signatory?.fullName || data?.executor_signature?.executor?.full_name || "";
  const executorPosition =
    executorSignatureInfo.signatory?.position || data?.executor_signature?.executor?.position || "";

  return (
    <>
      <Row gutter={36} className="mt-3">
        <Col span={12}>
          {showCustomerSignature && (
            <SignatureCompleted
              taskId={task.id}
              side={SignatureSideEnum.Customer}
              fio={customerName}
              position={customerPosition}
              localSignature={customerSignatureInfo.signature}
            />
          )}
        </Col>
        <Col span={12}>
          {showExecutorSignature && (
            <SignatureCompleted
              taskId={task.id}
              side={SignatureSideEnum.Executor}
              fio={executorName}
              position={executorPosition}
              localSignature={executorSignatureInfo.signature}
            />
          )}
        </Col>
      </Row>
      {task.state?.name === StateNamesEnum.Closed && (
        <Row gutter={36} className="mt-3">
          <Col span={12}>
            <CanIView
              roles={[
                RoleEnum.SLPI_WORKSHOP_LEAD,
                RoleEnum.PSPA_WORKSHOP_LEAD,
                RoleEnum.CUSTOMER_REPRESENTATIVE_SIGNATORY,
                RoleEnum.SHIFT_LEAD,
                RoleEnum.UNIT_LEAD,
                RoleEnum.TEAM_LEAD,
              ]}
            >
              <Button
                disabled={isDisabledByLocation}
                onClick={() => {
                  setSignatureStatus(SignatureSideEnum.Customer);
                }}
                style={{ width: "100%" }}
                type="primary"
              >
                ПОДПИСАТЬ - ЗАКАЗЧИК
              </Button>
            </CanIView>
          </Col>
          <Col span={12}>
            <CanIView
              roles={[
                RoleEnum.SLPI_WORKSHOP_LEAD,
                RoleEnum.PSPA_WORKSHOP_LEAD,
                RoleEnum.SHIFT_LEAD,
                RoleEnum.UNIT_LEAD,
                RoleEnum.TEAM_LEAD,
              ]}
            >
              <Button
                disabled={isDisabledByLocation}
                style={{ width: "100%" }}
                type="primary"
                onClick={() => {
                  setSignatureStatus(SignatureSideEnum.Executor);
                }}
              >
                ПОДПИСАТЬ - ИСПОЛНИТЕЛЬ
              </Button>
            </CanIView>
          </Col>
        </Row>
      )}
    </>
  );
}

export { SignatureButtons };
