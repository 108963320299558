import { Row, Col } from "antd";
import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";
import { isOossaTask, isPspaTask, isSlpiTask } from "core/shared/task";
import {
  PlannedDate,
  Customer,
  Location,
  Unit,
  Address,
  AccumulatorElapsedTime,
} from "core/widgets/TaskModals/shared/Fields";
import { ZabbixAlert } from "core/widgets/Equipment/ZabbixAlert";
import OossaMaintenanceTaskGeneralInfo from "core/widgets/Oossa/OossaMaintenanceTaskContent/OossaMaintenanceTaskGeneralInfo";
import { Deadline } from "core/widgets/TaskModals/shared/Fields/Deadline";

import SLPIGeneralInfo from "./SLPI";

interface GeneralInfoProps {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке ТО стационарного оборудования ПСПА, СДГР, СЛПИ, ООССА. */
function GeneralInfo(props: GeneralInfoProps): ReactElement {
  const { task } = props;

  if (isSlpiTask(task)) {
    return <SLPIGeneralInfo task={task} />;
  }

  if (isOossaTask(task)) {
    return <OossaMaintenanceTaskGeneralInfo task={task} />;
  }

  return (
    <>
      <ZabbixAlert equipmentId={task.equipment?.id} />
      <Row className="mb-1">
        <Col>
          {isPspaTask(task) ? <Deadline task={task} /> : <PlannedDate task={task} />}
          <Customer task={task} />
          <Unit task={task} />
          <Location task={task} />
          <Address task={task} />
          <AccumulatorElapsedTime task={task} />
        </Col>
      </Row>
    </>
  );
}

export default GeneralInfo;
