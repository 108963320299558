import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";

import { MaintenanceExecution } from "./MaintenanceExecution";

interface Props {
  task: ITask;
}

/** Раздел выполнения работ в карточке технического обслуживания (ТО).
 * Используется для стационарного оборудования ПСПА, СДГР, СЛПИ. */
export function MaintenanceExecutions({ task }: Props): ReactElement {
  return <MaintenanceExecution maintenanceExecution={task.maintenance_execution[0]} index={0} />;
}
