import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";
import { formatHours } from "core/shared/tools/dayjs";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

/** Атрибут "Плановые трудозатраты" в карточке ТО. */
export function PlannedEffort({ task }: FieldProps): ReactElement {
  const value = formatHours(task.effort ?? 0);
  return <Field label="Плановые трудозатраты" value={value} />;
}
