import { Button } from "antd";
import { MouseEventHandler } from "react";
import { NavLink } from "react-router-dom";

import { CanIView, ICanIViewProps } from "core/shared/components/CanIView";

export interface IAppBarMenuElementProps {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export interface IAppBarMenuItemProps extends IAppBarMenuElementProps {
  caption: string;
  url?: string;
  isTargetBlank?: boolean;
  canIViewProps?: Omit<ICanIViewProps, "children">;
  isDisabled?: boolean;
}

export function AppBarMenuItem({
  caption,
  url,
  isTargetBlank,
  canIViewProps,
  isDisabled = false,
  onClick,
}: IAppBarMenuItemProps) {
  return (
    <CanIView {...canIViewProps}>
      <div>
        {url ? (
          <NavLink target={isTargetBlank ? "_blank" : undefined} to={url} onClick={isDisabled ? undefined : onClick}>
            <Button type="link" size="large" disabled={isDisabled}>
              {caption}
            </Button>
          </NavLink>
        ) : (
          <Button type="link" size="large" onClick={onClick} disabled={isDisabled}>
            {caption}
          </Button>
        )}
      </div>
    </CanIView>
  );
}
