import { ReactElement } from "react";
import { Row, Col } from "antd";

import { IGroup, isGroup } from "core/shared/dashboard";
import { useTaskId } from "core/shared/task/hooks/useTaskId";
import { IDashboardShiftStatisticTasksByTeamsSlpiTask } from "core/app/api/units/SLPI/interfaces";

interface CellProps {
  record: IDashboardShiftStatisticTasksByTeamsSlpiTask | IGroup<IDashboardShiftStatisticTasksByTeamsSlpiTask>;
}

const styles = {
  cursor: "pointer",
  color: "#1890ff",
};

function InventoryNumber({ record }: CellProps): ReactElement {
  const [, setTaskId] = useTaskId();
  if (isGroup(record)) {
    return <div>{record.name}</div>;
  }

  const invNumber = record.inventory_number || "Без номера";

  const handleShowTask = async () => {
    setTaskId(record.id);
  };

  return (
    <Row onClick={handleShowTask}>
      <Col style={styles}>{invNumber}</Col>
    </Row>
  );
}

export default InventoryNumber;
