import { Col, Form, Row } from "antd";
import clsx from "clsx";

import NoteTextArea from "core/widgets/TaskModals/Recovery/Body/NoteTextArea";
import { ElapsedTime } from "core/widgets/TaskModals/shared/ElapsedTime";
import { EmployeesSelect } from "core/widgets/TaskModals/shared/EmployeesSelect/FormControlled";
import { useDisabled } from "core/shared/task/hooks/useDisabled";
import { ClosingDate } from "core/widgets/TaskModals/shared/ClosingDate";

import { useLsoMaintenanceContext } from "../../hooks";

function ClosingFields() {
  const form = Form.useFormInstance();
  const { task } = useLsoMaintenanceContext();
  const disabled = useDisabled();

  if (!task) {
    return null;
  }

  return (
    <Row gutter={16} className={clsx("mb-2", "mt-2")}>
      <Col span={12}>
        <div className="mb-8">
          <EmployeesSelect teamId={task.team_id} disabled={disabled} required />
        </div>
        <Row gutter={8} align="bottom">
          <ElapsedTime useMultiplier={false} disabled={disabled} />
          <Col span={12}>
            <ClosingDate value={task.closing_date} />
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <div className="mb-3">
          <NoteTextArea form={form} disabled={disabled} />
        </div>
      </Col>
    </Row>
  );
}

export default ClosingFields;
