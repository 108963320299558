import { ILocalAttachment } from "core/app/api/task/interfaces";

type TAttachmentGalleryItemProps = {
  attachment: ILocalAttachment;
};

function AttachmentGalleryItem({ attachment }: TAttachmentGalleryItemProps) {
  return (
    <>
      {attachment.mimeType.includes("video") && (
        <video controls width="100%">
          <source src={attachment.file} type={attachment.mimeType} />
        </video>
      )}
      {attachment.mimeType.includes("image") && (
        <img style={{ height: "100%", objectFit: "contain" }} src={attachment.file} alt={attachment.originalName} />
      )}
      {/* <p className="legend">
        {mediaCategoryTitles[attachment.category]} в {dayjs(attachment.created_at).format(displayTimeFormat)}
      </p> */}
    </>
  );
}

export default AttachmentGalleryItem;
