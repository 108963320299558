import { ReactElement } from "react";
import { Select, Form } from "antd";
import qs from "qs";

import { IDictionaryItem } from "core/shared/interfaces";
import { useUnitId } from "core/shared/unit/useUnitId";
import dayjs, { apiDateFormat } from "core/shared/tools/dayjs";
import { TaskTypesIdsEnum } from "core/app/api/task/enums";
import { useGetTransportQuery } from "core/app/api";

interface TransportFieldProps {
  required?: true;
  taskType?: TaskTypesIdsEnum;
}

function TransportField({ required, taskType }: TransportFieldProps): ReactElement {
  const [unitId] = useUnitId();
  const form = Form.useFormInstance();
  const locationId = Form.useWatch("locationId");
  const equipmentId = Form.useWatch("equipmentId");

  const queryParams: Record<string, string | number> = {
    active_for: dayjs().format(apiDateFormat),
  };
  if (unitId) {
    queryParams.unit = unitId;
  }
  if (locationId) {
    queryParams.location = locationId;
  }
  const queryString = qs.stringify(queryParams);
  const { data, isFetching } = useGetTransportQuery(queryString);

  const options = (data?.results || []).map((item: IDictionaryItem) => ({
    label: item.name,
    value: item.id,
  }));

  const handleChange = (val: number) => {
    const transport = (data?.results || []).find((item) => item.id === val);
    if (transport) {
      form.setFieldValue("locationId", transport.location_id);
    } else {
      form.setFieldValue("locationId", null);
    }
    form.setFieldValue("equipmentTypeId", null);
    form.validateFields(["locationId"]);
  };

  let disabled = false;
  if (taskType === TaskTypesIdsEnum.OTHER && equipmentId) {
    disabled = true;
  }

  return (
    <Form.Item label="ТРАНСПОРТ" name="transportId" colon={false} rules={required && [{ required: true, message: "" }]}>
      <Select
        loading={isFetching}
        size="large"
        allowClear
        disabled={disabled}
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleChange}
      />
    </Form.Item>
  );
}

export default TransportField;
