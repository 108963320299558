import { Form, Select } from "antd";

import { useDisabled } from "core/shared/task/hooks/useDisabled";
import { useGetNoPhotoReasonsQuery } from "core/app/api/task";

export function ReasonSelect() {
  const disabled = useDisabled();
  const { data, isFetching } = useGetNoPhotoReasonsQuery("");

  return (
    <Form.Item
      label="ПРИЧИНА"
      name="no_photo_reason_type_id"
      required
      rules={[
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.reject(new Error("Пожалуйста, выберите значение"));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Select
        disabled={disabled}
        loading={isFetching}
        options={data?.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
      />
    </Form.Item>
  );
}
