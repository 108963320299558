import { CloseCircleFilled } from "@ant-design/icons";
import { Button, Col, Row } from "antd";

import Text from "core/lib/Text/Text";
import dayjs, { displayDateFormat, hmTimeFormat } from "core/shared/tools/dayjs";
import { ILocalAttachment } from "core/app/api/task/interfaces";
import FileTypeIcon from "core/shared/components/FileTypeIcon";

import "./styles.scss";

type TAttachmentListItemProps = {
  attachment: ILocalAttachment;
  onDelete: (attachment: ILocalAttachment) => void;
  onSelect: (attachment: ILocalAttachment) => void;
};

function AttachmentListItem({ attachment, onDelete, onSelect }: TAttachmentListItemProps) {
  const handleDelete = () => {
    onDelete(attachment);
  };

  const handleSelect = () => {
    onSelect(attachment);
  };

  return (
    <div className="attachment-list__item">
      <Button type="link" className="attachment-list__item__button" onClick={handleDelete}>
        <CloseCircleFilled style={{ fontSize: "24px", color: "#bcceda" }} />
      </Button>

      <Row gutter={4} className="attachment-list__item__content" onClick={handleSelect}>
        <Col span={8} style={{ alignSelf: "center" }}>
          <FileTypeIcon originalName={attachment.originalName} mimeType={attachment.mimeType} />
        </Col>
        <Col span={16} style={{ alignSelf: "center", textAlign: "left" }}>
          <div>
            <Text size="label3" boldness="semibold">
              {dayjs(attachment.lastModifiedDate).format(displayDateFormat)}
            </Text>
          </div>
          <div>
            <Text size="label3" boldness="semibold">
              в {dayjs(attachment.lastModifiedDate).format(hmTimeFormat)}
            </Text>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default AttachmentListItem;
