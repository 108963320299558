import { Form, Select } from "antd";
import qs from "qs";

import { useUnit } from "core/shared/unit/useUnit";
import { useGetEquipmentTypesQuery } from "core/app/api";
import { TaskTypesIdsEnum } from "core/app/api/task/enums";

interface EquipmentTypeFieldProps {
  required?: true;
  taskType?: TaskTypesIdsEnum;
}

function EquipmentTypeField({ required, taskType }: EquipmentTypeFieldProps) {
  const isMounted = Form.useWatch("is_mounted");
  const transportId = Form.useWatch("transport_id");
  const unit = useUnit();
  const form = Form.useFormInstance();

  const queryParams: Record<string, string | number | boolean | undefined | null> = {
    is_mounted: isMounted,
  };

  if (unit) {
    queryParams.service = unit.service.id;
    queryParams.unit_id = unit.id;
  }
  if (transportId) {
    queryParams.transport = transportId;
  }

  const { data, isFetching } = useGetEquipmentTypesQuery(qs.stringify(queryParams));

  const options = (data?.results || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <Form.Item label="ТИП ОБОРУДОВАНИЯ" name="equipment_type_id" rules={required && [{ required: true, message: "" }]}>
      <Select
        className="equipment-type-field"
        allowClear
        showSearch
        loading={isFetching}
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={() => {
          if (!isMounted) {
            form.setFieldValue("equipment_id", null);
          }
        }}
      />
    </Form.Item>
  );
}

export default EquipmentTypeField;
