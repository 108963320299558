import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";
import { IEtoTask } from "core/app/api/task/eto/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask | IEtoTask;
}

/** Атрибут "Участок" в карточке ТО. */
function Unit({ task }: FieldProps): ReactElement {
  return <Field label="Участок" value={task.unit?.name} />;
}

export { Unit };
