import { Select, Form, FormInstance } from "antd";
import { ReactElement } from "react";

import { useObjectSystemComplexes } from "core/shared/slpi/hooks/useObjectSystemComplexes";
import { getSystemsString } from "core/pages/SLPI/Yearly/SlpiObject/tools";

interface ComplexSystemSelectProps {
  disabled: boolean;
  form: FormInstance;
}

function ComplexSystemSelect({ disabled, form }: ComplexSystemSelectProps): ReactElement {
  const objectId = Form.useWatch("object_id");

  const { data: systemComplexes } = useObjectSystemComplexes({ object_id: objectId });

  let options = [
    {
      label: "",
      value: 0,
    },
  ];
  if (systemComplexes) {
    options = systemComplexes.map((systemComplex) => {
      let label = "";
      if (systemComplex.inventory_number) {
        label = `(${systemComplex.inventory_number}) ${getSystemsString(systemComplex.systems)}`;
      } else {
        label = getSystemsString(systemComplex.systems);
      }
      return {
        label,
        value: systemComplex.id,
      };
    });
  }

  return (
    <Form.Item label="КОМПЛЕКС СИСТЕМ" name="system_complex_id">
      <Select
        disabled={disabled || !objectId}
        style={{ width: "100%" }}
        showSearch
        allowClear
        optionFilterProp="children"
        dropdownStyle={{ zIndex: 2000 }}
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
        options={options}
      />
    </Form.Item>
  );
}

export { ComplexSystemSelect };
