import { skipToken } from "@reduxjs/toolkit/query/react";
import qs from "qs";

import { useUnitId } from "core/shared/unit/useUnitId";
import { useGetUnitDepartmentsQuery } from "core/app/api";

export function useUnitDepartments(filters?: Record<string, any>) {
  const [unitId] = useUnitId();
  const skip = !unitId || unitId === -1;
  const queryString = qs.stringify({
    unit_id: unitId,
    ...filters,
  });
  return useGetUnitDepartmentsQuery(!skip ? queryString : skipToken);
}
