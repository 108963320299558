export enum EtoTaskAttachmentCategoryEnum {
  ShiftStart = "shift_start",
  ShiftEnd = "shift_end",
  Other = "other",
}

export enum EtoTaskStepNameEnum {
  PRE_SHIFT_CHECK = "PRE_SHIFT_CHECK",
  PERFORMING_ETO = "PERFORMING_ETO",
  POST_SHIFT_CHECK = "POST_SHIFT_CHECK",
}
