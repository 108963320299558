import { AppBar as MuiAppBar, Toolbar } from "@mui/material";
import { useState, MouseEvent, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Row, Col, message } from "antd";
import Drawer from "@mui/material/Drawer";

import nnLogo from "core/images/nn-logo.svg";
import { AppRoutes } from "core/shared/constants.routes";
import { useAppDispatch } from "core/app/store";
import { logOut } from "core/app/store/token-slice";
import { util } from "core/app/api";
import { usePrintTaskReportModalOpen } from "core/shared/tasks/usePrintTaskReportModalOpen";
import { PrintTaskReportModalStatesEnum } from "core/app/store/temp-slice";
import { useFullScreen } from "core/shared/main/store/useFullScreen";
import { rolesWitoutRepresentativesAndCatalogAdmin } from "core/shared/constants";
import { UnitServiceCodesEnum } from "core/shared/unit/types";
import { useLazyGetStationaryStatusReportQuery } from "core/app/api/reports";
import { RoleEnum } from "core/shared/enums";

import UploadFileWorker from "../BackgroundUpload";

import VersionNumber from "./VersionNumber";
import { UserName } from "./UserName";
import { OfflineSelector } from "./OfflineSelector";
import { TasksButton } from "./TasksButton";
import { AppBarMenuSection } from "./AppBarMenuSection";
import { AppBarMenuItem } from "./AppBarMenuSection/AppBarMenuItem";

function AppBar() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [, setPrintTaskReportModalState] = usePrintTaskReportModalOpen();
  const [isFullScreen] = useFullScreen();
  const [runStatReportRequest, { isLoading: isLoadingStatReport }] = useLazyGetStationaryStatusReportQuery();

  const handleMenuClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleExitClick = useCallback(() => {
    dispatch(logOut());
    dispatch(util.resetApiState());
  }, [dispatch]);

  const container = window !== undefined ? () => document.body : undefined;

  if (isFullScreen) {
    return <div />;
  }

  return (
    <>
      <MuiAppBar style={{ zIndex: 600, height: "48px" }}>
        <Toolbar style={{ height: "100%", minHeight: 0 }}>
          <Row style={{ width: "100%" }} justify="space-between">
            <Col>
              <Row align="middle" gutter={32}>
                <Col>
                  <Button variant="outlined" color="primary" ghost onClick={handleMenuClick} icon={<MenuOutlined />}>
                    МЕНЮ
                  </Button>
                </Col>
                <Col>
                  <NavLink to={AppRoutes.base}>
                    <img src={nnLogo} alt="logo" style={{ verticalAlign: "middle" }} />
                  </NavLink>
                </Col>
              </Row>
            </Col>
            <Col style={{ alignSelf: "center" }}>
              <OfflineSelector />
            </Col>
            <Col>
              <Row gutter={32} align="middle">
                <Col>
                  <TasksButton />
                </Col>
                <Col>
                  <UserName />
                </Col>
                <Col>
                  <Button onClick={handleExitClick} type="text">
                    ВЫЙТИ
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <UploadFileWorker />
        </Toolbar>
      </MuiAppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{ style: { background: "#e2ebf0", padding: "16px" } }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="text" onClick={handleMenuClose} icon={<CloseOutlined />}>
            Закрыть
          </Button>
        </div>

        <AppBarMenuSection
          label="Сменное задание"
          canIViewProps={{ roles: rolesWitoutRepresentativesAndCatalogAdmin }}
          onItemClick={handleMenuClose}
        >
          <AppBarMenuItem caption="Формирование бригад" url={AppRoutes.teams} />
          <AppBarMenuItem caption="Формирование наряд задания" url={AppRoutes.tasks} />
          <AppBarMenuItem caption="Распределение задач по бригадам" url={AppRoutes.tasksForTeams} />
          <AppBarMenuItem caption="Задачи на смену" url={AppRoutes.taskForShift} />
        </AppBarMenuSection>

        <AppBarMenuSection
          label="Планирование ТО/Персонала"
          canIViewProps={{ withoutRoles: [RoleEnum.CATALOG_ADMIN] }}
          onItemClick={handleMenuClose}
        >
          <AppBarMenuItem
            caption="Техническое обслуживание ПСПА"
            url={AppRoutes.pspa}
            canIViewProps={{
              serviceCodes: [UnitServiceCodesEnum.PSPA_ZF],
              not: [[RoleEnum.CATALOG_ADMIN, UnitServiceCodesEnum.PSPA_ZF]],
            }}
          />
          <AppBarMenuItem
            caption="Техническое обслуживание СЛПИ"
            url={AppRoutes.slpi}
            canIViewProps={{ serviceCodes: [UnitServiceCodesEnum.SLPI_KF, UnitServiceCodesEnum.SLPI_ZF] }}
          />
          <AppBarMenuItem
            caption="Техническое обслуживание ООССА"
            url={AppRoutes.oossa}
            canIViewProps={{ serviceCodes: [UnitServiceCodesEnum.OOSSA] }}
          />
          <AppBarMenuItem
            caption="Техническое обслуживание ПСС ЛСО"
            url={AppRoutes.pssLso}
            canIViewProps={{
              serviceCodes: [UnitServiceCodesEnum.LSO],
              roles: [RoleEnum.SERVICE_LEAD, RoleEnum.UNIT_LEAD, RoleEnum.TEAM_LEAD],
            }}
          />
          <AppBarMenuItem
            caption="Техническое обслуживание ПСС ЛКУ"
            url={AppRoutes.pssLku}
            canIViewProps={{
              serviceCodes: [UnitServiceCodesEnum.LKU],
              roles: [RoleEnum.SERVICE_LEAD, RoleEnum.UNIT_LEAD, RoleEnum.TEAM_LEAD],
            }}
          />
          <AppBarMenuItem
            caption="Техническое обслуживание ПСС ВОЛС"
            url={AppRoutes.pssVols}
            canIViewProps={{
              serviceCodes: [UnitServiceCodesEnum.VOLS],
              roles: [RoleEnum.SERVICE_LEAD, RoleEnum.UNIT_LEAD, RoleEnum.TEAM_LEAD],
            }}
          />
          <AppBarMenuItem
            caption="Техническое обслуживание СГПС"
            url={AppRoutes.sgps}
            canIViewProps={{ serviceCodes: [UnitServiceCodesEnum.PSPA_KF] }}
          />
          <AppBarMenuItem
            caption="График работы персонала"
            url={AppRoutes.employeesSchedule}
            canIViewProps={{ roles: rolesWitoutRepresentativesAndCatalogAdmin }}
          />
        </AppBarMenuSection>

        <AppBarMenuSection
          label="Отчётность"
          canIViewProps={{ roles: rolesWitoutRepresentativesAndCatalogAdmin }}
          onItemClick={handleMenuClose}
        >
          <AppBarMenuItem
            caption="Статистика"
            url={AppRoutes.base}
            canIViewProps={{ roles: rolesWitoutRepresentativesAndCatalogAdmin }}
          />
          <AppBarMenuItem
            caption="Рейтинг бригад"
            url={AppRoutes.rating}
            canIViewProps={{
              serviceCodes: [UnitServiceCodesEnum.PSPA_ZF],
              roles: [RoleEnum.SERVICE_LEAD, RoleEnum.WORKSHOP_LEAD, RoleEnum.UNIT_LEAD],
            }}
          />
          <AppBarMenuItem
            caption="Выгрузка по заявкам для ОПиН"
            canIViewProps={{
              serviceCodes: [UnitServiceCodesEnum.PSPA_ZF],
              roles: [
                RoleEnum.SERVICE_LEAD,
                RoleEnum.RELIABILITY_ENGINEER,
                RoleEnum.WORKSHOP_LEAD,
                RoleEnum.UNIT_LEAD,
                RoleEnum.SHIFT_LEAD,
              ],
            }}
            onClick={() => {
              setPrintTaskReportModalState(PrintTaskReportModalStatesEnum.AnyUnit);
              handleMenuClose();
            }}
          />
          <AppBarMenuItem
            caption="Карточка оборудования"
            url={AppRoutes.equipments}
            canIViewProps={{
              serviceCodes: [UnitServiceCodesEnum.PSPA_ZF, UnitServiceCodesEnum.PSPA_KF],
              roles: [
                RoleEnum.SERVICE_LEAD,
                RoleEnum.RELIABILITY_ENGINEER,
                RoleEnum.WORKSHOP_LEAD,
                RoleEnum.UNIT_LEAD,
                RoleEnum.SHIFT_LEAD,
                RoleEnum.TEAM_LEAD,
              ],
            }}
          />
          <AppBarMenuItem
            caption="Отчёт для проведения планерок"
            url={AppRoutes.dailyReport}
            canIViewProps={{
              serviceCodes: [UnitServiceCodesEnum.PSPA_ZF],
              roles: [RoleEnum.SERVICE_LEAD, RoleEnum.WORKSHOP_LEAD, RoleEnum.UNIT_LEAD],
            }}
          />
          <AppBarMenuItem
            caption="Статус по стационарному обор."
            canIViewProps={{
              serviceCodes: [UnitServiceCodesEnum.PSPA_ZF],
              roles: [RoleEnum.RELIABILITY_ENGINEER],
            }}
            isDisabled={isLoadingStatReport}
            onClick={() => {
              runStatReportRequest();
              handleMenuClose();
              message.loading("Отчет начал загружаться...");
              /** @todo message.loading работает, но выдает предупреждение в консоли */
            }}
          />
        </AppBarMenuSection>

        <AppBarMenuSection
          label="Справка"
          canIViewProps={{ roles: rolesWitoutRepresentativesAndCatalogAdmin }}
          onItemClick={handleMenuClose}
        >
          <AppBarMenuItem
            caption="Видеоинструкции"
            url={AppRoutes.help}
            canIViewProps={{ roles: rolesWitoutRepresentativesAndCatalogAdmin }}
          />
        </AppBarMenuSection>

        <AppBarMenuSection
          label="Управление справочниками"
          canIViewProps={{ roles: [RoleEnum.CATALOG_ADMIN] }}
          onItemClick={handleMenuClose}
        >
          <AppBarMenuItem caption="Справочники" url="/catalog-admin/" isTargetBlank />
        </AppBarMenuSection>

        <a href="https://supernika.nornik.ru/" target="_blank" onClick={handleMenuClose} rel="noreferrer">
          <Button type="link" size="large" style={{ margin: "8px 0" }}>
            Суперника
          </Button>
        </a>
        <VersionNumber />
      </Drawer>
    </>
  );
}

export { AppBar };
