import { CSSProperties } from "react";

import { OossaSystemTypeEnum } from "core/app/api/units/OOSSA/enums";

export const SYSTEM_WITH_HEIGHT_COLUMN = "СМЗИС";

export const OOSSA_SYSTEMS_TAG_COLORS: Record<OossaSystemTypeEnum, CSSProperties["color"]> = {
  [OossaSystemTypeEnum.ASKUE]: "#87E8DE",
  [OossaSystemTypeEnum.ASKUE_EE]: "#87E8DE",
  [OossaSystemTypeEnum.SMZIS]: "#52C41A",
};
