import { ReactElement, useContext } from "react";
import { Row, Col, Divider, Spin, Empty } from "antd";

import { useGetDashboardShiftStatisticTasksByEquipmentTypesQuery } from "core/app/api/dashboard";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";

import { ByEquipmentTypes } from "./ByEquipmentTypes";

function ByEquipmentTypesPspa(): ReactElement {
  const { queryString } = useContext(ShiftContext);
  const { data, isFetching, isError } = useGetDashboardShiftStatisticTasksByEquipmentTypesQuery(queryString, {
    skip: !queryString,
  });

  if (isFetching) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spin />
      </div>
    );
  }

  if (!data || isError) {
    return <Empty />;
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <ByEquipmentTypes data={data} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ByEquipmentTypes isMounted data={data} />
        </Col>
      </Row>
    </>
  );
}

export { ByEquipmentTypesPspa };
