import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TRootState } from "..";

interface IOossa {
  selectedTab: string;
  selectedObjectId: number | null;
  selectedSystemIds: number[] | null;
  selectedRepairAreaId: number | null;
  selectedDeviceId: number | null;
}

const initialState: IOossa = {
  selectedTab: "0",
  selectedObjectId: null,
  selectedSystemIds: null,
  selectedRepairAreaId: null,
  selectedDeviceId: null,
};

export const OossaSlice = createSlice({
  name: "oossa",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setSelectedTab(state, action: PayloadAction<string>) {
      state.selectedTab = action.payload;
    },
    setSelectedObjectId(state, action: PayloadAction<number | null>) {
      state.selectedObjectId = action.payload;
    },
    setSelectedSystemIds(state, action: PayloadAction<number[]>) {
      state.selectedSystemIds = action.payload;
    },
    setSelectedRepairAreaId(state, action: PayloadAction<number | null>) {
      state.selectedRepairAreaId = action.payload;
    },
    setSelectedDeviceId(state, action: PayloadAction<number | null>) {
      state.selectedDeviceId = action.payload;
    },
  },
});

export const {
  setSelectedTab,
  setSelectedObjectId,
  setSelectedSystemIds,
  setSelectedRepairAreaId,
  setSelectedDeviceId,
  reset,
} = OossaSlice.actions;
export const oossaMaintenanceSelector = createSelector(
  (state: TRootState) => state.oossa,
  (state) => state
);
