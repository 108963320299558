export enum ApiSliceEnum {
  oossa = "oossa",
  pspa = "pspa",
  slpi = "slpi",
  lso = "lso",
  pspaKf = "pspa-kf",
  lku = "lku",
  vols = "vols",
}

export enum EmployeeScheduleDayTypeEnum {
  WORKING_DAY = 1,
  NON_WORKING_DAY = 2,
  VACATION = 3,
  SICK_LEAVE = 4,
  TIME_OFF_DAY = 5,
  BUSINESS_TRIP = 6,
  MEDICAL_EXAMINATION = 7,
}

export enum TaskDiscriminatorEnum {
  PSPA_TASK = "PSPA_TASK",
  SLPI_TASK = "SLPI_TASK",
  OOSSA_TASK = "OOSSA_TASK",
  ETO_PSPA_TASK = "ETO_PSPA_TASK",
  PSPA_KF_TASK = "PSPA_KF_TASK",
  LSO_TASK = "LSO_TASK",
  LKU_TASK = "LKU_TASK",
  VOLS_TASK = "VOLS_TASK",
  OPIN_TASK = "OPIN_TASK",
}

export enum SignatureSideEnum {
  Executor = "executor",
  Customer = "customer-representative",
}

export enum PositionRoleEnum {
  CUSTOMER_REPRESENTATIVE = "CUSTOMER_REPRESENTATIVE",
  UNIT_LEAD = "UNIT_LEAD",
}
