import { ReactElement } from "react";
import { Select, Form } from "antd";
import qs from "qs";

import { useGetLkuRacksQuery } from "core/app/api/units/LKU";
import { TQueryProps } from "core/shared/interfaces";

import { FIELD_NAMES } from "../../constants";

export function LkuTechNodeField(): ReactElement {
  const form = Form.useFormInstance();
  const objectId = Form.useWatch(FIELD_NAMES.OBJECT);
  const equipmentTypeId = Form.useWatch(FIELD_NAMES.EQUIPMENT_TYPE);
  const equipmentId = Form.useWatch(FIELD_NAMES.EQUIPMENT);

  const queryParams: TQueryProps = {
    object_id: objectId,
    system_id: equipmentTypeId,
    limit: 1000,
  };
  if (equipmentId) {
    queryParams.equipment_id = equipmentId;
  }

  const { data, isFetching } = useGetLkuRacksQuery(qs.stringify(queryParams));
  const options = (data || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleSelectChange = (value: number) => {
    form.setFieldsValue({
      [FIELD_NAMES.TECH_NODE]: value,
      [FIELD_NAMES.EQUIPMENT]: undefined,
    });
  };

  return (
    <Form.Item label="ТЕХ. УЗЕЛ" name={FIELD_NAMES.TECH_NODE} colon={false}>
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleSelectChange}
      />
    </Form.Item>
  );
}
