import { ReactElement } from "react";
import { Row, Col, Empty, Spin } from "antd";

import { TaskTypesIdsEnum } from "core/app/api/task/enums";
import { safePercent } from "core/shared/tools/safePercent";
import { useDashboardShiftStatisticTasksByCustomerDepartmentsVols } from "core/shared/dashboard/hooks/useDashboardShiftStatisticTasksByObjectsVols";

import SystemStatRow from "../shared/SystemStatRow";
import TotalByTaskType from "../shared/TotalByTaskType";
import { Total } from "../shared/Total";

import { VolsDepartmentName } from "./VolsDepartmentName";

export default function ByCustomDepartmentsVols(): ReactElement {
  const { data, isError, isFetching } = useDashboardShiftStatisticTasksByCustomerDepartmentsVols();

  if (isFetching) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spin />
      </div>
    );
  }

  if (!data || isError) {
    return <Empty />;
  }

  return (
    <Row gutter={16}>
      <Col span={3}>
        <Total
          showTitle
          fact={safePercent(data.closed_sheduled_tasks, data.sheduled_tasks)}
          plan={100 - safePercent(data.closed_sheduled_tasks, data.sheduled_tasks)}
        />
      </Col>
      <Col span={21}>
        <Row gutter={24}>
          <Col span={5} style={{ fontSize: "16px", fontWeight: "bold", paddingTop: "8px" }}>
            Подразделения
          </Col>
          <Col span={19}>
            <Row>
              <Col span={8}>
                <TotalByTaskType
                  title="АВР"
                  data={data.by_type?.find((item) => item.name === TaskTypesIdsEnum.RECOVERY)}
                />
              </Col>
              <Col span={8}>
                <TotalByTaskType
                  title="ЗНО"
                  data={data.by_type?.find((item) => item.name === TaskTypesIdsEnum.SERVICE_REQUEST)}
                />
              </Col>
              <Col span={8}>
                <TotalByTaskType
                  title="ТО"
                  data={data.by_type?.find((item) => item.name === TaskTypesIdsEnum.MAINTENANCE)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {data.by_customer_department?.map((object) => (
              <div key={object.customer_department.id}>
                <SystemStatRow
                  name={<VolsDepartmentName name={object.customer_department.name} />}
                  nameIsLong
                  data={object.by_type}
                />
              </div>
            ))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
