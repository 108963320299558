import { Row, Col, FormInstance } from "antd";
import { ReactElement } from "react";

import { useDisabled } from "core/shared/task/hooks/useDisabled";

import UsedMaterialsTextArea from "../../shared/UsedMaterialsTextArea";
import { ElapsedTime } from "../../shared/ElapsedTime";
import { EmployeesSelect } from "../../shared/EmployeesSelect/FormControlled";
import { Comment } from "../../shared/Comment";
import { Comments } from "../../shared/Comments";

interface BodyProps {
  form: FormInstance;
}

function Body({ form }: BodyProps): ReactElement {
  const teamId = form.getFieldValue("team_id");
  const isDisabled = useDisabled();

  return (
    <>
      <Row>
        <Col span={24}>
          <Comments />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Comment required disabled={isDisabled} />
        </Col>
        <Col span={12}>
          <UsedMaterialsTextArea disabled={isDisabled} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <EmployeesSelect disabled={isDisabled} teamId={teamId} />
        </Col>
        <Col span={12}>
          <ElapsedTime useMultiplier disabled={isDisabled} />
        </Col>
      </Row>
    </>
  );
}

export default Body;
