import { message } from "antd";
import qs from "qs";
import { get } from "lodash";

import { IDashboardShiftStatisticTasksByTeams } from "core/shared/dashboard/hooks/interfaces";
import { IDictionaryItem, IResponseBody } from "core/shared/interfaces";

import { apiSlice } from "../..";
import { handleUpdateTaskQueryStarted, TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE } from "../../tools";
import { IPlanByMonth } from "../../interfaces";
import { extractResults } from "../../emptyApi";

import { TOossaMonthPlanState } from "./types";
import {
  IApproveOossaMonthPlan,
  IApproveOossaMonthPlanPayload,
  IDashboardShiftStatisticTasksByObjectsOossa,
  IDashboardShiftStatisticTasksByTeamsOossaTask,
  IOossaDevice,
  IOossaEquipment,
  IOossaMaintenancePlanFactStatistic,
  IOossaMonthPlan,
  IOossaMonthPlanQueryPayload,
  IOossaMonthPlansCreateQueryPayload,
  IOossaObject,
  IOossaTask,
  IOossaTechNode,
  IOossaWorkType,
  IOossaYearPlan,
  IOossaYearPlansQueryPayload,
  IOossaYearPlansStat,
} from "./interfaces";

export const oossaApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      "oossa/task",
      "oossa/objects",
      "oossa/object",
      "oossa/equipments",
      "oossa/equipment",
      "oossa/systems",
      "oossa/system",
      "oossa-month-plans",
      "oossa-year-plans",
      "oossa-year-plans/oossa_stat",
      "oossa-tech-node",
      "oossa-tech-nodes",
      "oossa-year-plan-by-months/month-plan-approval-state",
      "oossa-work-types",
      "dashboard/oossa-maintenance-plan-fact-statistic",
      "dashboard/shift-statistic/tasks/by-teams/oossa",
      "dashboard/shift-statistic/tasks/by-objects/oossa",
      "oossa-data-devices",
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getOossaTasks: builder.query<IResponseBody<IOossaTask>, string | undefined>({
        query: (queryProps) => `oossa-tasks/?${queryProps}`,
        providesTags: ["open-tasks"],
      }),
      getDashboardShiftStatisticTasksByObjectsOossa: builder.query<IDashboardShiftStatisticTasksByObjectsOossa, string>(
        {
          query: (queryString: string) => `dashboard/shift-statistic/tasks/by-objects/oossa/?${queryString}`,
          providesTags: ["dashboard/shift-statistic/tasks/by-objects/oossa"],
        }
      ),
      getDashboardShiftStatisticTasksByTeamsOossa: builder.query<
        IDashboardShiftStatisticTasksByTeams<IDashboardShiftStatisticTasksByTeamsOossaTask>[],
        string
      >({
        query: (queryString: string) => `dashboard/shift-statistic/tasks/by-teams/oossa/?${queryString}`,
        providesTags: ["dashboard/shift-statistic/tasks/by-teams/oossa"],
      }),
      updateOossaTask: builder.mutation({
        query: ({ rtkQueryCacheKey, ...task }) => ({
          url: `oossa-tasks/${task.id}/`,
          method: "PATCH",
          body: task,
        }),
        async onQueryStarted(args, queryContext) {
          await handleUpdateTaskQueryStarted(args, queryContext);
        },
        invalidatesTags: (result, error, arg) => [
          { type: "task", id: arg.id },
          ...TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE,
        ],
      }),
      getOossaObjects: builder.query<IResponseBody<IDictionaryItem>, string>({
        query: (queryString) => `/oossa-objects/?${queryString}`,
        providesTags: ["oossa/objects"],
      }),
      getOossaObject: builder.query<IOossaObject, number>({
        query: (id) => `/oossa-objects/${id}/`,
        providesTags: ["oossa/object"],
      }),
      getOossaEquipments: builder.query<IResponseBody<IOossaEquipment>, string>({
        query: (queryString) => `/oossa-equipments/?${queryString}`,
        providesTags: ["oossa/equipments"],
      }),
      getOossaEquipment: builder.query<IOossaEquipment, number>({
        query: (id) => `/oossa-equipments/${id}/`,
        providesTags: ["oossa/equipment"],
      }),
      getOossaSystems: builder.query<IResponseBody<IDictionaryItem>, string>({
        query: (queryString) => `/oossa-systems/?${queryString}`,
        providesTags: ["oossa/systems"],
      }),
      getOossaYearPlans: builder.query<IOossaYearPlan[], IOossaYearPlansQueryPayload>({
        query(payload) {
          const queryString = qs.stringify(payload);
          return `/oossa-year-plans/?${queryString}`;
        },
        transformResponse: extractResults,
        providesTags: ["oossa-year-plans"],
      }),
      getOossaYearPlansStat: builder.query<IOossaYearPlansStat, IOossaYearPlansQueryPayload>({
        query(payload) {
          const queryString = qs.stringify(payload);
          return `/oossa-year-plans/oossa-stat/?${queryString}`;
        },
        providesTags: ["oossa-year-plans/oossa_stat"],
      }),
      updateOossaYearPlan: builder.mutation<IPlanByMonth, Pick<IPlanByMonth, "id" | "maintenance_quantity">>({
        query(payload) {
          return {
            url: `/oossa-year-plan-by-months/${payload.id}/`,
            method: "PATCH",
            body: payload,
          };
        },
        invalidatesTags: ["oossa-year-plans"],
      }),
      approveOossaMonthPlan: builder.mutation<IApproveOossaMonthPlan, IApproveOossaMonthPlanPayload>({
        query(payload) {
          return {
            url: `oossa-month-plans/approve/`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: [
          "oossa-year-plans",
          "oossa-year-plan-by-months/month-plan-approval-state",
          "oossa-month-plans",
        ],
      }),
      undoApproveOossaMonthPlan: builder.mutation<string, IApproveOossaMonthPlanPayload>({
        query(payload) {
          return {
            url: `oossa-month-plans/undo-approve/`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: [
          "oossa-year-plans",
          "oossa-year-plan-by-months/month-plan-approval-state",
          "oossa-month-plans",
        ],
      }),
      updateOossaMonthPlan: builder.mutation<
        IOossaMonthPlan,
        { plan: IOossaMonthPlan; queryProps: IOossaMonthPlanQueryPayload }
      >({
        query(payload) {
          return {
            url: `oossa-month-plans/${payload.plan.id}/`,
            method: "PUT",
            body: payload.plan,
          };
        },
        async onQueryStarted(payload, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            oossaApiSlice.util.updateQueryData("getOossaMonthPlan", payload.queryProps, (cachedPlans) => {
              const cachedPlanToUpdate = cachedPlans.find((cachedPlan) => cachedPlan.id === payload.plan.id);
              if (cachedPlanToUpdate) {
                Object.assign(cachedPlanToUpdate, payload.plan);
              }
            })
          );
          try {
            await queryFulfilled;
          } catch (err: any) {
            const errorText = get(err, "error.data.errors", "Произошла ошибка, попробуйте позже");
            message.error(errorText);
            patchResult.undo();
          }
        },
        invalidatesTags: ["oossa-month-plans"],
      }),
      createOossaMonthPlan: builder.mutation<any, IOossaMonthPlansCreateQueryPayload>({
        query(payload) {
          return {
            url: `oossa-month-plans/make-schedule/`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["oossa-month-plans"],
      }),
      getOossaMonthPlan: builder.query<IOossaMonthPlan[], IOossaMonthPlanQueryPayload>({
        query(payload) {
          const queryString = qs.stringify({
            limit: 1000,
            ...payload,
          });
          return `/oossa-month-plans/?${queryString}`;
        },
        providesTags: ["oossa-month-plans"],
        transformResponse: extractResults,
      }),
      getOossaTechNode: builder.query<IOossaTechNode, number>({
        query(id) {
          return `/oossa-tech-nodes/${id}/`;
        },
        providesTags: ["oossa-tech-node"],
      }),
      getOossaTechNodes: builder.query<IOossaTechNode[], string>({
        query(queryString) {
          return `/oossa-tech-nodes/?${queryString}`;
        },
        providesTags: ["oossa-tech-nodes"],
        transformResponse: extractResults,
      }),
      getOossaMonthPlansApprovalState: builder.query<TOossaMonthPlanState[], string>({
        query: (queryString) => `/oossa-year-plan-by-months/month-plan-approval-state/?${queryString}`,
        providesTags: ["oossa-year-plan-by-months/month-plan-approval-state"],
      }),
      getOossaMaintenancePlanFactStatistic: builder.query<IOossaMaintenancePlanFactStatistic, string>({
        query: (queryString) => `/dashboard/oossa-maintenance-plan-fact-statistic/?${queryString}`,
        providesTags: ["dashboard/oossa-maintenance-plan-fact-statistic"],
      }),
      getOossaWorkTypes: builder.query<IOossaWorkType[], string>({
        query: (queryString) => `/oossa-work-types/?${queryString}`,
        providesTags: ["oossa-work-types"],
        transformResponse: extractResults,
      }),
      createOossaTask: builder.mutation({
        query: (payload) => ({
          url: `oossa-tasks/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["tasks", "open-tasks", "tasks-filtered", "kanban-board"],
      }),
      getOossaDevices: builder.query<IOossaDevice[], string>({
        query: (queryString) => `oossa-data-devices/?${queryString}`,
        providesTags: ["oossa-data-devices"],
        transformResponse: extractResults,
      }),
    }),
  });

export const {
  useUpdateOossaTaskMutation,
  useGetOossaObjectsQuery,
  useGetOossaObjectQuery,
  useGetOossaSystemsQuery,
  useGetOossaEquipmentsQuery,
  useGetOossaYearPlansQuery,
  useGetOossaMonthPlanQuery,
  useUpdateOossaYearPlanMutation,
  useUpdateOossaMonthPlanMutation,
  useCreateOossaMonthPlanMutation,
  useApproveOossaMonthPlanMutation,
  useUndoApproveOossaMonthPlanMutation,
  useGetOossaYearPlansStatQuery,
  useGetOossaTechNodeQuery,
  useGetOossaMonthPlansApprovalStateQuery,
  useGetOossaTechNodesQuery,
  useGetOossaEquipmentQuery,
  useGetOossaMaintenancePlanFactStatisticQuery,
  useGetOossaWorkTypesQuery,
  useGetDashboardShiftStatisticTasksByTeamsOossaQuery,
  useGetDashboardShiftStatisticTasksByObjectsOossaQuery,
  useGetOossaTasksQuery,
  useCreateOossaTaskMutation,
  useGetOossaDevicesQuery,
} = oossaApiSlice;
