import { Row, Col, Button, Space } from "antd";

import { useDisabledByLocation } from "core/shared/task/hooks/useDisabledByLocation";
import { useAssistanceCarMaintenanceContext } from "core/widgets/AssistanceCarTaskModal/hooks/useAssistanceCarMaintenanceContext";
import { FormPagesEnum } from "core/widgets/AssistanceCarTaskModal/AssistanceCarMaintenanceContext";
import { EtoTaskStepNameEnum } from "core/app/api/task/eto/enums";
import { IEtoTask } from "core/app/api/task/eto/interfaces";

import { EtoTaskSignatureSideEnum } from "../../interfaces";

import { SignatureCompleted } from "./SignatureCompleted";
import { useShowSignatureFlags } from "./hooks/useShowSignatureFlags";

interface SignatureButtonsProps {
  task: IEtoTask;
}

function SignatureButtons({ task }: SignatureButtonsProps) {
  const { setPage, setSignatureSide } = useAssistanceCarMaintenanceContext();
  const isDisabledByLocation = useDisabledByLocation();
  const {
    isMechanistSignatureExists,
    isDriverSignatureExists,
    showInitialSignatures,
    showFinishSignatures,
    isInitialSignatureButtonsDisabled,
    isFinishSignatureButtonsDisabled,
  } = useShowSignatureFlags(task);

  return (
    <Space className="w-100" direction="vertical" size={16}>
      {(isMechanistSignatureExists || isDriverSignatureExists) && (
        <Row gutter={36}>
          {showInitialSignatures && (
            <Col span={12}>
              {isMechanistSignatureExists && (
                <SignatureCompleted
                  task={task}
                  stepName={EtoTaskStepNameEnum.PRE_SHIFT_CHECK}
                  side={EtoTaskSignatureSideEnum.SENIOR_ELECTRICIAN}
                />
              )}
            </Col>
          )}
          {showInitialSignatures && (
            <Col span={12}>
              {isDriverSignatureExists && (
                <SignatureCompleted
                  task={task}
                  stepName={EtoTaskStepNameEnum.PRE_SHIFT_CHECK}
                  side={EtoTaskSignatureSideEnum.MACHINIST}
                />
              )}
            </Col>
          )}
          {showFinishSignatures && (
            <Col span={24}>
              {isDriverSignatureExists && (
                <SignatureCompleted
                  task={task}
                  stepName={EtoTaskStepNameEnum.POST_SHIFT_CHECK}
                  side={EtoTaskSignatureSideEnum.MACHINIST}
                />
              )}
            </Col>
          )}
        </Row>
      )}
      <Row gutter={36}>
        {showInitialSignatures && (
          <Col span={12}>
            <Button
              disabled={isDisabledByLocation || isInitialSignatureButtonsDisabled}
              onClick={() => {
                setPage(FormPagesEnum.SIGNATURE);
                setSignatureSide(EtoTaskSignatureSideEnum.SENIOR_ELECTRICIAN);
              }}
              style={{ width: "100%" }}
              type="primary"
            >
              ВЫПУСКАЮЩИЙ ЭЛЕКТРОМЕХАНИК
            </Button>
          </Col>
        )}
        {showInitialSignatures && (
          <Col span={12}>
            <Button
              disabled={isDisabledByLocation || isInitialSignatureButtonsDisabled}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                setPage(FormPagesEnum.SIGNATURE);
                setSignatureSide(EtoTaskSignatureSideEnum.MACHINIST);
              }}
            >
              МАШИНИСТ
            </Button>
          </Col>
        )}
        {showFinishSignatures && (
          <Col span={24}>
            <Button
              disabled={isDisabledByLocation || isFinishSignatureButtonsDisabled}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                setPage(FormPagesEnum.SIGNATURE);
                setSignatureSide(EtoTaskSignatureSideEnum.MACHINIST);
              }}
            >
              МАШИНИСТ
            </Button>
          </Col>
        )}
      </Row>
    </Space>
  );
}

export { SignatureButtons };
