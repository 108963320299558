import { Spin } from "antd";

import { useGetMediaQuery } from "core/app/api";
import { IAttachment } from "core/app/api/task/interfaces";
import { mediaCategoryTitles } from "core/shared/task";
import { useMediaContext } from "core/shared/task/context/mediaContext";
import dayjs, { displayTimeFormat } from "core/shared/tools/dayjs";

interface MediaItemProps {
  attachment: IAttachment;
}

function MediaItem({ attachment }: MediaItemProps) {
  const { taskId } = useMediaContext();
  const { data } = useGetMediaQuery({
    taskId,
    attachmentId: attachment.id,
  });
  return (
    <>
      {!data && (
        <div style={{ display: "flex", alignItems: "center", height: "calc(100vh - 102px)" }}>
          <div style={{ flex: 1 }}>
            <Spin size="large" />
          </div>
        </div>
      )}
      {data && attachment.content_type.includes("video") && (
        <video controls width="100%">
          <source src={data} type={attachment.content_type} />
        </video>
      )}
      {data && attachment.content_type.includes("image") && (
        <img style={{ height: "100%", objectFit: "contain" }} src={data} alt={attachment.original_name} />
      )}
      <p className="legend">
        {mediaCategoryTitles[attachment.category]} в {dayjs(attachment.created_at).format(displayTimeFormat)}
      </p>
    </>
  );
}

export { MediaItem };
