import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetOpinTaskQuery } from "core/app/api/task/opin";

import { useOpinTaskDraftId } from "../useOpinTaskDraftId";

export function useOpinTaskDraft() {
  const [draftId] = useOpinTaskDraftId();
  return useGetOpinTaskQuery(draftId ?? skipToken);
}
