import qs from "qs";

import { useUnitId } from "../../../unit/useUnitId";
import { useGetCustomerRepresentativesQuery } from "../../../../app/api/task";

const useCustomerRepresentatives = (queryExtraProps?: Record<string, any>) => {
  const [unit] = useUnitId();
  const queryString = qs.stringify({
    unit_id: unit,
    ...queryExtraProps,
  });
  return useGetCustomerRepresentativesQuery(queryString);
};

export { useCustomerRepresentatives };
