import { ReactElement, useEffect } from "react";
import { Form, Select } from "antd";
import qs from "qs";
import { capitalize } from "lodash";

import { ITeam as TeamInterface } from "core/app/api/team/interfaces";
import { getTeamName } from "core/widgets/TeamName";
import { useUnitId } from "core/shared/unit/useUnitId";
import dayjs, { apiDateFormat, displayDateFormat } from "core/shared/tools/dayjs";
import { useUnitShifts } from "core/shared/unit/useUnitShifts";
import { useGetTeamsQuery } from "core/app/api/team";

interface TeamSelectProps {
  required: boolean;
}

function TeamSelect({ required }: TeamSelectProps): ReactElement {
  const shift = Form.useWatch("shift");
  const date = Form.useWatch("date");
  const form = Form.useFormInstance();
  const [unitId] = useUnitId();
  const { data: unitShifts } = useUnitShifts();

  let teamsQS: Record<string, string | number | boolean | null> = {
    unit_id: unitId,
    ordering: "date,shift_number,team_number",
  };
  if (required) {
    teamsQS = {
      ...teamsQS,
      date_after: dayjs().startOf("day").add(-1, "day").format(apiDateFormat),
      date_before: dayjs().endOf("day").format(apiDateFormat),
      opened_shift: true,
      suitable_for_me: true,
    };
  } else {
    teamsQS = {
      ...teamsQS,
      date_after: dayjs(date).format(apiDateFormat),
      date_before: dayjs(date).format(apiDateFormat),
      shift_number: shift,
      opened_shift: true,
      suitable_for_me: true,
    };
  }
  const { data, isFetching, isSuccess } = useGetTeamsQuery(qs.stringify(teamsQS));

  useEffect(() => {
    if (date && shift) {
      form.setFieldValue("team", null);
    }
  }, [date, form, shift]);

  useEffect(() => {
    if (isSuccess && !isFetching && data && data[0]) {
      form.setFieldValue("team", data[0].id);
    }
  }, [data, form, isSuccess, isFetching]);

  if (!data) {
    return <div />;
  }

  return (
    <Form.Item label="БРИГАДА" colon={false} name="team" style={{ marginBottom: 0 }}>
      <Select disabled={!data.length} placeholder={!data.length ? "Нет бригад" : ""} style={{ minWidth: "160px" }}>
        {!required && (
          <Select.Option key={null} value={null}>
            <div>Без бригады</div>
          </Select.Option>
        )}
        {data.map((teamsItem: TeamInterface) => {
          const selectedShift = (unitShifts || []).find((unitShift) => unitShift.number === teamsItem.shift_number);
          return (
            <Select.Option key={teamsItem.id} value={teamsItem.id}>
              {required ? (
                <div>
                  {`${dayjs(teamsItem.date).format(displayDateFormat)} | ${capitalize(
                    selectedShift?.name
                  )} | ${getTeamName(teamsItem)} `}
                </div>
              ) : (
                getTeamName(teamsItem)
              )}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
}

export default TeamSelect;
