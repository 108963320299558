import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";
import { isSlpiTask } from "core/shared/task";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function Initiator({ task }: FieldProps): ReactElement {
  const value = isSlpiTask(task) ? task?.request_initiator : task?.initiator?.full_name;

  return <Field label="ФИО Инициатора" value={value} />;
}

export { Initiator };
