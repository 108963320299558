import { ReactElement, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import { AppRoutes } from "core/shared/constants.routes";
import { FixedLayout } from "core/pages/Layouts/FixedLayout";
import { BaseLayout } from "core/pages/Layouts/BaseLayout";
import StatisticDashboardPage from "core/pages/Dashboard";
import TaskSignatureJournal from "core/pages/TaskSignatureJournal";

const Teams = lazy(() => import("core/pages/Teams"));
const PspaMaintenance = lazy(() => import("core/pages/PspaMaintenance"));
const TasksForTeams = lazy(() => import("core/pages/TasksForTeams"));
const TasksForShift = lazy(() => import("core/pages/TasksForShift"));
const TasksForShiftTask = lazy(() => import("core/pages/TasksForShiftTask"));
const Tasks = lazy(() => import("core/pages/Tasks"));
const EmployeesSchedule = lazy(() => import("core/pages/EmployeesSchedule"));
const SLPI = lazy(() => import("core/pages/SLPI"));
const Equipments = lazy(() => import("core/pages/Equipments"));
const Help = lazy(() => import("core/pages/Help"));
const Rating = lazy(() => import("core/pages/Rating"));
const DailyReport = lazy(() => import("core/pages/DailyReport"));
const Oossa = lazy(() => import("core/pages/OOSSA"));
const SGPS = lazy(() => import("core/pages/SGPS"));
const PssLso = lazy(() => import("core/pages/PSS/LSO"));
const PssLku = lazy(() => import("core/pages/PSS/LKU"));
const PssVols = lazy(() => import("core/pages/PSS/VOLS"));

function LayoutRoutes(): ReactElement {
  return (
    <Routes>
      <Route
        path={AppRoutes.base}
        element={
          <Suspense>
            <BaseLayout>
              <StatisticDashboardPage />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.teams}
        element={
          <Suspense>
            <BaseLayout>
              <Teams />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.pspa}
        element={
          <Suspense>
            <FixedLayout>
              <PspaMaintenance />
            </FixedLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.tasksForTeams}
        element={
          <Suspense>
            <BaseLayout>
              <TasksForTeams />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.taskForShift}
        element={
          <Suspense>
            <BaseLayout>
              <TasksForShift />
            </BaseLayout>
          </Suspense>
        }
      >
        <Route
          path=":taskId"
          element={
            <Suspense>
              <BaseLayout>
                <TasksForShiftTask />
              </BaseLayout>
            </Suspense>
          }
        />
      </Route>
      <Route
        path={AppRoutes.tasks}
        element={
          <Suspense>
            <BaseLayout>
              <Tasks />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.employeesSchedule}
        element={
          <Suspense>
            <BaseLayout>
              <EmployeesSchedule />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.slpi}
        element={
          <Suspense>
            <BaseLayout>
              <SLPI />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.equipments}
        element={
          <Suspense>
            <BaseLayout>
              <Equipments />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.help}
        element={
          <Suspense>
            <BaseLayout>
              <Help />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.rating}
        element={
          <Suspense>
            <BaseLayout fixed>
              <Rating />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.dailyReport}
        element={
          <Suspense>
            <BaseLayout fixed>
              <DailyReport />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.oossa}
        element={
          <Suspense>
            <BaseLayout fixed>
              <Oossa />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.sgps}
        element={
          <Suspense>
            <BaseLayout>
              <SGPS />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.taskSignatureJournal}
        element={
          <Suspense>
            <BaseLayout fixed>
              <TaskSignatureJournal />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.pssLso}
        element={
          <Suspense>
            <BaseLayout fixed>
              <PssLso />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.pssLku}
        element={
          <Suspense>
            <BaseLayout fixed>
              <PssLku />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.pssVols}
        element={
          <Suspense>
            <BaseLayout fixed>
              <PssVols />
            </BaseLayout>
          </Suspense>
        }
      />
    </Routes>
  );
}

export default LayoutRoutes;
