import { skipToken } from "@reduxjs/toolkit/query/react";
import qs from "qs";

import { useUnitId } from "core/shared/unit/useUnitId";
import { useGetRepairAreasQuery } from "core/app/api/units/PSPA";

export interface IRepairAreaFilterProps {
  unit_id?: number | null;
  customer_department_id?: number | null;
  data_collect_transfer_device_id?: number | null;
}

export function useUnitRepairAreas(filters?: IRepairAreaFilterProps) {
  const [unitId] = useUnitId();
  const queryString = qs.stringify({
    unit_id: unitId,
    ...filters,
  });
  return useGetRepairAreasQuery(unitId ? queryString : skipToken);
}
