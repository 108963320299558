import { Form, FormInstance } from "antd";
import { ReactElement } from "react";
import { Dayjs } from "dayjs";

import DatePicker from "core/widgets/DatePicker";
import dayjs, { displayDateFormat } from "core/shared/tools/dayjs";
import { useItsmDelayReasons } from "core/shared/main/hooks/useItsmDelayReasons";
import { StateNamesEnum } from "core/app/api/task/enums";

interface SuspendedUntilProps {
  disabled: boolean;
  form: FormInstance;
}

function SuspendedUntil({ disabled, form }: SuspendedUntilProps): ReactElement {
  const delayReasonId = Form.useWatch("delay_reason_id");
  const { data: delayReasons } = useItsmDelayReasons();
  const delayReason = delayReasons?.find((item) => item.id === delayReasonId);
  let isSuspendedUntilRequired = true;
  if (delayReason && !delayReason.is_suspended_until_required) {
    form.setFieldValue("suspendedUntil", null);
    isSuspendedUntilRequired = false;
  }

  return (
    <Form.Item
      label="ПРИОСТАНОВЛЕН ДО"
      name="suspendedUntil"
      rules={[
        {
          validator: (_, value) => {
            const newState = form.getFieldValue("newState");
            if (newState === StateNamesEnum.OnPause && !value && isSuspendedUntilRequired) {
              return Promise.reject(new Error("Пожалуйста, введите ПРИОСТАНОВЛЕН ДО"));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <DatePicker
        disabled={disabled || !isSuspendedUntilRequired}
        format={displayDateFormat}
        style={{ width: "100%" }}
        disabledDate={(currentDate: Dayjs) => {
          if (dayjs().add(-1, "day").diff(currentDate) > 0) {
            return true;
          }
          return false;
        }}
      />
    </Form.Item>
  );
}

export default SuspendedUntil;
