import { ReactElement } from "react";

import Text from "core/lib/Text/Text";
import { safePercent } from "core/shared/tools/safePercent";
import { TaskTypesIdsEnum } from "core/app/api/task/enums";
import { IDashboardShiftStatisticTasksByType } from "core/shared/dashboard/hooks/interfaces";

interface ITotalByTaskTypeProps {
  data: IDashboardShiftStatisticTasksByType | undefined;
  title?: string;
}

const defaultData: IDashboardShiftStatisticTasksByType = {
  name: TaskTypesIdsEnum.OTHER,
  closed_sheduled_tasks: 0,
  sheduled_tasks: 0,
  unsheduled_tasks: 0,
  closed_unsheduled_tasks: 0,
};

function TotalByTaskType({ data = defaultData, title }: ITotalByTaskTypeProps): ReactElement {
  return (
    <>
      {title && (
        <Text boldness="semibold" size="body1">
          {title}
        </Text>
      )}
      {data && (
        <div style={{ lineHeight: 1 }}>
          <Text boldness="semibold" size="label3">
            ФАКТ - {data.closed_sheduled_tasks} шт. ({safePercent(data.closed_sheduled_tasks, data.sheduled_tasks)}%)
          </Text>
          <br />
          <Text boldness="semibold" size="label3">
            ПЛАН - {data.sheduled_tasks} шт.
          </Text>
        </div>
      )}
    </>
  );
}

export default TotalByTaskType;
