import { apiSlice } from "core/app/api";
import { IDictionaryItem, IResponseBody } from "core/shared/interfaces";
import { IDashboardShiftStatisticTasksByTeams } from "core/shared/dashboard/hooks/interfaces";

import { extractResults } from "../../emptyApi";
import { handleUpdateTaskQueryStarted, TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE } from "../../tools";

import {
  ICheckSlpiMonthPlan,
  ICreateSlpiMonthPlansPayload,
  IDashboardShiftStatisticTasksByObjectsSlpi,
  IDashboardShiftStatisticTasksByTeamsSlpiTask,
  IDashboardSlpiMaintenancePlanFactStatistic,
  ISlpiDirectObject,
  ISlpiMonthPlansAndEffort,
  ISlpiObjectMonthPlan,
  ISlpiObjectStat,
  ISLPISystemComplexLight,
  ISlpiTask,
  ISlpiWorkType,
  TSlpiDirectObjectLight,
  TSLPISystemComplex,
} from "./interfaces";

const slpiApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      "slpi-object",
      "slpi-department",
      "slpi-object/slpi_stat",
      "slpi-system-complex",
      "slpi-direct-object",
      "slpi-month-plan/check",
      "slpi-direct-object-light",
      "slpi-system-complex-light",
      "slpi-department-extended",
      "slpi-work-types",
      "slpi-units",
      "slpi-month-plan/hours-norm",
      "dashboard/shift-statistic/tasks/by-teams/slpi",
      "dashboard/slpi-maintenance-plan-fact-statistic",
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSlpiObjectsMonthPlans: builder.query<ISlpiObjectMonthPlan[], string>({
        query: (queryString: string) => `slpi-object/?${queryString}`,
        providesTags: ["slpi-object"],
        transformResponse: extractResults,
      }),
      getSlpiDirectObjects: builder.query<ISlpiDirectObject[], string>({
        query: (queryString: string) => `slpi-direct-object/?${queryString}`,
        providesTags: ["slpi-direct-object"],
        transformResponse: extractResults,
      }),
      getSlpiDirectObjectsLight: builder.query<TSlpiDirectObjectLight[], string>({
        query: (queryString: string) => `slpi-direct-object-light/?${queryString}`,
        providesTags: ["slpi-direct-object-light"],
        transformResponse: extractResults,
      }),
      updateSlpiMonthPlan: builder.mutation({
        query(body) {
          return {
            url: `/slpi-month-plan/${body.id}/`,
            method: "PATCH",
            body,
          };
        },
        invalidatesTags: ["slpi-object", "slpi-object/slpi_stat", "slpi-month-plan/check"],
      }),
      checkSlpiMonthPlan: builder.query<ICheckSlpiMonthPlan, string>({
        query: (queryString: string) => `slpi-month-plan/check/?${queryString}`,
        providesTags: ["slpi-month-plan/check"],
      }),
      approveSlpiMonthPlan: builder.mutation({
        query: (queryString: string) => ({
          url: `slpi-month-plan/approve/?${queryString}`,
          method: "GET",
        }),
        invalidatesTags: ["slpi-object"],
      }),
      undoApproveSlpiMonthPlan: builder.mutation({
        query: (queryString: string) => ({
          url: `slpi-month-plan/undo_approve/?${queryString}`,
          method: "GET",
        }),
        invalidatesTags: ["slpi-object"],
      }),
      getSlpiObjectStat: builder.query<ISlpiObjectStat, string>({
        query: (queryString: string) => `slpi-object/slpi_stat/?${queryString}`,
        providesTags: ["slpi-object/slpi_stat"],
      }),
      getDashboardShiftStatisticTasksByObjectsSlpi: builder.query<IDashboardShiftStatisticTasksByObjectsSlpi, string>({
        query: (queryString: string) => `dashboard/shift-statistic/tasks/by-objects/slpi/?${queryString}`,
        providesTags: ["dashboard/shift-statistic/tasks/by-objects/slpi"],
      }),
      getDashboardShiftStatisticTasksByTeamsSlpi: builder.query<
        IDashboardShiftStatisticTasksByTeams<IDashboardShiftStatisticTasksByTeamsSlpiTask>[],
        string
      >({
        query: (queryString: string) => `dashboard/shift-statistic/tasks/by-teams/slpi/?${queryString}`,
        providesTags: ["dashboard/shift-statistic/tasks/by-teams/slpi"],
      }),
      getSlpiSystemComplex: builder.query<TSLPISystemComplex[], string>({
        query: (queryString: string) => `slpi-system-complex/?${queryString}`,
        providesTags: ["slpi-system-complex"],
        transformResponse: extractResults,
      }),
      getSlpiSystemComplexLight: builder.query<ISLPISystemComplexLight[], string>({
        query: (queryString: string) => `slpi-system-complex-light/?${queryString}`,
        providesTags: ["slpi-system-complex-light"],
        transformResponse: extractResults,
      }),
      importSlpiMonthPlans: builder.mutation({
        query: (payload) => ({
          url: `slpi-month-plan/load_month_plans/`,
          method: "POST",
          body: payload,
          cache: "no-cache",
        }),
        invalidatesTags: ["slpi-object", "slpi-object/slpi_stat"],
      }),
      getDashboardSlpiMaintenancePlanFactStatistic: builder.query<IDashboardSlpiMaintenancePlanFactStatistic, string>({
        query: (queryString) => `/dashboard/slpi-maintenance-plan-fact-statistic/?${queryString}`,
        providesTags: ["dashboard/slpi-maintenance-plan-fact-statistic"],
      }),
      getSlpiWorkTypes: builder.query<ISlpiWorkType[], string>({
        query() {
          return `slpi-work-types/`;
        },
        providesTags: ["slpi-work-types"],
        transformResponse: extractResults,
      }),
      getSlpiUnits: builder.query<IDictionaryItem[], string>({
        query(code) {
          return `units/service-units/${code}`;
        },
        providesTags: ["slpi-units"],
      }),
      createSlpiMonthPlans: builder.mutation<null, ICreateSlpiMonthPlansPayload>({
        query: (body) => ({
          url: `slpi-object/create-month-plans/`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["slpi-object/slpi_stat", "slpi-object"],
      }),
      getSlpiMonthPlansAndEffort: builder.query<ISlpiMonthPlansAndEffort, string>({
        query: (queryString) => `slpi-month-plan/hours-norm/?${queryString}`,
        providesTags: ["slpi-month-plan/hours-norm"],
      }),
      getSlpiTasks: builder.query<IResponseBody<ISlpiTask>, string | undefined>({
        query: (queryProps) => `slpi-tasks/?${queryProps}`,
        providesTags: ["open-tasks"],
      }),
      createSlpiTask: builder.mutation({
        query: (payload) => ({
          url: `slpi-tasks/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["tasks", "open-tasks", "tasks-filtered", "kanban-board", "shifttask"],
      }),
      updateSlpiTask: builder.mutation({
        query: ({ rtkQueryCacheKey, ...task }) => ({
          url: `slpi-tasks/${task.id}/`,
          method: "PATCH",
          body: task,
        }),
        async onQueryStarted(args, queryContext) {
          await handleUpdateTaskQueryStarted(args, queryContext);
        },
        invalidatesTags: (result, error, arg) => [
          { type: "task", id: arg.id },
          ...TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE,
        ],
      }),
      importSLPITasks: builder.mutation({
        query: (payload) => ({
          url: `slpi-month-plan/import-tasks/`,
          method: "POST",
          body: payload,
          cache: "no-cache",
        }),
        invalidatesTags: ["tasks", "tasks-filtered"],
      }),
    }),
  });

export const {
  useCreateSlpiMonthPlansMutation,
  useGetSlpiObjectsMonthPlansQuery,
  useUpdateSlpiMonthPlanMutation,
  useApproveSlpiMonthPlanMutation,
  useUndoApproveSlpiMonthPlanMutation,
  useGetSlpiObjectStatQuery,
  useGetDashboardShiftStatisticTasksByTeamsSlpiQuery,
  useGetSlpiSystemComplexQuery,
  useGetSlpiDirectObjectsQuery,
  useGetDashboardShiftStatisticTasksByObjectsSlpiQuery,
  useImportSlpiMonthPlansMutation,
  useLazyCheckSlpiMonthPlanQuery,
  useGetSlpiDirectObjectsLightQuery,
  useGetDashboardSlpiMaintenancePlanFactStatisticQuery,
  useGetSlpiSystemComplexLightQuery,
  useGetSlpiWorkTypesQuery,
  useGetSlpiUnitsQuery,
  useGetSlpiTasksQuery,
  useGetSlpiMonthPlansAndEffortQuery,
  useUpdateSlpiTaskMutation,
  useImportSLPITasksMutation,
  useCreateSlpiTaskMutation,
} = slpiApiSlice;
