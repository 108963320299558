import { ReactElement } from "react";
import clsx from "clsx";

import { TPlanFactStatistic } from "core/shared/dashboard/hooks/interfaces";
import Text from "core/lib/Text/Text";
import { roundTo } from "core/shared/tools/math";

interface ICellProps {
  plans: TPlanFactStatistic;
  facts: TPlanFactStatistic;
  isMounted?: boolean;
}

function FactMinusPlanCell({ plans, facts, isMounted = false }: ICellProps): ReactElement {
  const plan = plans.find((item) => item.is_mounted === isMounted);
  const fact = facts.find((item) => item.is_mounted === isMounted);
  if (!plan || !fact) {
    return <div />;
  }
  const difference = roundTo(fact.amount - plan.amount, 2);
  return (
    <div
      className={clsx({
        advance: difference >= 0,
        lag: difference < 0,
      })}
    >
      <div>
        <Text boldness="black">{difference}</Text>
      </div>
      {difference > 0 && (
        <div>
          <Text size="label3">с опережением плана</Text>
        </div>
      )}
      {difference < 0 && difference / plan.amount < -0.2 && (
        <div>
          <Text size="label3">отставание более чем на 20%</Text>
        </div>
      )}
    </div>
  );
}

export { FactMinusPlanCell };
