import { CSSProperties } from "react";

import { LkuEquipmentTypeCodeEnum, LkuEquipmentTypeEnum } from "./enums";

export const LKU_EQUIPMENT_TYPE_CODES_TAG_COLORS: Record<string, CSSProperties["color"]> = {
  [LkuEquipmentTypeCodeEnum.BKT]: "#87E8DE",
  [LkuEquipmentTypeCodeEnum.KRT]: "#52C41A",
};

export const LKU_EQUIPMENT_TYPE_NAMES_TAG_COLORS: Record<string, CSSProperties["color"]> = {
  [LkuEquipmentTypeEnum.BKT]: "#87E8DE",
  [LkuEquipmentTypeEnum.KRT]: "#52C41A",
};
