import { IDashboardShiftStatisticTasksByTeamsVolsTask } from "core/app/api/units/VOLS/interfaces";
import { IGroup, isGroup } from "core/shared/dashboard";

interface CellProps {
  record: IDashboardShiftStatisticTasksByTeamsVolsTask | IGroup<IDashboardShiftStatisticTasksByTeamsVolsTask>;
}
export function VolsEquipmentType({ record }: CellProps) {
  if (isGroup(record)) {
    return <div />;
  }

  return <span>{record.equipment_type_name}</span>;
}
