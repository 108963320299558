import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";
import { getSystemsString } from "core/pages/SLPI/Yearly/SlpiObject/tools";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

export function SystemComplex({ task }: FieldProps): ReactElement {
  const systems = getSystemsString(task.system_complex?.systems);
  const inventoryNumber = task.system_complex?.inventory_number;
  const value = inventoryNumber ? `${systems} (${inventoryNumber})` : systems;

  return <Field label="Cистемы" value={value} />;
}
