import { Form, Select } from "antd";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import qs from "qs";

import { IDictionaryItem } from "core/shared/interfaces";
import { useUnitId } from "core/shared/unit/useUnitId";
import dayjs, { apiDateFormat } from "core/shared/tools/dayjs";
import { useGetEquipmentsQuery } from "core/app/api";
import { TaskTypesIdsEnum } from "core/app/api/task/enums";

const LIMIT = 5000;

interface EquipmentFieldProps {
  required?: true;
  taskType?: TaskTypesIdsEnum;
}

function EquipmentField({ required, taskType }: EquipmentFieldProps) {
  const [unitId] = useUnitId();
  const isMounted = Form.useWatch("is_mounted");
  const equipmentTypeId = Form.useWatch("equipment_type_id");
  const form = Form.useFormInstance();
  const queryParams: Record<string, string | number | boolean | undefined | null> = {
    unit: unitId,
    is_mounted: isMounted,
    active_for: dayjs().format(apiDateFormat),
    limit: LIMIT,
  };
  if (equipmentTypeId) {
    queryParams.equipment_type = equipmentTypeId;
  }

  const isSkip = isMounted === undefined;

  const queryString = qs.stringify(queryParams);
  const { data, isFetching } = useGetEquipmentsQuery(isSkip ? skipToken : queryString);

  const handleChange = (val: number) => {
    const equipment = (data?.results || []).find((item) => item.id === val);
    if (equipment) {
      form.setFieldValue("equipment_type_id", equipment.equipment_type_id);
      form.setFieldValue("location_id", equipment.location_id);
    } else {
      form.setFieldValue("location_id", null);
    }
    form.validateFields(["equipment_type_id"]);
  };
  const options = (data?.results || []).map((item: IDictionaryItem) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <Form.Item
      label="ЕДИНИЦА ОБОРУДОВАНИЯ"
      name="equipment_id"
      colon={false}
      rules={required && [{ required: true, message: "" }]}
    >
      <Select
        className="equipment-field"
        allowClear
        optionFilterProp="children"
        showSearch
        loading={isFetching}
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        onChange={handleChange}
        options={options}
      />
    </Form.Item>
  );
}

export default EquipmentField;
