import { DownOutlined } from "@ant-design/icons";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { Children, MouseEventHandler, ReactElement } from "react";

import { CanIView, ICanIViewProps } from "core/shared/components/CanIView";
import { Text } from "core/lib";

import { IAppBarMenuElementProps } from "./AppBarMenuItem";

interface AppBarMenuSectionProps {
  label: string;
  canIViewProps?: Omit<ICanIViewProps, "children">;
  children?: ReactElement<IAppBarMenuElementProps> | Array<ReactElement<IAppBarMenuElementProps>>;
  onItemClick: MouseEventHandler<HTMLAnchorElement>;
}

export function AppBarMenuSection({ label, canIViewProps: canIView, children, onItemClick }: AppBarMenuSectionProps) {
  if (!children) return null;

  const childrens = Children.map(
    children,
    (child) => React.isValidElement(child) && React.cloneElement(child, { onClick: child.props.onClick || onItemClick })
  );

  return (
    <CanIView {...canIView}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<DownOutlined />} aria-controls="panel1a-content" id="panel1a-header">
          <Text size="body3" boldness="semibold">
            {label}
          </Text>
        </AccordionSummary>
        <AccordionDetails>{childrens}</AccordionDetails>
      </Accordion>
    </CanIView>
  );
}
