import { apiSlice } from "../..";
import { handleUpdateTaskQueryStarted, TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE } from "../../tools";
import { ITask } from "../interfaces";

import { ICreateOpinTaskBody } from "./interfaces";

const opinApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOpinTask: builder.query<ITask, number | string>({
      query: (id) => `opin-tasks/${id}/`,
      providesTags: (result, error, taskId) => [{ type: "task", id: Number(taskId) }],
    }),
    createOpinTask: builder.mutation<ITask, ICreateOpinTaskBody>({
      query: (body) => ({
        url: "opin-tasks/",
        method: "POST",
        body,
      }),
    }),
    updateOpinTask: builder.mutation({
      query: ({ rtkQueryCacheKey, ...task }) => ({
        url: `opin-tasks/${task.id}/`,
        method: "PATCH",
        body: task,
      }),
      async onQueryStarted(args, queryContext) {
        await handleUpdateTaskQueryStarted(args, queryContext);
      },
      invalidatesTags: (result, error, arg) => [
        { type: "task", id: arg.id },
        { type: "task", id: arg.execution_task_id },
        ...TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE,
      ],
    }),
  }),
});

export const { useCreateOpinTaskMutation, useGetOpinTaskQuery, useUpdateOpinTaskMutation } = opinApiSlice;
