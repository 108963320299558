import { Row, Col, Empty, Form } from "antd";
import qs from "qs";
import { useState } from "react";

import { useGetDashboardMaintenancePlanFactStatisticQuery } from "core/app/api/dashboard";
import { useUnitId } from "core/shared/unit/useUnitId";
import { TPlanFactStatistic } from "core/shared/dashboard/hooks/interfaces";

import { Bars } from "../Bars";
import { TablePspa } from "../Table/TablePspa";

import { PspaMaintenanceDashboardFilters } from "./Filters";

export const getStatAmount = (stat: TPlanFactStatistic, isMounted: boolean): number => {
  const amount = stat?.find((item) => item.is_mounted === isMounted)?.amount;
  if (amount === undefined) {
    return 0;
  }
  return amount;
};

export default function PspaMaintenanceStatistic() {
  const [form] = Form.useForm();
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [unitId] = useUnitId();

  const queryParams: Record<string, any> = {
    unit_id: unitId,
    year,
    month,
  };

  const { data, isSuccess } = useGetDashboardMaintenancePlanFactStatisticQuery(qs.stringify(queryParams));

  if (!isSuccess) {
    return <Empty />;
  }

  const isCurrentYear = year === new Date().getFullYear();
  const isCurrentMonth = month === new Date().getMonth() + 1;
  const isCurrentDate = isCurrentYear && isCurrentMonth;

  return (
    <Form form={form}>
      <Row gutter={60}>
        <Col span={4}>
          <PspaMaintenanceDashboardFilters
            year={year}
            month={month}
            onChangeYear={(y) => setYear(y)}
            onChangeMonth={(m) => setMonth(m)}
          />
        </Col>
        <Col span={8}>
          <TablePspa data={data} isCurrentDate={isCurrentDate} />
        </Col>
        <Col span={6}>
          {data && (
            <Bars
              title="Стационарное ТО"
              month={getStatAmount(data.plans_for_the_month, false)}
              day={getStatAmount(data.plans_for_the_day, false)}
              fact={getStatAmount(data.facts_for_the_day, false)}
              isCurrentDate={isCurrentDate}
            />
          )}
        </Col>
        <Col span={6}>
          {data && (
            <Bars
              title="НАВЕСНОЕ ТО"
              month={getStatAmount(data.plans_for_the_month, true)}
              day={getStatAmount(data.plans_for_the_day, true)}
              fact={getStatAmount(data.facts_for_the_day, true)}
              isCurrentDate={isCurrentDate}
            />
          )}
        </Col>
      </Row>
    </Form>
  );
}
