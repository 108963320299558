import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { message } from "antd";
import { Mutex } from "async-mutex";

import { baseUrl } from "core/shared/constants";
import { logOut, setToken } from "core/app/store/token-slice";
import type { TRootState } from "core/app/store";
import { IResponseBody } from "core/shared/interfaces";

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as TRootState).token;
    if (token) {
      // include token in req header
      headers.set("authorization", `Bearer ${token.access}`);
    }
    return headers;
  },
});

const mutex = new Mutex();

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  const { token } = (api.getState() as TRootState).token;
  if (result.error) {
    if (result.error.status === 500) {
      message.error("Ошибка подключения к серверу");
    }
    if (result.error.status === 401 && token) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        // try to get a new token
        const refreshResult = await baseQuery(
          {
            url: "auth/jwt/refresh/",
            method: "POST",
            body: {
              refresh: token.refresh,
            },
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          // store the new token
          api.dispatch(
            setToken({
              ...token,
              ...(refreshResult.data as { access: string }),
            })
          );
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logOut());
        }
        release();
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
  }

  return result;
};

export const extractResults = <T>(responseData: IResponseBody<T>) => responseData.results;

export const emptyApiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [],
  endpoints: (builder) => ({}),
});

export const { util } = emptyApiSlice;
