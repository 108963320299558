import { ReactElement, useContext } from "react";

import { StatisticTeamSelector } from "core/shared/dashboard/ui/TeamSelector";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { useGetDashboardShiftStatisticTasksByTeamsLkuQuery } from "core/app/api/units/LKU";

export default function StatisticTeamSelectorLku(): ReactElement {
  const { queryString } = useContext(ShiftContext);
  const { data, isSuccess } = useGetDashboardShiftStatisticTasksByTeamsLkuQuery(queryString, { skip: !queryString });

  if (!(isSuccess && data)) {
    return <div />;
  }

  return <StatisticTeamSelector teams={data} />;
}
