import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetTeamQuery } from "core/app/api/team";
import { useTeamId } from "core/shared/tasks-for-shift/store/useTeamId";

export function useTeam(teamId?: number | null) {
  const [stateTeamId] = useTeamId();
  const id = teamId ?? stateTeamId;
  const { data } = useGetTeamQuery(id ?? skipToken);
  return data;
}
