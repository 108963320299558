import { apiSlice } from "core/app/api";
import {
  IDashboardMaintenancePlanFactStatistic,
  IDashboardOpenedTasksStatistic,
  IDashboardShiftStatistic,
  IDashboardShiftStatisticTasks,
  IDashboardShiftStatisticTasksByEquipmentTypes,
} from "core/shared/dashboard/hooks/interfaces";

const dashboardApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: ["dashboard/opened-tasks-statistic", "dashboard/maintenance-plan-fact-statistic"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDashboardOpenedTasksStatistic: builder.query<IDashboardOpenedTasksStatistic, string>({
        query: (queryString: string) => ({
          url: `dashboard/opened-tasks-statistic/?${queryString}`,
        }),
        providesTags: ["dashboard/opened-tasks-statistic"],
      }),
      getDashboardMaintenancePlanFactStatistic: builder.query<IDashboardMaintenancePlanFactStatistic, string>({
        query: (queryString: string) => ({
          url: `dashboard/maintenance-plan-fact-statistic/?${queryString}`,
        }),
        providesTags: ["dashboard/maintenance-plan-fact-statistic"],
      }),
      getDashboardShiftStatistic: builder.query<IDashboardShiftStatistic, string>({
        query: (queryString: string) => `dashboard/shift-statistic/?${queryString}`,
        providesTags: ["dashboard/shift-statistic"],
      }),
      getDashboardShiftStatisticTasks: builder.query<IDashboardShiftStatisticTasks, string>({
        query: (queryString: string) => `dashboard/shift-statistic/tasks/?${queryString}`,
        providesTags: ["dashboard/shift-statistic/tasks"],
      }),
      getDashboardShiftStatisticTasksByEquipmentTypes: builder.query<
        IDashboardShiftStatisticTasksByEquipmentTypes,
        string
      >({
        query: (queryString: string) => `dashboard/shift-statistic/tasks/by-equipment-types/?${queryString}`,
        providesTags: ["dashboard/shift-statistic/tasks/by-equipment-types"],
      }),
    }),
  });

export const {
  useGetDashboardOpenedTasksStatisticQuery,
  useGetDashboardMaintenancePlanFactStatisticQuery,
  useGetDashboardShiftStatisticQuery,
  useGetDashboardShiftStatisticTasksQuery,
  useGetDashboardShiftStatisticTasksByEquipmentTypesQuery,
} = dashboardApiSlice;
