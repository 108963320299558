import { EtoTaskStepNameEnum } from "core/app/api/task/eto/enums";

import { useCurrentStep } from "./useCurrentStep";

/**
 * Проверяет, должны ли быть заблокированы поля задачи на основе
 * текущего шага задачи и переданного шага.
 * @param stepName
 * @returns
 */
export function useStepFieldsDisabled(stepName: EtoTaskStepNameEnum) {
  const currentStep = useCurrentStep();
  if (currentStep?.step_name === EtoTaskStepNameEnum.POST_SHIFT_CHECK) {
    if (stepName === EtoTaskStepNameEnum.PERFORMING_ETO || stepName === EtoTaskStepNameEnum.PRE_SHIFT_CHECK) {
      return true;
    }
  }
  return false;
}
