import dayjs from "dayjs";
import { Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { Button } from "core/lib";
import { useBackendError } from "core/shared/main/hooks/useBackendError";
import { ITask } from "core/app/api/task/interfaces";
import { useGetTransportMaintenanceQuery, useGetEquipmentMaintenanceQuery } from "core/app/api/units/PSPA";
import { IMaintenanceControl } from "core/app/api/units/PSPA/interfaces";
import { useUpdateTask } from "core/shared/task/hooks/useUpdateTask";
import { useShiftPlan } from "core/shared/shift/useShiftPlan";
import { ShiftPlanStatusesEnum } from "core/app/api/shift/enums";
import { AppRoutes } from "core/shared/constants.routes";

import { Field } from "../Fields/Field";

interface MaintenanceControlProps {
  task: ITask;
}

/** Блок элементов для добавления задачи в наряд-задание
 * из карточки задачи АВР, ЗНО, доп.работ
 * для стационарного оборудования ЦГПС ЗФ (это ПСПА ЗФ). */
export function MaintenanceControl({ task }: MaintenanceControlProps) {
  const teamId = task.team_id;
  const teamDate = task.team?.date;
  const transportId = task.transport_id;
  const equipmentId = transportId ? null : task.equipment_id; // в случае навесного оборудования, нужно будет по нашей кнопке создавать ТО на транспорт, а не на оборудование
  const { data: equipmentMaintenance } = useGetEquipmentMaintenanceQuery(
    equipmentId ? { id: equipmentId, params: { on_date: teamDate } } : skipToken
  );
  const { data: transportMaintenance } = useGetTransportMaintenanceQuery(
    transportId ? { id: transportId, params: { on_date: teamDate } } : skipToken
  );
  const maintenanceControl: IMaintenanceControl | undefined = equipmentMaintenance || transportMaintenance;

  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task?.discriminator); // useUpdatePspaTaskMutation / useUpdatePspaKfTaskMutation
  function addTaskToTeam() {
    updateTaskRequest({ id: maintenanceControl?.next_maintenance?.task_id, team_id: teamId, planned_date: teamDate });
  }
  useBackendError(updateTaskResult, "Ошибка добавления в наряд-задание", ["team_id"]);

  const isTeamDisbanded = task.team?.disbanded;

  /** Список причин, по которым кнопка добавления в наряд-задание заблокирована */
  const disabledCause: string[] = [];

  const { pathname } = useLocation();
  if (pathname !== AppRoutes.taskForShift)
    disabledCause.push('задачу по ТО в текущую смену можно добавить только на странице "Задачи на смену"');

  const shiftPlan = useShiftPlan();
  if (shiftPlan?.status !== ShiftPlanStatusesEnum.APPROVED) disabledCause.push("текущая смена не открыта");

  if (!teamId) disabledCause.push("бригада не указана");
  else if (isTeamDisbanded) disabledCause.push("бригада расформирована");
  else if (!teamDate) disabledCause.push("дата бригады не указана");

  if (!maintenanceControl?.next_maintenance?.execution_date) disabledCause.push("не определена следующая дата ТО");
  else if (!maintenanceControl?.next_maintenance?.task_id) disabledCause.push("не найдена задача для следующего ТО");

  if (teamId && maintenanceControl?.next_maintenance?.team_id === teamId)
    disabledCause.push(
      `задача уже назначена на ${dayjs(maintenanceControl?.next_maintenance?.execution_date)?.format("DD.MM.YYYY")}`
    );

  const disabledTitle = disabledCause.length
    ? `Добавление в наряд-задание не доступно, т.к. ${disabledCause.join(", ")}`
    : "";
  const isDisabledButton = disabledTitle !== "";

  return (
    <>
      <Field
        label="Дата последнего ТО"
        value={
          maintenanceControl?.last_maintenance?.execution_date
            ? dayjs(maintenanceControl.last_maintenance.execution_date).format("DD MMMM YYYY")
            : "нет"
        }
      />
      <Field
        label="Дата следующего ТО"
        value={
          maintenanceControl?.next_maintenance?.execution_date
            ? dayjs(maintenanceControl.next_maintenance.execution_date).format("DD MMMM YYYY")
            : "нет"
        }
      />
      <br />
      <Tooltip title={disabledTitle}>
        <Button
          type="primary"
          className="w-100"
          onClick={() => addTaskToTeam()}
          disabled={isDisabledButton}
          loading={updateTaskResult.isLoading}
        >
          Добавить в наряд-задание
        </Button>
      </Tooltip>
    </>
  );
}
