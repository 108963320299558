import { ReactElement } from "react";
import { Alert, Col, Row, Tooltip } from "antd";

import icAlertZabbixProblemBig from "core/images/ic-alert-zabbix-problem-big.svg";
import icAlertZabbixUnknownBig from "core/images/ic-alert-zabbix-unknown-big.svg";
import { useGetEquipmentLastProblemQuery } from "core/app/api";

import Skeletons from "../../Skeletons";

interface ZabbixAlertCompactProps {
  text: string;
  icon: string;
}

function ZabbixAlertCompact({ text, icon }: ZabbixAlertCompactProps): ReactElement {
  return (
    <Row style={{ fontWeight: 600, color: "#FF8A00" }}>
      <Col style={{ width: "60px" }}>
        <img className="ic-alert-zabbix" src={icon} alt="" />
      </Col>
      <Col span={16}>{text}</Col>
    </Row>
  );
}

interface ZabbixAlertBigProps {
  text: string;
  icon: string;
  type: "success" | "info" | "warning" | "error";
  problem?: string;
}

function ZabbixAlertBig({ text, icon, type, problem }: ZabbixAlertBigProps): ReactElement {
  return (
    <Tooltip title={problem ? `Проблема: ${problem}` : "Проблема не указана"}>
      <Alert
        className="mb-3"
        message={text}
        type={type}
        showIcon
        icon={<img className="ic-alert-zabbix" src={icon} alt="" />}
      />
    </Tooltip>
  );
}

interface ZabbixAlertInternalProps {
  equipmentId: number;
  compact: boolean;
  showUnknownState: boolean;
}

function ZabbixAlertInternal({
  equipmentId,
  compact,
  showUnknownState,
}: ZabbixAlertInternalProps): ReactElement | null {
  const { data: lastProblem, isLoading: isLoadingLastProblem } = useGetEquipmentLastProblemQuery(equipmentId);

  if (isLoadingLastProblem) return <Skeletons />;

  // компактное отображение
  if (compact) {
    if (lastProblem) return <ZabbixAlertCompact text="Статус: Неисправно" icon={icAlertZabbixProblemBig} />;
    if (showUnknownState) return <ZabbixAlertCompact text="Статус неизвестен" icon={icAlertZabbixUnknownBig} />;
    return null; // тревога отсутствует
  }

  // некомпактное отображение
  if (lastProblem)
    return (
      <ZabbixAlertBig
        text="Статус: Неисправно"
        icon={icAlertZabbixProblemBig}
        type="warning"
        problem={lastProblem.problem}
      />
    );
  if (showUnknownState) return <ZabbixAlertBig text="Статус неизвестен" icon={icAlertZabbixUnknownBig} type="info" />;
  return null; // тревога отсутствует
}

interface ZabbixAlertProps {
  equipmentId?: number | null;
  compact?: boolean;
  showUnknownState?: boolean;
  showMissingEquipment?: boolean;
}

export function ZabbixAlert({
  equipmentId,
  compact,
  showUnknownState,
  showMissingEquipment,
}: ZabbixAlertProps): ReactElement | null {
  if (equipmentId)
    return <ZabbixAlertInternal equipmentId={equipmentId} compact={!!compact} showUnknownState={!!showUnknownState} />;

  if (showMissingEquipment) {
    if (compact) return <ZabbixAlertCompact text="Оборудование отсутствует" icon={icAlertZabbixUnknownBig} />;
    return <ZabbixAlertBig text="Оборудование отсутствует" icon={icAlertZabbixUnknownBig} type="info" />;
  }

  return null; // не показывать тревогу, если оборудование отсутствует
}
