import { Select, Form } from "antd";
import { ReactElement } from "react";

import { IEmployee } from "core/app/store/employee-slice";
import { useEmployees } from "core/shared/main/hooks/useEmployees";

interface EmployeeSelectProps {
  teamId?: number | null;
}

function ExecutorSelect({ teamId }: EmployeeSelectProps): ReactElement {
  const { data: employees } = useEmployees({ team_id: teamId });

  let options = [
    {
      label: "",
      value: 0,
    },
  ];
  if (employees?.results) {
    options = employees.results.map((employee: IEmployee) => ({
      label: employee.full_name,
      value: employee.id,
    }));
  }

  return (
    <Form.Item
      name="executor"
      label="ФАМИЛИЯ ИМЯ ОТЧЕТСТВО"
      rules={[{ required: true, message: "Пожалуйста, введите исполнителя!" }]}
    >
      <Select
        style={{ width: "100%" }}
        showSearch
        allowClear
        optionFilterProp="children"
        dropdownStyle={{ zIndex: 2000 }}
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
        options={options}
      />
    </Form.Item>
  );
}

export { ExecutorSelect };
