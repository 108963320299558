import dayjs from "dayjs";

import { partOfFileNameDateFormat } from "core/shared/tools/dayjs";

import { emptyApiSlice } from "../emptyApi";
import { createDownloadResponseHandler } from "../tools";

export const reportsApiSlice = emptyApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStationaryStatusReport: builder.query<void, void>({
      query() {
        const today = dayjs();
        return {
          url: `report/stationary-equipments-report`,
          method: "GET",
          responseHandler: createDownloadResponseHandler(
            `Статус по стационарному ${today.format(partOfFileNameDateFormat)}`,
            false
          ),
          cache: "no-cache",
        };
      },
    }),
  }),
});

export const { useLazyGetStationaryStatusReportQuery } = reportsApiSlice;
