import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function ProjectNumber({ task }: FieldProps): ReactElement {
  return <Field label="Номер проекта" value={task.system_complex?.project_number} />;
}

export { ProjectNumber };
