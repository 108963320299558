import { ReactElement } from "react";
import { Button, Row, Col, Checkbox } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import clsx from "clsx";

import Text from "core/lib/Text/Text";
import { useMediaContext } from "core/shared/task/context/mediaContext";
import FileTypeIcon from "core/shared/components/FileTypeIcon";
import { fileNameAbbreviation } from "core/shared/tools/fileNameAbbreviation";
import dayjs, { displayDateFormat, hmTimeFormat } from "core/shared/tools/dayjs";
import { useGetMediaQuery } from "core/app/api";
import { IAttachment } from "core/app/api/task/interfaces";
import { useDeleteAttachmentMutation, useSetAttachmentShouldBeSentMutation } from "core/app/api/task";

interface MediaItemProps {
  taskId: number;
  attachment: IAttachment;
  disabled: boolean;
}

function MediaItem({ taskId, attachment, disabled }: MediaItemProps): ReactElement {
  const [deleteTaskRequest] = useDeleteAttachmentMutation();
  const [setAttachmentShouldBeSentRequest] = useSetAttachmentShouldBeSentMutation();
  const { data } = useGetMediaQuery({
    taskId,
    attachmentId: attachment.id,
  });
  const { setSelectedMediaId } = useMediaContext();

  return (
    <>
      {!disabled && (
        <Button
          type="link"
          className="ic-close"
          onClick={() => {
            deleteTaskRequest({ taskId, attachmentId: attachment.id });
          }}
        >
          <CloseCircleFilled style={{ fontSize: "24px", color: "#bcceda" }} />
        </Button>
      )}
      <Checkbox
        className="media-selection-checkbox"
        checked={attachment.should_be_sent}
        disabled={disabled}
        onChange={(e) => {
          setAttachmentShouldBeSentRequest({
            taskId,
            attachmentId: attachment.id,
            shouldBeSent: e.target.checked,
          });
        }}
      />
      {!attachment.content_type.includes("video") && !attachment.content_type.includes("image") ? (
        <a href={data} download={attachment.original_name} rel="noreferrer" target="_blank">
          <Row
            gutter={4}
            className={clsx("media-upload", {
              "media-upload__checked": attachment.should_be_sent,
            })}
          >
            <Col span={8} style={{ display: "flex", alignSelf: "center", minWidth: "100%", justifyContent: "center" }}>
              <FileTypeIcon originalName={attachment.original_name} mimeType={attachment.content_type} size={20} />
            </Col>
          </Row>
        </a>
      ) : (
        <Row
          gutter={4}
          className={clsx("media-upload", {
            "media-upload__checked": attachment.should_be_sent,
          })}
          onClick={() => {
            setSelectedMediaId(attachment.id);
          }}
        >
          {attachment.content_type.includes("image") && (
            <Col span={24} style={{ padding: 0 }}>
              {attachment.content_type.includes("image") && (
                <div className="media-upload-image">
                  <img
                    style={{ width: "100%", height: "100%", minWidth: "100%", objectFit: "cover" }}
                    src={data}
                    alt={attachment.original_name}
                  />
                </div>
              )}
            </Col>
          )}
          {attachment.content_type.includes("video") && (
            <Col span={8} style={{ display: "flex", alignSelf: "center", minWidth: "100%", justifyContent: "center" }}>
              <FileTypeIcon originalName={attachment.original_name} mimeType={attachment.content_type} size={20} />
            </Col>
          )}
        </Row>
      )}
      <div className="file-name">
        <Text size="label3" boldness="regular">
          {dayjs(attachment.created_at).format(displayDateFormat)}
        </Text>
        <Text size="label3" boldness="regular">
          в {dayjs(attachment.created_at).format(hmTimeFormat)}
        </Text>
        <Text size="label3" boldness="regular" style={{ color: "#BAC5D1" }}>
          {fileNameAbbreviation(attachment.original_name)}
        </Text>
      </div>
    </>
  );
}

export { MediaItem };
