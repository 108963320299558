import { apiSlice } from "core/app/api";

import { extractResults } from "../emptyApi";

import { ITeam } from "./interfaces";

const teamApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query<ITeam[], string>({
      providesTags: ["teams"],
      query: (queryString: string) => `teams/?${queryString}`,
      transformResponse: extractResults,
    }),
    getTeam: builder.query<ITeam, number | { id: number; query: string }>({
      providesTags: ["team"],
      query: (payload) => {
        if (typeof payload === "number") {
          return `teams/${payload}/`;
        }
        return `teams/${payload.id}/${payload.query}`;
      },
    }),
    createTeam: builder.mutation({
      query: (team) => ({
        url: `teams/`,
        method: "POST",
        body: team,
      }),
      invalidatesTags: ["teams", "shifttask", "employees", "shift-plan/available-statuses"],
    }),
    deleteTeam: builder.mutation({
      query: (id) => ({
        url: `teams/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["teams", "shifttask", "employees", "tasks", "tasks-filtered", "shift-plan/available-statuses"],
    }),
    disbandTeam: builder.mutation({
      query: (payload) => ({
        url: `teams/${payload}/disband/`,
        method: "POST",
        body: payload.body,
      }),
      invalidatesTags: [
        "teams",
        "shifttask",
        "employees",
        "tasks",
        "tasks-filtered",
        "shift-plan/available-statuses",
        "kanban-board",
      ],
    }),
    updateTeam: builder.mutation({
      query: (team) => ({
        url: `teams/${team.id}/`,
        method: "PUT",
        body: team,
      }),
      invalidatesTags: ["teams", "shifttask", "employees"],
    }),
    createTeams: builder.mutation({
      query: (teams) => ({
        url: `/teams/bulk-create/`,
        method: "POST",
        body: teams,
      }),
      invalidatesTags: ["teams"],
    }),
  }),
});

export const {
  useGetTeamQuery,
  useGetTeamsQuery,
  useCreateTeamMutation,
  useCreateTeamsMutation,
  useDeleteTeamMutation,
  useDisbandTeamMutation,
  useUpdateTeamMutation,
} = teamApiSlice;
