export enum MaintenanceTypeEnum {
  MOUNTED = "mounted",
  STATIONARY = "stationary",
}

export enum EquipmentSystemSummaryMonthStatusEnum {
  PLANNING = "PLANNING",
  APPROVE_WORKSHOP_LEAD = "APPROVE_WORKSHOP_LEAD",
  APPROVE_CUSTOMER = "APPROVE_CUSTOMER",
  APPROVED = "APPROVED",
}

export enum PspaMonthPlanSignatureUrlPartEnum {
  CUSTOMER = "customer-representative",
  UNIT_LEAD = "unit-lead",
  WORK_SHOP_LEAD = "work-shop-lead",
}
