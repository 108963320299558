/* eslint-disable camelcase */
import { IEmployee } from "core/app/store/employee-slice";

export function getShortEmployeeName({ last_name, first_name, middle_name }: IEmployee) {
  if (!first_name && !middle_name) {
    return last_name;
  }

  if (!middle_name && first_name) {
    return `${last_name} ${first_name[0].toUpperCase()}.`;
  }

  return `${last_name} ${first_name[0].toUpperCase()}.${middle_name[0].toUpperCase()}.`;
}
