import { Row, Col, Button, Spin, Space, Form, InputNumber } from "antd";
import { ReactElement, useEffect } from "react";

import { ITask } from "core/app/api/task/interfaces";
import { useAppDispatch } from "core/app/store";
import { useUpdateTask } from "core/shared/task/hooks/useUpdateTask";
import { setTask } from "core/app/store/task-slice";
import { useTaskId } from "core/shared/task/hooks/useTaskId";
import { useTask } from "core/shared/task/hooks/useTask";
import { TaskSnapMomentEnum } from "core/app/api/task/enums";

import "core/widgets/TaskModals/Stationary/styles.scss";

import OossaMaintenanceTaskGeneralInfo from "./OossaMaintenanceTaskGeneralInfo";
import OossaMaintenanceTaskExecution from "./OossaMte";
import OossaMaintenanceTaskFooter from "./OossaMaintenanceTaskFooter";

/** Карточка технического обслуживания (ТО) для ООССА. */
export default function OossaMaintenanceTaskContent(): ReactElement {
  const dispatch = useAppDispatch();
  const { data: task, refetch } = useTask();
  const [, , moment] = useTaskId();
  const isCheckMoment = moment === TaskSnapMomentEnum.TEAM_DISBAND;
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task?.discriminator);

  const handleSave = (values: Partial<ITask>) => {
    updateTaskRequest(values);
  };

  useEffect(() => {
    if (updateTaskResult.isSuccess) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateTaskResult.isSuccess]);

  useEffect(() => {
    if (task) {
      dispatch(setTask({ ...task }));
    }
  }, [dispatch, task]);

  if (task) {
    return (
      <Form initialValues={task} scrollToFirstError onFinish={handleSave} layout="vertical">
        <Form.Item name="id" hidden>
          <InputNumber />
        </Form.Item>
        <Space
          direction="vertical"
          size="small"
          style={{ padding: "0", /* backgroundColor: "#354A5FE5", */ maxWidth: "100%" }}
        >
          {/* Верхняя часть диалога - название, кнопки, атрибуты задания */}
          <OossaMaintenanceTaskGeneralInfo task={task} />
          <hr />
          {!!task.maintenance_execution.length && (
            // Средняя часть диалога - список работ
            <>
              <OossaMaintenanceTaskExecution task={task} />
              <hr />
            </>
          )}

          {/* Нижняя часть диалога - кто закрыл, какие файлы прикрепил */}
          <OossaMaintenanceTaskFooter task={task} />
          {/* Кнопка "Сохранить" */}
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                size="large"
                className="w-100"
                htmlType="submit"
                loading={updateTaskResult.isLoading}
                disabled={isCheckMoment}
              >
                Сохранить
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    );
  }
  return (
    <Row align="middle" justify="center" style={{ minHeight: "300px" }}>
      <Spin size="large" />
    </Row>
  );
}
