import { ReactElement } from "react";
import { Divider, Flex } from "antd";

import PageTitle from "core/widgets/PageTitle";
import { PageTitleContainer } from "core/widgets/PageTitleContainer";
import { UnitsTabs } from "core/widgets/UnitsTabs";

import "./styles.scss";
import UnitStatistic from "./Tab";

/** Страница статистики. */
export default function StatisticDashboardPage(): ReactElement {
  return (
    <Flex vertical className="page-dashboard">
      <PageTitleContainer>
        <PageTitle title="СТАТИСТИКА" />
      </PageTitleContainer>
      <Divider />
      <div className="content fixed-min-w">
        <div className="mb-3">
          <UnitsTabs tab={UnitStatistic()} />
        </div>
      </div>
    </Flex>
  );
}
