import {
  IDashboardShiftStatisticTasksByTeamsTask,
  IDashboardShiftStatisticTasksByType,
} from "core/shared/dashboard/hooks/interfaces";
import { IDictionaryItem } from "core/shared/interfaces";

import { IBaseTask, IAdditionalTasksProps } from "../../task/interfaces";

export interface ISlpiMonthPlan extends IDictionaryItem {
  id: number;
  name: "P-1" | "P-2" | "P-3";
  scheduled_days: number[];
  completed_days: number[];
  recovery_days: number[];
  repaired_days: number[];
  work_in_progress_days: number[];
  additional_days: number[];
  additional_tasks: Record<number, number>;
  year: number;
  month: number;
  hours_norm: number;
  employees_quantity: number;
  is_approved: boolean;
  approved_at: null | string;
  created_at: string;
  updated_at: string;
  approved_by: null | number;
  tasks: Record<number, number>;
}

export interface ISlpiObjectSystemSet {
  id: number;
  inventory_number: null | string;
  project_number: null | string;
  systems: IDictionaryItem[] | null;
  month_plans: ISlpiMonthPlan[];
}

export interface ISlpiObject extends IDictionaryItem {
  system_set: ISlpiObjectSystemSet[] | null;
  inventory_number: string | null;
  project_number: string | null;
  type: "GROUND";
  unit: number | null;
  customer: number | null;
}

export interface ISLPISystemComplexLight {
  id: number;
  inventory_number: string | null;
  project_number: string | null;
  systems: IDictionaryItem[] | null;
}

export type TSLPISystemComplex = ISLPISystemComplexLight;

export interface ISlpiObjectMonthPlan {
  object: ISlpiObject;
}

export interface ISlpiDirectObject {
  system_set: {
    id: number;
    inventory_number: string | null;
    project_number: null | number;
    systems: IDictionaryItem[];
  };
  id: number;
  name: string;
  inventory_number: null | string;
  project_number: null | string;
  type: string;
  unit: number | null;
  customer: number | null;
  department: number | null;
  customer_department: number | null;
}

export type TSlpiDirectObjectLight = Omit<ISlpiDirectObject, "system_set">;

export interface ISlpiTask extends IBaseTask, IAdditionalTasksProps {
  customer_department?: IDictionaryItem;
  customer_department_id?: number | null;
  object?: ISlpiObject;
  object_id?: number | null;
  system_complex: TSLPISystemComplex | undefined;
  system_complex_id: null | number;
  effort_by_system_complex?: number | null;
  slpi_maintenance_name?: null | string;
}

export interface ISlpiDepartment extends IDictionaryItem {
  unit: number;
}

export interface ISlpiDepartmentExtended extends ISlpiDepartment {
  customer: IDictionaryItem;
}

export interface ISlpiObjectStat {
  plan: number;
  fact: number;
  facts_by_month: Record<number, [number, number]>;
}

export interface IDashboardSlpiMaintenancePlanFactStatistic {
  plans_for_the_month: number;
  plans_for_the_day: number;
  facts_for_the_day: number;
}

export interface ICheckSlpiMonthPlan {
  data?: string[];
  result: boolean;
}

export enum SlpiWorkTypeGroupEnum {
  RESEARCH = "research",
  WORK = "work",
  INSTRUCTION = "instruction",
}

export interface ISlpiWorkType extends IDictionaryItem {
  description: string;
  group: SlpiWorkTypeGroupEnum;
}
export interface ICreateSlpiMonthPlansPayload {
  unit_id: number;
}

export interface ISlpiMonthPlansAndEffort {
  month_plan_names: {
    name: string;
  }[];
  effort: number | null;
}

export interface IDashboardShiftStatisticTasksByTeamsSlpiTask extends IDashboardShiftStatisticTasksByTeamsTask {
  inventory_number: string;
  object: string;
  customer: string;
}

export interface IDashboardShiftStatisticTasksByObjectsSlpi {
  sheduled_tasks: number;
  closed_sheduled_tasks: number;
  unsheduled_tasks: number;
  closed_unsheduled_tasks: number;
  by_type: IDashboardShiftStatisticTasksByType[];
  by_object: {
    by_type: IDashboardShiftStatisticTasksByType[];
    object: {
      id: number;
      name: string;
      inventory_number: string;
    };
  }[];
}
