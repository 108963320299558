import { Button, Checkbox, Col, Divider, Form, InputNumber, Row, Spin } from "antd";
import { ReactElement, useState } from "react";

import { useDisabled } from "core/shared/task/hooks/useDisabled";
import { useSignatureStatus } from "core/shared/task/hooks/useSignatureStatus";
import { useTask } from "core/shared/task/hooks/useTask";
import { useUpdateTask } from "core/shared/task/hooks/useUpdateTask";
import { useUnit } from "core/shared/unit/useUnit";
import ErrorMessage from "core/widgets/CreateTaskModal/ErrorMessage";
import Media from "core/widgets/TaskModals/shared/Media";
import { OpinActions } from "core/widgets/TaskModals/shared/OpinActions";
import Title from "core/widgets/TaskModals/shared/TaskTitle";

import { ClosingDate } from "../../shared/ClosingDate";
import { Comment } from "../../shared/Comment";
import { ElapsedTime } from "../../shared/ElapsedTime";
import { EmployeesSelect } from "../../shared/EmployeesSelect/FormControlled";
import { SignatureForm } from "../../shared/SignatureForm";
import StateButtons from "../../shared/StateButtons/FormControlled";
import { hideErrorMessageOnSuccessfulCheck } from "../../shared/tools";
import DocumentPacks from "../../shared/Media/Category/DocumentPacks";

import GeneralInfo from "./GeneralInfo";
import { MaintenanceExecutions } from "./MaintenanceExecutions";

import "../styles.scss";

interface ContentProps {
  taskId: number;
}

/** Карточка технического обслуживания (ТО) для стационарного оборудования ПСПА, СДГР, СЛПИ. */
function Content(props: ContentProps): ReactElement {
  const { taskId } = props;
  const { data: task } = useTask(taskId);
  const unit = useUnit();
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task?.discriminator);
  const [signatureStatus] = useSignatureStatus();
  const isDisabled = useDisabled();
  const [form] = Form.useForm();
  const [shouldShowErrorMessage, setShowErrorMessage] = useState(false);

  const handleSave = (values: any) => {
    setShowErrorMessage(false);
    updateTaskRequest(values);
  };

  const handleValidationFailed = () => {
    setShowErrorMessage(true);
  };

  const initialValues = {
    ...task,
    __is_disabled: isDisabled,
  };
  if (task) {
    return (
      <Form
        initialValues={initialValues}
        onValuesChange={(changedValues) => {
          hideErrorMessageOnSuccessfulCheck({
            form,
            changedValues,
            hideErrorMessage: () => {
              setShowErrorMessage(false);
            },
          });
        }}
        scrollToFirstError
        onFinish={handleSave}
        onFinishFailed={handleValidationFailed}
        layout="vertical"
        form={form}
      >
        <Form.Item name="id" label="id" hidden>
          <InputNumber />
        </Form.Item>
        <Form.Item name="__is_disabled" label="__is_disabled" valuePropName="checked" hidden>
          <Checkbox />
        </Form.Item>
        <Title icon task={task} />
        <OpinActions task={task} />
        {!!signatureStatus && <SignatureForm task={task} />}
        {!signatureStatus && (
          <>
            {shouldShowErrorMessage && (
              <Col className="mb-3" span={24}>
                <ErrorMessage message="Невозможно сохранить задачу" />
              </Col>
            )}

            <GeneralInfo task={task} />
            <StateButtons task={task} hideClearButton onValidationFailed={handleValidationFailed} />
            <Divider />
            {!!task.maintenance_execution.length && (
              <>
                <MaintenanceExecutions task={task} />
                <Divider />
              </>
            )}
            <Row gutter={16}>
              <Col span={13}>
                <EmployeesSelect disabled={isDisabled} teamId={task.team_id} required />
                <Row gutter={8} align="bottom">
                  <ElapsedTime useMultiplier={!unit?.isSLPI()} disabled={isDisabled} />
                  <Col span={12}>
                    <ClosingDate value={task.planned_date} />
                  </Col>
                </Row>
              </Col>
              <Col span={11}>
                <Comment disabled={isDisabled} />
              </Col>
            </Row>
            <DocumentPacks task={task} />
            <Media task={task} />
            <Divider />
            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  size="large"
                  className="w-100"
                  htmlType="submit"
                  loading={updateTaskResult.isLoading}
                  disabled={isDisabled}
                >
                  Сохранить
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    );
  }
  return (
    <Row align="middle" justify="center" style={{ minHeight: "300px" }}>
      <Spin size="large" />
    </Row>
  );
}

export default Content;
