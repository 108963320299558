import { StateNamesEnum } from "core/app/api/task/enums";
import { EtoTaskStepNameEnum } from "core/app/api/task/eto/enums";
import { IEtoTask } from "core/app/api/task/eto/interfaces";
import { RoleEnum } from "core/shared/enums";
import { useHasUserOneOfSelectedRoles } from "core/shared/main/hooks/useHasUserOneOfSelectedRoles";
import { useAssistanceCarMaintenanceContext } from "core/widgets/AssistanceCarTaskModal/hooks/useAssistanceCarMaintenanceContext";
import { useCurrentStep } from "core/widgets/AssistanceCarTaskModal/hooks/useCurrentStep";

export function useShowSignatureFlags(task: IEtoTask) {
  const currentStep = useCurrentStep();
  const { selectedStepTab } = useAssistanceCarMaintenanceContext();
  const selectedStep = task.steps.find((step) => step.step_name === selectedStepTab);
  const isUserHasAccess = useHasUserOneOfSelectedRoles([
    RoleEnum.UNIT_LEAD,
    RoleEnum.SHIFT_LEAD,
    RoleEnum.TEAM_LEAD,
    RoleEnum.PSPA_WORKSHOP_LEAD,
  ]);

  const showInitialSignatures = selectedStepTab
    ? [EtoTaskStepNameEnum.PRE_SHIFT_CHECK, EtoTaskStepNameEnum.PERFORMING_ETO].includes(selectedStepTab)
    : false;
  const showFinishSignatures = selectedStepTab === EtoTaskStepNameEnum.POST_SHIFT_CHECK;

  const isInitialSignatureButtonsDisabled =
    !isUserHasAccess ||
    currentStep?.step_name === EtoTaskStepNameEnum.POST_SHIFT_CHECK ||
    currentStep?.editable === false ||
    task.state?.name !== StateNamesEnum.InWork;
  const isFinishSignatureButtonsDisabled = !isUserHasAccess || task.state?.name !== StateNamesEnum.InWork;
  const isDriverSignatureExists = Boolean(selectedStep?.driver_signature_id);
  const isMechanistSignatureExists = Boolean(selectedStep?.mechanic_signature_id);

  return {
    showFinishSignatures,
    showInitialSignatures,
    isInitialSignatureButtonsDisabled,
    isFinishSignatureButtonsDisabled,
    isDriverSignatureExists,
    isMechanistSignatureExists,
  };
}
