import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ru";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import RelativeTime from "dayjs/plugin/relativeTime";
import toObject from "dayjs/plugin/toObject";

import { MonthStatusEnum } from "../enums";

import { roundTo } from "./math";

dayjs.locale("ru");
dayjs.extend(localizedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(toObject);
dayjs.extend(duration);

/** Проверяет строку на соответствие формату ISO 8601 */
export function getIsValidISO8601Duration(durationString: string | null | undefined) {
  if (!durationString) return false;
  const regex = /^P(\d+D)?T?(\d+H)?(\d+M)?$/;
  return regex.test(durationString);
}

/** @todo покрыть тестами */
/** Может принимать строки формата pandas duration и ISO 8601, возвращает dayJs duration Obj */
export function parseDuration(time: string) {
  const durationObj: duration.DurationUnitsObjectType = {};

  const isIsoDuration = getIsValidISO8601Duration(time);
  if (isIsoDuration) {
    return dayjs.duration(time);
  }

  /** Убираем слово "day" или "days" (если оно есть) */
  const formattedTime = time.replace(/\s*day(s)?\s*/i, " ").trim();
  /** Находим строку с днями, если она есть (вначале строки число, далее за ним пробельный символ)   */
  const daysMatch = formattedTime.match(/^(\d+)\s+/);
  if (daysMatch) {
    const [daysString, daysCountString] = daysMatch;
    durationObj.days = parseInt(daysCountString, 10);
  }
  /** Находим часы, минуты, секунды (hh|h:mm:ss) */
  const timeMatch = formattedTime.match(/(\d{1,2}):(\d{2}):(\d{2})/);
  if (timeMatch) {
    const [timeString, hoursString, minutesString, secondsString] = timeMatch;
    Object.assign(durationObj, {
      hours: parseInt(hoursString, 10),
      minutes: parseInt(minutesString, 10),
      seconds: parseInt(secondsString, 10),
    });
  }
  return dayjs.duration(durationObj);
}

export function getDayJsFromDurationString(time: string | null | undefined): dayjs.Dayjs {
  let dayjsTime = dayjs("00:00", "HH:mm");
  if (!time) {
    return dayjsTime;
  }
  const dur = parseDuration(time);
  dayjsTime = dayjsTime.set("days", dur.get("days"));
  dayjsTime = dayjsTime.set("hours", dur.get("hours"));
  dayjsTime = dayjsTime.set("minutes", dur.get("minutes"));
  dayjsTime = dayjsTime.set("seconds", dur.get("seconds"));
  return dayjsTime;
}

/** Вернуть округленное до 2 знаков после запятой количество часов в строке, заданной в формате времени ("ДД ЧЧ:ММ:СС" или "ЧЧ:ММ:СС"). */
export function getHoursFromTime(time: string | undefined | null, round: boolean): number {
  let elapsedTime = 0;
  if (!time) {
    return elapsedTime;
  }
  elapsedTime = parseDuration(time).asHours();
  if (round) {
    return roundTo(elapsedTime, 2);
  }
  return elapsedTime;
}
/** Преобразовывает количество часов в формат DD HH:mm:ss
 * @param hours не должен быть >= 720
 */
export function getTimeFromHours(hours?: number | string | null): string {
  let h = 0;
  if (typeof hours === "string") {
    h = parseFloat(hours);
  } else if (typeof hours === "number") {
    h = hours;
  }
  return dayjs.duration(h, "hours").format("DD HH:mm:ss");
}

export function getDurationString(date?: Dayjs | null) {
  if (!date) {
    return "00:00:00";
  }
  return dayjs(date).format("HH:mm:ss");
}

export function getDayJsFromHours(hours?: number | string | null) {
  return getDayJsFromDurationString(getTimeFromHours(hours));
}

export function getHoursFromDayJs(date?: Dayjs | null) {
  return getHoursFromTime(getDurationString(date), false);
}

export const apiDateFormat = "YYYY-MM-DD";
export const apiDateTimeFormat = "YYYY-MM-DDTHH:mm:ssZ";
export const displayDateFormat = "DD.MM.YYYY";
/** Формат даты, если она используется в имени файла, например, при загрузке отчета. Здесь нежелателен символ точки. */
export const partOfFileNameDateFormat = "DD-MM-YYYY";
export const displayTimeFormat = "H:mm:ss";
export const hmTimeFormat = "HH:mm";
// export const hmDisplayFormat = "Hч. mmм.";

export const getHmDisplayString = ({ hours, minutes }: { hours: number; minutes: number }) =>
  `${hours}ч ${String(minutes).padStart(2, "0")}м`;

export function formatHours(effort: number | null) {
  const hours = effort ?? 0;
  const fullHours = Math.floor(hours);
  const minutes = Math.round((hours - fullHours) * 60);
  return getHmDisplayString({ hours: fullHours, minutes });
}

export function formatDuration(durationString: string | null | undefined) {
  const hours = durationString ? parseDuration(durationString).asHours() : 0;
  return formatHours(hours);
}

export default dayjs;

export function getMonthStatus({ year, month }: { year: number; month: number }) {
  const currentDate = dayjs();
  const givenDate = dayjs(`${year}-${month}-01`);

  if (currentDate.isBefore(givenDate, "month")) {
    return MonthStatusEnum.AFTER;
  }
  if (currentDate.isAfter(givenDate, "month")) {
    return MonthStatusEnum.BEFORE;
  }
  return MonthStatusEnum.NOW;
}
