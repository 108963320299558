import { ReactElement } from "react";
import { Dayjs } from "dayjs";

import { ITask } from "core/app/api/task/interfaces";
import dayjs, { displayDateFormat } from "core/shared/tools/dayjs";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
  label?: string;
  format?: string;
}

function RequestCreationDatetime({ task, label, format }: FieldProps): ReactElement {
  const requestCreationDatetime = dayjs(task.request_creation_datetime);
  const createdAt = dayjs(task.created_at);

  let date: Dayjs | undefined;

  if (createdAt.isValid()) {
    date = createdAt;
  }

  if (requestCreationDatetime.isValid()) {
    date = requestCreationDatetime;
  }

  return (
    <Field
      label={label || "Дата и время регистрации"}
      value={format ? date?.format(format) : date?.format(`${displayDateFormat} г.`)}
    />
  );
}

export { RequestCreationDatetime };
