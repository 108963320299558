import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import dayjs, { apiDateFormat } from "core/shared/tools/dayjs";
import { ITeam } from "core/app/api/team/interfaces";

import { IEmployee } from "../employee-slice";

export const initialState: ITeam = {
  id: null,
  team_number: null,
  team_lead_id: null,
  date: dayjs().format(apiDateFormat),
  shift_number: 1,
  unit_id: 1,
  employees: [],
  team_lead_short_name: null,
  moment: null,
  disbanded: false,
};

export const CurrentTeamSlice = createSlice({
  name: "currentTeam",
  initialState,
  reducers: {
    addEmployee(state, action) {
      state.employees.push(action.payload);
    },
    removeEmployee(state, action) {
      state.employees = state.employees.filter((employee: IEmployee) => employee.id !== action.payload);
      if (action.payload === state.team_lead_id) {
        state.team_lead_id = null;
      }
    },
    reset() {
      return initialState;
    },
    setCurrentTeam(state, action: PayloadAction<ITeam>) {
      return action.payload;
    },
  },
});

export const { addEmployee, removeEmployee, reset, setCurrentTeam } = CurrentTeamSlice.actions;
