import { ReactElement } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

import { IGroup } from "core/shared/dashboard";
import { useDashboardShiftStatisticTasksByTeamsLso } from "core/shared/dashboard/hooks/useDashboardShiftStatisticTasksByTeamsLso";
import { IDashboardShiftStatisticTasksByTeamsLsoTask } from "core/app/api/units/LSO/interfaces";
import { EffortTypeEnum } from "core/models/enums";

import ClosingNote from "../Fields/ClosingNote";
import Effort from "../Fields/Effort";
import ElapsedTime from "../Fields/ElapsedTime";
import { Comment } from "../Fields/Comment";
import RequestDescription from "../Fields/RequestDescription";

import { Object } from "./Object";

type TTableRecord = IDashboardShiftStatisticTasksByTeamsLsoTask | IGroup<IDashboardShiftStatisticTasksByTeamsLsoTask>;

const columns: ColumnsType<IGroup<IDashboardShiftStatisticTasksByTeamsLsoTask>> = [
  {
    title: "ОБЪЕКТ",
    dataIndex: "object",
    render: (_: string, record: TTableRecord) => <Object record={record} />,
    width: 140,
  },
  {
    title: "ЗАКАЗЧИК",
    dataIndex: "customer",
  },
  {
    title: "ОПИСАНИЕ ЗАДАЧИ",
    dataIndex: "request_description",
    width: 300,
    render: (_: string, record: TTableRecord) => <RequestDescription record={record} />,
  },
  {
    title: "СТАТУС",
    dataIndex: "status",
  },
  {
    title: "КОММЕНТАРИЙ",
    dataIndex: "request_comment",
    render: (_: string, record: TTableRecord) => <Comment record={record} />,
  },
  {
    title: "ПРИМЕЧАНИЕ К РАЗРЕШЕНИЮ",
    dataIndex: "closing_note",
    render: (_: string, record: TTableRecord) => <ClosingNote record={record} />,
  },
  {
    title: "ЗАГРУЗКА (ПЛАН)",
    dataIndex: "effort",
    render: (_: string, record: TTableRecord) => <Effort record={record} effortType={EffortTypeEnum.MAN_HOURS} />,
  },
  {
    title: "ЗАГРУЗКА (ФАКТ)",
    dataIndex: "elapsed_time",
    render: (_: string, record: TTableRecord) => <ElapsedTime record={record} effortType={EffortTypeEnum.MAN_HOURS} />,
  },
];

export function StatisticByTeamLsoTable(): ReactElement {
  const dataSource = useDashboardShiftStatisticTasksByTeamsLso();

  return (
    <Table
      expandable={{
        defaultExpandAllRows: true,
        indentSize: 0,
      }}
      size="small"
      dataSource={dataSource}
      rowKey="id"
      columns={columns}
    />
  );
}
