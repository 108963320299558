import { useAppSelector } from "core/app/store";
import { useUnitId } from "core/shared/unit/useUnitId";
import dayjs, { apiDateFormat } from "core/shared/tools/dayjs";
import { TaskTypesIdsEnum } from "core/app/api/task/enums";
import { ISendCreatioTaskBody } from "core/app/api/task/interfaces";
import { useSendCreatioTaskMutation } from "core/app/api/task";

import { taskTypeToCategoryGroupFilterMap } from "../../constants";
import { ICreateTaskModalValues } from "../../interfaces";
import { useRequiredCustomerApproval } from "../../Fields/hooks";
import { CreateTaskBaseForm } from "../CreateTaskBaseForm";

import RecoveryOrServiceRequestContent from "./RecoveryOrServiceRequestContent";

export function RecoveryOrServiceRequest() {
  const [unitId] = useUnitId();
  const { createTaskModalType } = useAppSelector((state) => state.temp);
  const [sendCreatioTask, sendCreatioTaskResult] = useSendCreatioTaskMutation();

  const initialValues: ICreateTaskModalValues = {
    isMounted: false,
    isStpp: false,
    requestCategoryId: "",
    creatioEquipmentId: "",
    transportId: null,
    equipmentTypeId: null,
    equipmentId: null,
    locationId: null,
    description: "",
    attachments: [],
    effort: 0,
    teamId: null,
    shiftNumber: 1,
    plannedDate: "",
    taskListOrshift: "taskList",
  };

  const requiredCustomerApproval = useRequiredCustomerApproval();

  const type = createTaskModalType && taskTypeToCategoryGroupFilterMap[createTaskModalType];

  const createTask = async (values: typeof initialValues) => {
    if (!type || !unitId) {
      return Promise.reject();
    }
    const body: ISendCreatioTaskBody = {
      type,
      unit: unitId,
      request_category: values.requestCategoryId,
      equipment_type: values.equipmentTypeId,
      description: values.description,
      location: values.locationId,
    };

    if (values.effort) {
      body.effort = values.effort;
    }
    if (values.effort_duration) {
      body.effort_duration = values.effort_duration;
    }

    if (values.teamId) {
      body.team_id = values.teamId;
    }
    if (values.shiftNumber && !requiredCustomerApproval) {
      body.shift_number = values.shiftNumber;
    }
    if (values.plannedDate) {
      body.planned_date = dayjs(values.plannedDate).format(apiDateFormat);
    }

    if (values.isStpp && createTaskModalType !== TaskTypesIdsEnum.RECOVERY) {
      body.itsm_stpp_equipment_type = values.creatioEquipmentId;
    } else {
      body.itsm_equipment = values.creatioEquipmentId;
    }

    if (values.isMounted) {
      body.transport = values.transportId;
    } else {
      body.mobile_brigade_equipment = values.equipmentId;
    }

    body.attachments = values.attachments || [];

    return sendCreatioTask(body).unwrap();
  };

  return (
    <CreateTaskBaseForm
      initialValues={initialValues}
      createTaskFunction={createTask}
      createTaskIsLoading={sendCreatioTaskResult.isLoading}
      shouldCancelSubmit={!unitId || !type}
      canSwitchTypes
    >
      <RecoveryOrServiceRequestContent />
    </CreateTaskBaseForm>
  );
}
