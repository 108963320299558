import { IUser } from "core/app/api/interfaces";

import { IBaseTask } from "../interfaces";

export interface ICreateOpinTaskBody {
  execution_task_id: number;
}

export enum CommentStateEnum {
  DRAFT = "DRAFT",
  FINAL = "FINAL",
}

interface IComment {
  created_at: string;
  id: number;
  message: string;
  updated_at: string;
  user?: IUser;
  user_id: number;
  state?: CommentStateEnum;
}

export interface IOpinTask extends IBaseTask {
  execution_task_id: number;
  comments?: IComment[];
  initiator_user?: IUser;
  initiator_user_id?: number;
}
