import { useEffect } from "react";
import { Button, Col, Row, Space } from "antd";

import { RoleEnum } from "core/shared/enums";
import { CanIView } from "core/shared/components/CanIView";
import Text from "core/lib/Text/Text";
import { useOpinTaskDraftId } from "core/shared/task/hooks/useOpinTaskDraftId";
import { ITask } from "core/app/api/task/interfaces";
import { useUpdateTask } from "core/shared/task/hooks/useUpdateTask";
import { useCreateOpinTaskMutation } from "core/app/api/task/opin";
import { OpinStateEnum } from "core/app/api/task/opin/enums";
import { StateNamesEnum } from "core/app/api/task/enums";
import { TaskDiscriminatorEnum } from "core/app/api/enums";
import { OpinStateTag } from "core/widgets/OpinStateTag";

interface Props {
  task?: ITask;
}

export function OpinActions({ task }: Props) {
  const [, setOpinTaskDraftId] = useOpinTaskDraftId();
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task?.discriminator);
  const [createOpinTaskRequest, createOpinTaskResult] = useCreateOpinTaskMutation();
  const { isLoading: isUpdateLoading } = updateTaskResult;
  const { isLoading: isCreating, data: opinTaskDraft } = createOpinTaskResult;

  const handleClickReject = () => {
    if (task) {
      createOpinTaskRequest({
        execution_task_id: task.id,
      });
    }
  };

  const handleAccept = () => {
    if (task) {
      updateTaskRequest({
        id: task.id,
        opin_state: OpinStateEnum.ACCEPTED,
      });
    }
  };

  useEffect(() => {
    if (opinTaskDraft) {
      setOpinTaskDraftId(opinTaskDraft.id);
    }
  }, [opinTaskDraft, setOpinTaskDraftId]);

  /**
   * Кнопки доступны только в закрытых задачах,
   * для пользователей с ролью "Сотрудник ОПиН ПСПА ЗФ (Надёжник)",
   * по участку ПСПА ЗФ.
   */
  if (
    task?.state?.name !== StateNamesEnum.Closed ||
    (task?.discriminator !== TaskDiscriminatorEnum.PSPA_TASK && task?.discriminator !== TaskDiscriminatorEnum.OPIN_TASK)
  ) {
    return <div />;
  }

  return (
    <div>
      <CanIView roles={[RoleEnum.RELIABILITY_ENGINEER]}>
        <Row justify="space-between" className="mb-2" align="middle">
          <Col>
            <Space>
              <Text size="label2">Статус ОПиН:</Text>
              {!!task?.opin_state && <OpinStateTag state={task?.opin_state} />}
            </Space>
          </Col>
          <Col>
            {task?.opin_state === OpinStateEnum.NOT_VERIFIED && (
              <Space>
                <Button
                  disabled={isUpdateLoading}
                  loading={isCreating}
                  type="primary"
                  danger
                  onClick={handleClickReject}
                >
                  ОТКЛОНИТЬ
                </Button>
                <Button loading={isUpdateLoading} disabled={isCreating} type="primary" onClick={handleAccept}>
                  ПРИНЯТЬ
                </Button>
              </Space>
            )}
          </Col>
        </Row>
      </CanIView>
    </div>
  );
}
