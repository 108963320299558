import { StateNamesEnum } from "core/app/api/task/enums";
import { IDictionaryItem } from "core/shared/interfaces";

import { useGetTaskStatesQuery } from "../../../../app/api/task";

export function useStateByName(stateName: StateNamesEnum) {
  const { data: states } = useGetTaskStatesQuery("");

  return states?.results.find((item: IDictionaryItem) => item.name === stateName);
}
