import { useLocation } from "react-router-dom";
import { Dayjs } from "dayjs";

import { useShiftPlan } from "core/shared/shift/useShiftPlan";
import { AppRoutes } from "core/shared/constants.routes";
import { ShiftPlanStatusesEnum } from "core/app/api/shift/enums";
import { TaskSnapMomentEnum } from "core/app/api/task/enums";

import { useTaskId } from "../useTaskId";

/** Если переданная дата null - скипаем запрос внутри useShiftPlan и возвращаем false */
const useDisabledByShiftStatus = (shiftDate?: Dayjs | null): boolean => {
  const location = useLocation();

  const shiftPlan = useShiftPlan(shiftDate, undefined, undefined, { skip: shiftDate === null });

  const [, , moment] = useTaskId();

  if (shiftDate === null) {
    return false;
  }

  if (location.pathname === AppRoutes.pspa) {
    return false;
  }
  const checkMoment = moment === TaskSnapMomentEnum.TEAM_DISBAND;

  return !shiftPlan || shiftPlan.status !== ShiftPlanStatusesEnum.APPROVED || checkMoment;
};

export { useDisabledByShiftStatus };
