import { Col, Form, Input, InputNumber, Row, Space } from "antd";
import { ReactElement } from "react";

import { useDisabled } from "core/shared/task/hooks/useDisabled";
import { VandalActionsSwitch } from "core/widgets/TaskModals/shared/Fields/VandalActionsSwitch";
import { IMaintenanceExecution } from "core/app/api/interfaces";
import { CheckItemGroupsEnum } from "core/app/api/task/enums";

import { CheckListGroup } from "./CheckListGroup";
import Value from "./Value";

const { TextArea } = Input;

interface MaintenanceExecutionProps {
  showVandalActionsSwitch?: boolean;
  maintenanceExecution: IMaintenanceExecution;
  index: number;
}

/** Закладка на карточке технического обслуживания (ТО).
 * Соответствует виду оборудования (системе) в навесном оборудовании ПСПА. */
export default function PspaMaintenanceExecution({
  maintenanceExecution,
  showVandalActionsSwitch,
  index,
}: MaintenanceExecutionProps): ReactElement {
  const isDisabled = useDisabled();

  return (
    <Row gutter={16}>
      {showVandalActionsSwitch && (
        <Col span={24}>
          <VandalActionsSwitch
            name={["maintenance_execution", index, "is_vandalism_detected"]}
            isDisabled={isDisabled}
          />
        </Col>
      )}
      <Col span={13}>
        <Form.Item name={["maintenance_execution", index, "id"]} hidden>
          <InputNumber />
        </Form.Item>
        {/* На форме не используется, но на бэке это поле обязательное */}
        <Form.Item name={["maintenance_execution", index, "values"]} label="values" hidden>
          <InputNumber />
        </Form.Item>
        <Space direction="vertical">
          <CheckListGroup
            title="РАБОТОСПОСОБНОСТЬ"
            maintenanceExecution={maintenanceExecution}
            maintenanceExecutionIndex={index}
            group={CheckItemGroupsEnum.Performance}
          />
          <CheckListGroup
            title="РАБОТЫ"
            maintenanceExecution={maintenanceExecution}
            maintenanceExecutionIndex={index}
            group={CheckItemGroupsEnum.Work}
          />
        </Space>
      </Col>
      <Col span={11}>
        <div>
          {maintenanceExecution.values.map((valueItem, valueIndex) => (
            <Value key={valueItem.id} maintenanceExecutionIndex={index} value={valueItem} valueIndex={valueIndex} />
          ))}
        </div>
        <div>
          <Form.Item label="КОММЕНТАРИЙ" name={["maintenance_execution", index, "comment"]}>
            <TextArea rows={5} disabled={isDisabled} />
          </Form.Item>
        </div>
        <div>
          <Form.Item label="ИСПОЛЬЗУЕМЫЕ МАТЕРИАЛЫ" name={["maintenance_execution", index, "used_materials"]}>
            <TextArea rows={5} disabled={isDisabled} />
          </Form.Item>
        </div>
      </Col>
    </Row>
  );
}
