import { Row, Col, Divider, Space } from "antd";
import { ReactElement, useState } from "react";
import Collapse from "@mui/material/Collapse";
import clsx from "clsx";

import { Field } from "core/widgets/TaskModals/shared/Fields/Field";
import Text from "core/lib/Text/Text";
import { ITask } from "core/app/api/task/interfaces";
import {
  Number,
  RequestCreationDatetime,
  State,
  Location,
  EquipmentType,
  EffortField,
  AccumulatorElapsedTime,
  Unit,
} from "core/widgets/TaskModals/shared/Fields";
import { IcCollapse } from "core/widgets/IcCollapse";
import { useTask } from "core/shared/task/hooks/useTask";
import { EffortTypeEnum } from "core/models/enums";

import { MountedOrStationary } from "./MountedOrStationary";

interface GeneralInfoProps {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке АВР или ЗНО. */
export function GeneralInfo({ task }: GeneralInfoProps): ReactElement {
  const { data: parrentTask } = useTask(task.execution_task_id);
  const [isFull, setFull] = useState(false);

  return (
    <>
      <Row className="mb-1">
        <Col span={24}>
          <MountedOrStationary task={task} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          {parrentTask?.number && <Number number={parrentTask.number} requestDescription={task.request_description} />}
        </Col>
        <Col span={12}>
          <Field label="Номер поручения" value={task.number} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Field label="Описание проблемы" value={task.request_description} />
        </Col>
      </Row>
      <Collapse in={isFull}>
        <Row gutter={16} className={clsx("mb-1", "mt-2")}>
          <Col span={12}>
            <Location task={task} label="Местоположение" />
            <Unit task={task} />
            <EquipmentType task={task} />
          </Col>
          <Col span={12}>
            <State task={task} />
            <RequestCreationDatetime task={task} format="DD.MM.YYYY в HH:mm:ss" />
            <Field
              label="ФИО Инициатора"
              value={
                task.initiator_user ? `${task.initiator_user.last_name} ${task.initiator_user.first_name}`.trim() : ""
              }
            />
            <EffortField task={task} effortType={EffortTypeEnum.HOURS} />
            <AccumulatorElapsedTime task={task} />
          </Col>
        </Row>
      </Collapse>
      <Divider>
        <div
          className="link"
          onClick={() => {
            setFull(!isFull);
          }}
        >
          <Space>
            <Text className="blue" size="label3">
              {isFull ? "СВЕРНУТЬ ДОПОЛНИТЕЛЬНУЮ ИНФОРМАЦИЮ" : "РАЗВЕРНУТЬ ДОПОЛНИТЕЛЬНУЮ ИНФОРМАЦИЮ"}
            </Text>
            <IcCollapse open={!isFull} reverse />
          </Space>
        </div>
      </Divider>
    </>
  );
}
