import { Tag } from "antd";

import { OossaSystemTypeEnum } from "core/app/api/units/OOSSA/enums";
import { OOSSA_SYSTEMS_TAG_COLORS } from "core/shared/oossa";

interface IObjectNameProps {
  object: {
    object_name: string;
    system_name: string;
    code_enum: OossaSystemTypeEnum;
  };
}

function ObjectName({ object }: IObjectNameProps) {
  return (
    <>
      {object.object_name} <br />
      <Tag color={OOSSA_SYSTEMS_TAG_COLORS[object.code_enum]}>{object.system_name}</Tag>
    </>
  );
}

export { ObjectName };
