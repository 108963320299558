import { ReactElement } from "react";
import TextArea from "antd/lib/input/TextArea";
import { Col, Row, Form } from "antd";

import {
  IMaintenanceExecutionCheckListItem,
  IMaintenanceExecutionValuesItem,
  IMaintenanceExecutionWork,
} from "core/app/api/interfaces";
import { CheckListItem } from "core/widgets/TaskModals/shared/CheckListItem";

import OossaMaintenanceExecutionValue from "./OossaMteValue";
import OossaMaintenanceTaskExecutionGroupWork from "./OossaMteGroupWork";

interface OossaMaintenanceTaskExecutionWorkProps {
  workItem: IMaintenanceExecutionWork;
  showGroupName: boolean;
  disabled: boolean;
}

export default function OossaMaintenanceTaskExecutionWork({
  workItem,
  showGroupName,
  disabled: isDisabled,
}: OossaMaintenanceTaskExecutionWorkProps): ReactElement {
  const checkItem = workItem as IMaintenanceExecutionCheckListItem;
  const valueItem = workItem as IMaintenanceExecutionValuesItem;

  return (
    <>
      {/* Элемент чек-листа */}
      {showGroupName && <OossaMaintenanceTaskExecutionGroupWork workItem={workItem} />}
      {checkItem.check && (
        <Form.Item name={workItem.form_name}>
          <CheckListItem show disabled={isDisabled} />
        </Form.Item>
      )}
      {/* Элемент измеренного значения */}
      {valueItem.field && (
        <Form.Item name={workItem.form_name}>
          <OossaMaintenanceExecutionValue disabled={isDisabled} />
        </Form.Item>
      )}
      {/* Комментарий к работе */}
      <Row>
        <Col span={22}>
          <div className="pl-1">
            <Form.Item name={[...workItem.form_name, "comment"]}>
              <TextArea rows={2} disabled={isDisabled} />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </>
  );
}
