import { useLocation } from "react-router-dom";

import { AppRoutes } from "core/shared/constants.routes";
import { useShiftPlan } from "core/shared/shift/useShiftPlan";
import { useNetworkState } from "core/shared/main/hooks/useNetworkState";
import { ShiftPlanStatusesEnum } from "core/app/api/shift/enums";

export function useShowMenuButtonTaskSidebar() {
  const location = useLocation();
  const shiftPlan = useShiftPlan();
  const { isOnline } = useNetworkState();

  if (!isOnline) {
    return false;
  }
  if (location.pathname === AppRoutes.tasksForTeams && shiftPlan?.status !== ShiftPlanStatusesEnum.CLOSED) {
    return true;
  }
  if (location.pathname === AppRoutes.taskForShift && shiftPlan?.status === ShiftPlanStatusesEnum.APPROVED) {
    return true;
  }
  return false;
}
