import qs from "qs";

import { apiSlice } from "core/app/api";
import { IEtoTask } from "core/app/api/task/eto/interfaces";
import {
  IAttachment,
  ICreateTaskSignaturePayload,
  ICustomerRepresentative,
  ICustomerRepresentativePayload,
  IGetTaskQueryPayload,
  IGetTaskSignatureFileProps,
  IItsmCategory,
  IItsmCategoryGroup,
  INoPhotoReason,
  IPlanTasksNotificationBody,
  IPosition,
  ISendCreatioTaskBody,
  ITask,
  ITaskDocument,
  ITaskDocumentQueryPayload,
  ITaskSignature,
  IUpdateTaskCheckListPayload,
  IUpdateTaskStatePayload,
  IUserSettingsTasksNotifications,
} from "core/app/api/task/interfaces";
import { IRecoveryTasksNotification, ISkipRecoveryTasksNotificationBody } from "core/app/api/task/recovery/interfaces";
import {
  getFileNameFromContentDisposition,
  handleUpdateTaskQueryStarted,
  TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE,
} from "core/app/api/tools";
import { extractResults } from "core/app/api/emptyApi";

import { IDictionaryItem, IResponseBody } from "../../../shared/interfaces";

const taskApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      "task-states",
      "task-attachments",
      "task-signature",
      "task-signature/file",
      "itsm-category-groups",
      "itsm-request-categories",
      "itsm-stpp-equipment-type",
      "itsm-equipment",
      "no-photo-reasons",
      "document-packs",
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTask: builder.query<ITask, string | number | IGetTaskQueryPayload>({
        query: (payload) => {
          if (typeof payload === "string" || typeof payload === "number") {
            return `tasks/${payload}/`;
          }
          return `${payload.url}/${payload.taskId}/${payload?.query ?? ""}`;
        },
        providesTags: (result, error, payload) => {
          if (typeof payload === "string" || typeof payload === "number") {
            return [{ type: "task", id: payload }];
          }
          return [{ type: "task", id: payload.taskId }];
        },
      }),
      getEtoTask: builder.query<IEtoTask, string | number>({
        query: (taskId) => `eto-tasks/${taskId}/`,
        providesTags: (result, error, taskId) => [{ type: "task", id: taskId }],
      }),
      createTask: builder.mutation({
        query: (payload) => ({
          url: `tasks/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["tasks", "open-tasks", "kanban-board", "tasks-filtered", "shifttask"],
      }),
      updateEtoTask: builder.mutation({
        query: ({ rtkQueryCacheKey, ...task }) => ({
          url: `eto-tasks/${task.id}/`,
          method: "PATCH",
          body: task,
        }),
        async onQueryStarted(args, queryContext) {
          await handleUpdateTaskQueryStarted(args, queryContext);
        },
        invalidatesTags: (result, error, arg) => [
          { type: "task", id: arg.id },
          ...TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE,
        ],
      }),
      updateTaskCheckList: builder.mutation({
        query: (payload: IUpdateTaskCheckListPayload) => ({
          url: `tasks/${payload.taskId}/maintenance-execution/${payload.maintenanceExecution}/checklist/${payload.checklist}/`,
          method: "PATCH",
          body: {
            value: payload.value,
          },
        }),
        invalidatesTags: ["task"],
      }),
      getTaskStates: builder.query<IResponseBody<IDictionaryItem>, string>({
        query: () => `states/`,
        providesTags: ["task-states"],
      }),
      updateTaskState: builder.mutation({
        query: (payload: IUpdateTaskStatePayload) => ({
          url: `tasks/${payload.taskId}/`,
          method: "PATCH",
          body: {
            state_id: payload.stateId,
          },
        }),
        invalidatesTags: ["task", "kanban-board"],
      }),
      importTasks: builder.mutation({
        query: (payload) => ({
          url: `tasks/import-tasks/`,
          method: "POST",
          body: payload,
          cache: "no-cache",
        }),
        invalidatesTags: ["tasks", "tasks-filtered"],
      }),
      getCreatioStates: builder.query({
        query: () => `tasks/request-statuses/`,
        providesTags: ["creatio-states"],
      }),
      createRecoveryTasks: builder.mutation({
        query: (taskId: number) => ({
          url: `tasks/${taskId}/create-recovery-tasks/`,
          method: "GET",
        }),
        invalidatesTags: ["month-plan/recovery-tasks-layer", "tasks"],
      }),
      needCreateRecoveryTasks: builder.query<{ need_create: boolean }, number | null>({
        query: (taskId: number) => ({
          url: `tasks/${taskId}/need-create-recovery-tasks/`,
        }),
        providesTags: ["need-create-recovery-tasks"],
      }),
      getTaskAttachments: builder.query<IAttachment[], number | IGetTaskQueryPayload>({
        query: (payload) => {
          if (typeof payload === "number") {
            return `tasks/${payload}/attachments/`;
          }
          return `${payload.url}/${payload.taskId}/attachments`;
        },
        providesTags: ["task-attachments"],
      }),
      uploadAttachment: builder.mutation({
        query: (payload) => ({
          url: `tasks/${payload.taskId}/attachments/`,
          method: "POST",
          body: payload.body,
        }),
        invalidatesTags: ["task-attachments", "task-attachment"],
      }),
      deleteAttachment: builder.mutation({
        query: (payload) => ({
          url: `tasks/${payload.taskId}/attachments/${payload.attachmentId}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["task-attachments", "task-attachment"],
      }),
      setAttachmentShouldBeSent: builder.mutation({
        query: (payload) => ({
          url: `tasks/${payload.taskId}/attachments/${payload.attachmentId}/set_should_be_sent/`,
          method: "POST",
          body: {
            should_be_sent: payload.shouldBeSent,
          },
        }),
        invalidatesTags: ["task-attachments", "task-attachment"],
      }),
      uploadTaskSignature: builder.mutation<any, ICreateTaskSignaturePayload>({
        query({ taskId, body, side }) {
          return {
            url: `/tasks/${taskId}/task-signature/${side}/`,
            method: "PATCH",
            body,
          };
        },
        invalidatesTags: ["task-signature", "task-signature/file"],
      }),
      getTaskSignature: builder.query<ITaskSignature, number>({
        query: (taskId) => `tasks/${taskId}/task-signature/`,
        providesTags: ["task-signature"],
      }),
      getCustomerRepresentatives: builder.query<ICustomerRepresentative[], string>({
        query: (queryString) => `customer-representatives/?${queryString}`,
        providesTags: ["customer-representatives"],
        transformResponse: extractResults,
      }),
      getPositions: builder.query<IPosition[], string>({
        query: (queryString) => `positions/?${queryString}`,
        providesTags: ["positions"],
      }),
      createCustomerRepresentative: builder.mutation<ICustomerRepresentative, ICustomerRepresentativePayload>({
        query(payload) {
          return {
            url: `customer-representatives/`,
            method: "POST",
            body: {
              unit: payload.unit,
              first_name: payload.firstName,
              last_name: payload.lastName,
              middle_name: payload.middleName,
              position: payload.position,
            },
          };
        },
        invalidatesTags: ["customer-representatives"],
      }),
      getTaskSignatureFile: builder.query<string, IGetTaskSignatureFileProps>({
        query(props) {
          return {
            url: `/tasks/${props.taskId}/task-signature/${props.side}/file/`,
            method: "GET",
            responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
          };
        },
        providesTags: ["task-signature/file"],
      }),
      createCustomerPosition: builder.mutation<IPosition, Omit<IPosition, "id">>({
        query(payload) {
          return {
            url: `positions/`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["positions"],
      }),
      getTaskAttachmentsDownload: builder.query<string, number>({
        query(taskId) {
          return {
            url: `tasks/${taskId}/attachments/download/`,
            responseHandler: async (response) => {
              if (response.ok) {
                const fileLink = document.createElement("a");
                fileLink.href = window.URL.createObjectURL(await response.blob());
                fileLink.target = "blank";
                const disposition = await response.headers.get("content-disposition");
                const fileName = getFileNameFromContentDisposition(disposition);
                fileLink.download = fileName ?? `tasks_${taskId}_attachments`;
                fileLink.click();
              }
            },
          };
        },
      }),
      getRecoveryTasksNotifications: builder.query<IResponseBody<IRecoveryTasksNotification>, string>({
        query() {
          return {
            url: `notifications/tasks/`,
          };
        },
        providesTags: ["notifications/tasks"],
        keepUnusedDataFor: 5,
      }),
      skipRecoveryTasksNotification: builder.mutation<
        ISkipRecoveryTasksNotificationBody,
        ISkipRecoveryTasksNotificationBody
      >({
        query(body) {
          return {
            url: `/notifications/tasks/skip/`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["notifications/tasks"],
      }),
      planTaskNotification: builder.mutation<IPlanTasksNotificationBody, IPlanTasksNotificationBody>({
        query(body) {
          return {
            url: `/notifications/tasks/plan/`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["notifications/tasks"],
      }),
      getUserSettingsTasksNotifications: builder.query<IUserSettingsTasksNotifications, string>({
        query() {
          return {
            url: `user-settings/task-notifications/`,
          };
        },
        providesTags: ["user-settings/task-notifications"],
      }),
      getItsmCategoryGroups: builder.query<IItsmCategoryGroup[], string>({
        query: (queryString) => `/itsm-category-group/?${queryString}`,
        providesTags: ["itsm-category-groups"],
        transformResponse: extractResults,
      }),
      getItsmCategories: builder.query<IItsmCategory[], string>({
        query: (queryString) => `/itsm-request-category/?${queryString}`,
        providesTags: ["itsm-request-categories"],
        transformResponse: extractResults,
      }),
      getItsmBaseEquipmentType: builder.query<IDictionaryItem[], string>({
        query: (queryString) => `/itsm-equipment/?${queryString}`,
        providesTags: ["itsm-equipment"],
        transformResponse: extractResults,
      }),
      sendCreatioTask: builder.mutation<void, ISendCreatioTaskBody>({
        query(body) {
          return {
            url: `/ksip-adapter/new-task-requests/`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: [
          "tasks",
          "shifttask",
          "tasks-filtered",
          "kanban-board",
          "month-plan",
          "tasks/statistics-by-days",
          "dashboard/shift-statistic/tasks",
        ],
      }),
      getNoPhotoReasons: builder.query<INoPhotoReason[], string>({
        query() {
          return `no-photo-reasons/`;
        },
        providesTags: ["no-photo-reasons"],
        transformResponse: extractResults,
      }),
      resetTask: builder.query({
        query: (taskId: number | null) => `tasks/${taskId}/reset/`,
      }),
      getTaskDocuments: builder.query<ITaskDocument[], ITaskDocumentQueryPayload>({
        query: (params) => {
          const queryString = qs.stringify(params);
          return `/document-packs/?${queryString}`;
        },
        providesTags: ["document-packs"],
        transformResponse: extractResults,
      }),
    }),
  });

export const {
  useGetTaskQuery,
  useLazyGetTaskQuery,
  useUpdateTaskCheckListMutation,
  useGetTaskStatesQuery,
  useUpdateTaskStateMutation,
  useImportTasksMutation,
  useGetCreatioStatesQuery,
  useCreateTaskMutation,
  useCreateRecoveryTasksMutation,
  useNeedCreateRecoveryTasksQuery,
  useUploadAttachmentMutation,
  useDeleteAttachmentMutation,
  useSetAttachmentShouldBeSentMutation,
  useGetTaskAttachmentsQuery,
  useUploadTaskSignatureMutation,
  useGetTaskSignatureQuery,
  useGetCustomerRepresentativesQuery,
  useCreateCustomerRepresentativeMutation,
  useGetPositionsQuery,
  useGetTaskSignatureFileQuery,
  useCreateCustomerPositionMutation,
  useLazyGetTaskAttachmentsDownloadQuery,
  useGetRecoveryTasksNotificationsQuery,
  useSkipRecoveryTasksNotificationMutation,
  usePlanTaskNotificationMutation,
  useGetUserSettingsTasksNotificationsQuery,
  useGetItsmCategoryGroupsQuery,
  useGetItsmCategoriesQuery,
  useGetItsmBaseEquipmentTypeQuery,
  useSendCreatioTaskMutation,
  useUpdateEtoTaskMutation,
  useGetEtoTaskQuery,
  useGetNoPhotoReasonsQuery,
  useResetTaskQuery,
  useGetTaskDocumentsQuery,
} = taskApiSlice;
