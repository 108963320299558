import React, { ReactElement } from "react";
import { Row, Col } from "antd";

import Text from "core/lib/Text/Text";
import { useGetEquipmentMaintenanceQuery } from "core/app/api/units/PSPA";
import dayjs, { displayDateFormat } from "core/shared/tools/dayjs";

interface EquipmentProps {
  equipmentId: number;
}

function MaintenanceSchedule({ equipmentId }: EquipmentProps): ReactElement {
  const { data } = useGetEquipmentMaintenanceQuery({ id: equipmentId });

  return (
    <>
      <Row className="mb-1">
        <Col>
          <Text size="label2" className="uppercase" boldness="black">
            {data?.maintenance_quantity === 12 && "Обслуживается каждый месяц"}
            {data?.maintenance_quantity !== 12 && `Обслуживается ${data?.maintenance_quantity} раза в год`}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text size="label2">Прогноз следующего ТО:&nbsp;</Text>
        </Col>
        {data?.next_maintenance && (
          <Col>
            <Text size="label2" boldness="black">
              {dayjs(data.next_maintenance.execution_date).format(displayDateFormat)} /{" "}
            </Text>
            <Text size="label2" boldness="black" className="blue">
              {data.next_maintenance.task_number}
            </Text>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Text size="label2">Факт предыдущего ТО:&nbsp;</Text>
        </Col>
        {data?.last_maintenance && (
          <Col>
            <Text size="label2" boldness="black">
              {dayjs(data.last_maintenance.execution_date).format(displayDateFormat)} /{" "}
            </Text>
            <Text size="label2" boldness="black" className="blue">
              {data.last_maintenance.task_number}
            </Text>
          </Col>
        )}
      </Row>
    </>
  );
}

export default MaintenanceSchedule;
