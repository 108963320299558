import { ITask } from "core/app/api/task/interfaces";

import { Field } from "../Field";

type TLsoMaintenanceTypeProps = {
  task: ITask;
};

export function LsoMaintenanceType({ task }: TLsoMaintenanceTypeProps) {
  return <Field label="Тип ТО" value={task.lso_maintenance_name} />;
}
