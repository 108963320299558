import { intersection } from "lodash";

import { RoleEnum } from "core/shared/enums";

import { useUserRoles } from "../useUserRoles";

export function useHasUserOneOfSelectedRoles(roles: RoleEnum[]) {
  const userRoles = useUserRoles();

  return intersection(userRoles, roles).length !== 0;
}
