import { ReactElement } from "react";
import { Select, Form } from "antd";

import { IDictionaryItem } from "core/shared/interfaces";
import { SlpiWorkTypeGroupEnum } from "core/app/api/units/SLPI/interfaces";
import { useCustomerDepartments } from "core/shared/main/hooks/useCustomerDepartments";
import { useWorkType } from "core/shared/main/hooks/useWorkType";
import { useApiSlice } from "core/shared/main/hooks/useApiSlice";
import { ApiSliceEnum } from "core/app/api/enums";
import { LsoWorkTypeGroupEnum } from "core/app/api/units/LSO/enums";

export function Department(): ReactElement {
  const form = Form.useFormInstance();
  const customerId = Form.useWatch("customerId");
  const workTypeId = Form.useWatch("workTypeId");
  const apiSlice = useApiSlice();
  const workType = useWorkType(workTypeId, apiSlice);
  const objectIsMissing = Form.useWatch("objectIsMissing");

  const queryParams: Record<string, string | number> = {};

  if (customerId) {
    queryParams.customer_id = customerId;
  }

  const { data, isFetching } = useCustomerDepartments(queryParams);

  const options = (data || []).map((item: IDictionaryItem) => ({
    label: item.name,
    value: item.id,
  }));

  const handleChange = (val: number) => {
    form.setFieldValue("systemComplexId", null);
    form.setFieldValue("objectId", null);
    const department = data?.find((item) => item.id === val);
    if (department?.customer_id) {
      form.setFieldValue("customerId", department.customer_id);
    }
  };

  const getIsRequired = () => {
    if (apiSlice === ApiSliceEnum.slpi) {
      return (
        workType &&
        "group" in workType &&
        (workType?.group === SlpiWorkTypeGroupEnum.WORK ||
          (workType?.group === SlpiWorkTypeGroupEnum.RESEARCH && !objectIsMissing))
      );
    }
    if (apiSlice === ApiSliceEnum.lso) {
      return (
        workType &&
        "group" in workType &&
        (workType?.group === LsoWorkTypeGroupEnum.WORK ||
          (workType?.group === LsoWorkTypeGroupEnum.RESEARCH && !objectIsMissing))
      );
    }
    return false;
  };
  const required = getIsRequired();

  return (
    <Form.Item
      label="ПОДРАЗДЕЛЕНИЕ"
      name="departmentId"
      colon={false}
      required={required}
      rules={[
        {
          required,
          message: "",
        },
      ]}
    >
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleChange}
      />
    </Form.Item>
  );
}
