import React from "react";
import {
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  VideoCameraOutlined,
  MailOutlined,
} from "@ant-design/icons";

const iconsMap = {
  image: <FileImageOutlined />,
  video: <VideoCameraOutlined />,
  pdf: <FilePdfOutlined />,
  docx: <FileWordOutlined />,
  doc: <FileWordOutlined />,
  xlsx: <FileExcelOutlined />,
  xls: <FileExcelOutlined />,
  msg: <MailOutlined />,
  txt: <FileTextOutlined />,
};
interface FileTypeIconProps {
  originalName: string;
  mimeType: string;
  size?: number;
  iconType?: keyof typeof iconsMap;
}

function FileTypeIcon({ originalName, mimeType, size = 22, iconType }: FileTypeIconProps) {
  let Icon = iconsMap.txt;
  const typeIcon = iconType ?? originalName.split(".").pop();

  if (mimeType.includes("image")) {
    Icon = iconsMap.image;
  } else if (mimeType.includes("video")) {
    return iconsMap.video;
  } else if (typeIcon && typeIcon in iconsMap) {
    Icon = iconsMap[typeIcon as keyof typeof iconsMap];
  }

  return React.cloneElement(Icon, { style: { fontSize: size } });
}

export default FileTypeIcon;
