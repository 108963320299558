import { Button, Col, Divider, Form, InputNumber, Row } from "antd";
import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";
import { useDisabled } from "core/shared/task/hooks/useDisabled";
import { useSignatureStatus } from "core/shared/task/hooks/useSignatureStatus";
import { useTask } from "core/shared/task/hooks/useTask";
import { useUpdateTask } from "core/shared/task/hooks/useUpdateTask";
import { ZabbixAlert } from "core/widgets/Equipment/ZabbixAlert";
import { OpinActions } from "core/widgets/TaskModals/shared/OpinActions";

import { MediaOrComment } from "../Recovery/MediaOrComment";
import RecoveryAndOtherTaskStateButtons from "../Recovery/StateButtons";
import { SignatureForm } from "../shared/SignatureForm";
import Title from "../shared/TaskTitle";
import DocumentPacks from "../shared/Media/Category/DocumentPacks";

import Body from "./Body";
import OtherGeneralInfo from "./GeneralInfo";

import "./styles.scss";

/** Задание на доп. работы ("прочие"). */
export default function OtherTaskModal(): ReactElement {
  const { data: task } = useTask();
  const [form] = Form.useForm();
  const [signatureStatus] = useSignatureStatus();
  const isDisabled = useDisabled({ skipStatusCheck: true });
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task?.discriminator);

  const handleFinish = (values: Partial<ITask>) => {
    updateTaskRequest(values);
  };

  return (
    <div className="task-modal recovery-task-modal">
      {task && (
        <Form onFinish={handleFinish} form={form} name="TaskCard" layout="vertical" initialValues={task}>
          <Form.Item name="id" label="id" hidden>
            <InputNumber />
          </Form.Item>
          <Title icon task={task} />
          <OpinActions task={task} />
          {!!signatureStatus && <SignatureForm task={task} />}
          {!signatureStatus && (
            <>
              <RecoveryAndOtherTaskStateButtons task={task} form={form} />
              <ZabbixAlert equipmentId={task.equipment?.id} />
              <OtherGeneralInfo task={task} />
              <Divider />
              <Body task={task} form={form} />
              <DocumentPacks task={task} />
              <MediaOrComment />
              <Divider />
              <Row>
                <Col span={24}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="w-100"
                    loading={updateTaskResult.isLoading}
                    disabled={isDisabled}
                  >
                    Сохранить
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>
      )}
    </div>
  );
}
