import { ReactElement } from "react";
import _ from "lodash";

import { RoleEnum } from "core/shared/enums";
import { UnitServiceCodesEnum } from "core/shared/unit/types";
import { useUserServiceCodes } from "core/shared/unit/useUserServices";
import { useUserRoles } from "core/shared/main/hooks/useUserRoles";

/**
 * Создаёт список ключей доступа, из списков ролей и услуг
 */
const getCombination = (roles: RoleEnum[] | undefined, services: UnitServiceCodesEnum[] | undefined) => {
  const result: string[] = [];
  roles?.forEach((role) => {
    services?.forEach((service) => {
      result.push(`${role}.${service}`);
    });
  });
  return result;
};

/**
 * Создаёт ключ доступа из пары роль и услуга
 */
const getKey = (pair: [RoleEnum, UnitServiceCodesEnum]) => `${pair[0]}.${pair[1]}`;

export interface ICanIViewProps {
  /**
   * Список ролей, которые должны быть доступны для просмотра
   */
  roles?: RoleEnum[];
  /**
   * Список ролей которые надо исключить из переданного списка ролей
   */
  withoutRoles?: RoleEnum[];
  /**
   * Список сервисов(услуг)
   */
  serviceCodes?: UnitServiceCodesEnum[];
  /**
   * Список ключей доступа, которые необходимо исключить
   */
  not?: [RoleEnum, UnitServiceCodesEnum][];
  /**
   * Список ключей доступа, которые необходимо добавить
   */
  and?: [RoleEnum, UnitServiceCodesEnum][];
  /**
   * Дочерний элемент
   */
  children: ReactElement;
}

export function CanIView({ roles, children, serviceCodes, withoutRoles, not, and }: ICanIViewProps): ReactElement {
  const userRoles = useUserRoles();
  const userServiceCodes = useUserServiceCodes();
  let localRoles: RoleEnum[] = [];
  let localServiceCodes: UnitServiceCodesEnum[] = [];

  /**
   * Если роли не переданы, используем все роли по умолчанию
   */
  if (!roles) {
    localRoles = Object.values(RoleEnum);
  } else {
    localRoles = [...roles];
  }

  /**
   * Исключаем роли
   */
  if (withoutRoles) {
    localRoles = localRoles.filter((role) => !withoutRoles.includes(role));
  }

  /**
   * Если услуги не переданы, используем все услуги по умолчанию
   */
  if (!serviceCodes) {
    localServiceCodes = Object.values(UnitServiceCodesEnum);
  } else {
    localServiceCodes = [...serviceCodes];
  }

  /**
   * Создаём массив ключей доступа из комбинаций ролей и услуг, которым разрешён доступ
   */
  let allowedAccessKeys = getCombination(localRoles, localServiceCodes);

  /**
   * Исключаем ключи
   */
  if (not) {
    allowedAccessKeys = allowedAccessKeys.filter((item) => !not.map(getKey).includes(item));
  }

  /**
   * Добавляем ключи
   */
  if (and) {
    allowedAccessKeys = allowedAccessKeys.concat(and.map(getKey));
  }

  /**
   * Создаём массив ключей доступа из комбинаций ролей и услуг, которыми обладает пользователь
   */
  const userAccessKeys = getCombination(userRoles, userServiceCodes);

  /**
   * Проверяем, есть ли хотя бы один общий ключ доступа
   */
  const isAccessAllowed = _.intersection(allowedAccessKeys, userAccessKeys).length !== 0;

  if (isAccessAllowed) {
    return children;
  }

  return <div />;
}
