import { useLocation } from "react-router-dom";
import { Space, Switch } from "antd";

import { AppRoutes } from "core/shared/constants.routes";
import { setIsOnline } from "core/app/store/network-slice";
import { useNetworkState } from "core/shared/main/hooks/useNetworkState";
import { useAppDispatch } from "core/app/store";

export function OfflineSelector() {
  const location = useLocation();
  const { isOnline } = useNetworkState();
  const dispatch = useAppDispatch();

  const handleChangeNetworkState = (checked: boolean) => {
    dispatch(setIsOnline(checked));
  };

  if (location.pathname === AppRoutes.taskForShift) {
    return (
      <Space direction="horizontal" size={16}>
        <Switch
          checked={isOnline}
          checkedChildren="ОНЛАЙН"
          unCheckedChildren="ОФФЛАЙН"
          style={{ width: "100px" }}
          onChange={handleChangeNetworkState}
        />
      </Space>
    );
  }
  return null;
}
