import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";
import dayjs, { displayDateFormat } from "core/shared/tools/dayjs";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function PlannedDate({ task }: FieldProps): ReactElement {
  const value = `${dayjs(task.planned_date).format(displayDateFormat)} г.`;
  return <Field label="Плановая дата выполнения" value={value} />;
}

export { PlannedDate };
