import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function VolsLineNumber({ task }: FieldProps): ReactElement {
  return <Field label="Инвентарный номер линии" value={task.line?.inventory_number} />;
}

export { VolsLineNumber };
