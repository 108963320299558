import { ReactElement, useContext } from "react";
import { Progress, Row, Col, ProgressProps } from "antd";

import { safePercent } from "core/shared/tools/safePercent";
import Text from "core/lib/Text/Text";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { IDashboardShiftStatisticTasksByType } from "core/shared/dashboard/hooks/interfaces";

interface IProgressBarProps extends ProgressProps {
  data?: IDashboardShiftStatisticTasksByType;
}

function ProgressBar({ data, ...rest }: IProgressBarProps): ReactElement {
  const { showOutOfPlan } = useContext(ShiftContext);

  let sheduledTasks = 0;
  let closedSheduledTasks = 0;
  let unsheduledTasks = 0;
  let closedUnsheduledTasks = 0;
  if (data) {
    sheduledTasks = data.sheduled_tasks;
    closedSheduledTasks = data.closed_sheduled_tasks;
    unsheduledTasks = data.unsheduled_tasks;
    closedUnsheduledTasks = data.closed_unsheduled_tasks;
  }
  const percent = safePercent(closedSheduledTasks, sheduledTasks);
  const percentUnsheduled = safePercent(closedUnsheduledTasks, unsheduledTasks);
  return (
    <>
      <Row gutter={4} justify="space-between">
        <Col span={18}>
          <div style={{ width: `${percent}%`, textAlign: "end", lineHeight: 1 }}>
            <Text size="label3" boldness="semibold">
              {closedSheduledTasks}
            </Text>
          </div>
          <Progress percent={percent} showInfo={false} {...rest} />
        </Col>
        <Col span={6} style={{ alignSelf: "self-end", lineHeight: 1 }}>
          <Text size="label3" boldness="semibold">
            ({sheduledTasks} шт.)
          </Text>
        </Col>
      </Row>
      {showOutOfPlan && (
        <Row gutter={4} justify="space-between">
          <Col span={18}>
            <Progress percent={percentUnsheduled} showInfo={false} {...rest} strokeColor="#dea4fa" />
            <div style={{ width: `${percentUnsheduled}%`, textAlign: "end", lineHeight: 1 }}>
              <Text size="label3" boldness="semibold">
                {closedUnsheduledTasks}
              </Text>
            </div>
          </Col>
          <Col span={6} style={{ alignSelf: "flex-start", lineHeight: 1 }}>
            <Text size="label3" boldness="semibold">
              ({unsheduledTasks} шт.)
            </Text>
          </Col>
        </Row>
      )}
    </>
  );
}

export default ProgressBar;
