import { Form, Input } from "antd";

import { StateNamesEnum } from "core/app/api/task/enums";

const { TextArea } = Input;

interface Props {
  disabled?: boolean;
  required?: boolean;
}
export function Comment({ disabled, required }: Props) {
  const formInstance = Form.useFormInstance();
  return (
    <Form.Item
      label="КОММЕНТАРИЙ"
      name="comment"
      required={required}
      rules={[
        {
          validator: (_, value: string) => {
            const newState = formInstance.getFieldValue("newState");
            if (!value.trim() && newState === StateNamesEnum.OnPause) {
              return Promise.reject(new Error("Пожалуйста, введите КОММЕНТАРИЙ"));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <TextArea rows={5} disabled={disabled} />
    </Form.Item>
  );
}
