import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { useLazyGetTaskAttachmentsDownloadQuery, useGetTaskAttachmentsQuery } from "core/app/api/task";

interface SaveAllBtnProps {
  taskId: number;
}

function SaveAllBtn({ taskId }: SaveAllBtnProps) {
  const [request, { isLoading }] = useLazyGetTaskAttachmentsDownloadQuery();
  const { data: attachments } = useGetTaskAttachmentsQuery(taskId);

  const handleClick = () => {
    request(taskId);
  };

  if (!attachments || !attachments.length) {
    return null;
  }

  return (
    <Button loading={isLoading} onClick={handleClick} icon={<DownloadOutlined />} size="small">
      СКАЧАТЬ
    </Button>
  );
}

export { SaveAllBtn };
