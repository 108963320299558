import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";

import { Field } from "../Field";

interface TVolsPlannedVolumeProps {
  task: ITask;
}

function VolsPlannedVolume({ task }: TVolsPlannedVolumeProps): ReactElement {
  return <Field label="Плановое количество м" value={task.planned_volume?.toString()} />;
}

export { VolsPlannedVolume };
