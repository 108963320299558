import { useEffect } from "react";
import { Form, Select } from "antd";
import qs from "qs";

import { useGetSlpiMonthPlansAndEffortQuery } from "core/app/api/units/SLPI";

export function SlpiMaintenanceType() {
  const systemComplexId = Form.useWatch("systemComplexId");
  const form = Form.useFormInstance();
  const queryProps = {
    system_complex_id: systemComplexId,
  };
  const { data } = useGetSlpiMonthPlansAndEffortQuery(qs.stringify(queryProps));

  useEffect(() => {
    if (data) {
      form.setFieldValue("effort", data.effort);
      form.setFieldValue("monthPlanName", data.month_plan_names[0]?.name);
    }
  }, [data, form]);

  const options =
    data?.month_plan_names.map((item) => ({
      value: item.name,
      label: item.name,
    })) || [];

  return (
    <Form.Item label="ВИД ТО" name="monthPlanName" rules={[{ required: true, message: "" }]}>
      <Select size="large" allowClear loading={false} options={options} />
    </Form.Item>
  );
}
