import { ReactElement, useEffect } from "react";

import { useMonthPlanFindOrCreateTaskMutation } from "core/app/api";
import { useAppDispatch, useAppSelector } from "core/app/store";
import { setFindOrCreate } from "core/app/store/find-or-create-slice";
import { useTask } from "core/shared/task/hooks/useTask";
import { useTaskId } from "core/shared/task/hooks/useTaskId";
import { useUnitId } from "core/shared/unit/useUnitId";

import "../styles.scss";
import PspaContent from "./Content";
import "./styles.scss";
import WarningModal from "./WarningModal";

/** Обертка:
 * либо карточка технического обслуживания для навесного оборудования ПСПА,
 * либо предупреждающее сообщение про удаление карточки. */
export default function PspaMountedTaskForm(): ReactElement {
  const task = useTask();

  return (
    <>
      {!!task && <PspaContent />}
      <WarningModal />
    </>
  );
}

export function FindOrCreateWrapper(): ReactElement {
  const { findOrCreate } = useAppSelector((state) => state.findOrCreate);
  const [unitId] = useUnitId();
  const dispatch = useAppDispatch();
  const [findOrCreateRequest, findOrCreateResult] = useMonthPlanFindOrCreateTaskMutation();
  const [, setTaskId] = useTaskId();

  useEffect(() => {
    if (findOrCreate) {
      findOrCreateRequest({
        unit_id: unitId,
        transport_id: findOrCreate.transportId,
        completed_date: findOrCreate.date,
        month_plan_id: findOrCreate.monthPlanId,
      });
    }
  }, [findOrCreateRequest, findOrCreate, unitId]);

  useEffect(() => {
    if (findOrCreateResult.data?.task_id) {
      setTaskId(findOrCreateResult.data.task_id);
      dispatch(setFindOrCreate(null));
    }
  }, [dispatch, findOrCreateResult, setTaskId]);

  return <div />;
}
