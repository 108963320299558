import { createSlice } from "@reduxjs/toolkit";
import { maxBy } from "lodash";

import { ITeam } from "core/app/api/team/interfaces";

interface ITeamState {
  teams: ITeam[];
}

const initialState: ITeamState = {
  teams: [],
};

export const TeamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    addTeam(state, action) {
      const { payload }: { payload: ITeam } = action;
      if (!payload.id) {
        const maxId = maxBy(state.teams, "id");
        payload.id = maxId && maxId.id ? maxId.id + 1 : 1;
      }
      state.teams.push(action.payload);
    },
    updateTeam(state, action) {
      const { payload }: { payload: ITeam } = action;
      const i = state.teams.findIndex((t) => t.id === payload.id);
      state.teams[i] = payload;
    },
  },
});

export const { addTeam, updateTeam } = TeamSlice.actions;
