import { Form, Input } from "antd";

import { EtoTaskStepNameEnum } from "core/app/api/task/eto/enums";
import Text from "core/lib/Text/Text";
import { useFieldsDisabled } from "core/widgets/AssistanceCarTaskModal/hooks/useDisabled";
import { useStepFieldsDisabled } from "core/widgets/AssistanceCarTaskModal/hooks/useStepDisabled";

export function Comments() {
  const taskFieldsDisabled = useFieldsDisabled();
  const stepFieldsDisabled = useStepFieldsDisabled(EtoTaskStepNameEnum.PERFORMING_ETO);
  const disabled = taskFieldsDisabled || stepFieldsDisabled;

  return (
    <>
      <Form.Item
        name="comment"
        label={
          <Text size="label4" transform="uppercase">
            Дополнительный комментарий (на что нужно обратить внимание)
          </Text>
        }
      >
        <Input.TextArea disabled={disabled} rows={4} />
      </Form.Item>
      <Form.Item
        name="note"
        label={
          <Text size="label4" transform="uppercase">
            Комментарий для внутреннего пользования
          </Text>
        }
      >
        <Input.TextArea disabled={disabled} rows={4} />
      </Form.Item>
    </>
  );
}
