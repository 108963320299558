import { Form, Select, SpaceProps } from "antd";

import { IRepairAreaFilterProps, useUnitRepairAreas } from "core/shared/main/hooks/useUnitRepairAreas";

type TRepairAreaSelectProps = {
  value?: number | null;
  name?: string;
  filters?: IRepairAreaFilterProps;
  showAllOption?: boolean;
  direction?: SpaceProps["direction"];
  onChange?: (value: number | null) => void;
};

const { Option } = Select;

export function RepairAreaSelect({ filters, showAllOption, direction, value, name, onChange }: TRepairAreaSelectProps) {
  const { data } = useUnitRepairAreas(filters);

  if (!data) {
    return <div />;
  }

  return (
    <Form.Item
      label="ПЛОЩАДКА"
      name={name}
      colon={false}
      labelCol={direction === "vertical" ? { span: 24 } : undefined}
      css={{
        minWidth: 280,
      }}
    >
      <Select
        value={value}
        onChange={onChange}
        showSearch
        filterOption={(input, option) =>
          (option?.children?.toString() || "").toLowerCase().includes((input || "").toLowerCase()) || false
        }
        style={{
          width: 280,
        }}
        allowClear
      >
        {showAllOption && (
          <Option key="all" value={null}>
            Все
          </Option>
        )}
        {data?.map((repairArea) => (
          <Option key={repairArea.id} value={repairArea.id}>
            {repairArea.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}
