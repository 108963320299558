import { Form, Checkbox } from "antd";

import { ApiSliceEnum } from "core/app/api/enums";
import { LsoWorkTypeGroupEnum } from "core/app/api/units/LSO/enums";
import { SlpiWorkTypeGroupEnum } from "core/app/api/units/SLPI/interfaces";
import { useApiSlice } from "core/shared/main/hooks/useApiSlice";
import { useWorkType } from "core/shared/main/hooks/useWorkType";

export function ObjectIsMissing() {
  const workTypeId = Form.useWatch("workTypeId");
  const apiSlice = useApiSlice();
  const workType = useWorkType(workTypeId, apiSlice);

  if (apiSlice === ApiSliceEnum.slpi) {
    if (workType && "group" in workType && workType?.group !== SlpiWorkTypeGroupEnum.RESEARCH) {
      return null;
    }
  }
  if (apiSlice === ApiSliceEnum.lso) {
    if (workType && "group" in workType && workType?.group !== LsoWorkTypeGroupEnum.RESEARCH) {
      return null;
    }
  }

  return (
    <Form.Item valuePropName="checked" label="ОБЪЕКТ ОТСУТСТВУЕТ В СПИСКЕ" name="objectIsMissing">
      <Checkbox />
    </Form.Item>
  );
}
