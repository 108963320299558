import { Col, Row, Switch, Tooltip } from "antd";
import { ReactElement } from "react";

import Text from "core/lib/Text/Text";
import { IMaintenanceExecutionValuesItem } from "core/app/api/interfaces";

interface OossaMaintenanceExecutionValueProps {
  value?: IMaintenanceExecutionValuesItem;
  onChange?: (value: IMaintenanceExecutionValuesItem) => void;
  disabled: boolean;
}

/** Текущее значение выполнения работы (например, измеренное значение) по заданию на ТО. */
export default function OossaMaintenanceExecutionValue(props: OossaMaintenanceExecutionValueProps): ReactElement {
  const { onChange, value, disabled: isDisabled } = props;

  const handleValueSwitchChange = (checked: boolean) => {
    if (onChange && value) {
      onChange({
        ...value,
        value: checked ? "1" : "0",
      });
    }
  };

  return (
    <div>
      <Row className="mb-1">
        <Col span={22}>
          <Tooltip title={value?.field?.description}>
            <Text size="label2" boldness="semibold" className="list-item">
              {value?.field?.title}
            </Text>
          </Tooltip>
        </Col>
        <Col span={2}>
          <Row justify="end">
            <Col>
              <Switch
                checkedChildren="Да"
                unCheckedChildren="Нет"
                checked={value?.value === "1"}
                onChange={handleValueSwitchChange}
                disabled={isDisabled}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
