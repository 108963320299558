/* eslint-disable camelcase */
import qs from "qs";

import { apiSlice } from "core/app/api";
import { IResponseBody } from "core/shared/interfaces";
import { IDashboardShiftStatisticTasksByTeams } from "core/shared/dashboard/hooks/interfaces";

import { handleUpdateTaskQueryStarted, TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE } from "../../tools";
import { extractResults } from "../../emptyApi";

import {
  IApproveVolsMonthPlanPayload,
  IGetVolsCrossMonthPlansQueryParams,
  IGetVolsCrossMonthPlansStatsQueryParams,
  IGetVolsCrossMonthPlansTotalsByObjectsQueryParams,
  IGetVolsMonthPlanStatusQueryParams,
  IUpdateVolsCrossMonthPlanPayload,
  IVolsCross,
  IVolsCrossesMonthPlanByObject,
  IVolsCrossesMonthPlanStats,
  IVolsCrossMonthPlan,
  IVolsLine,
  IVolsMonthPlanStatus,
  IVolsObject,
  IVolsTask,
  IVolsLineMonthPlansStat,
  IVolsLineMonthPlansStatByMonths,
  IVolsLineMonthPlan,
  IGetVolsLineMonthPlansQueryParams,
  IRemoteVolsLineMonthPlansStatByMonths,
  IDashboardShiftStatisticTasksByTeamsVolsTask,
  IDashboardShiftStatisticTasksByCustomerDepartmentsVols,
  IDashboardVolsMaintenancePlanFactStatistic,
} from "./interfaces";
import { TCreateVolsTaskBody } from "./types";

export const volsApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      "vols-cross-month-plans-totals-by-objects",
      "vols-cross-month-plans-stats",
      "vols-month-plan-status",
      "vols-cross-month-plans",
      "vols-object",
      "vols-line",
      "vols-cross",
      "vols-line-month-plans-stat",
      "vols-line-month-plans/stats-by-months",
      "vols-line-month-plans",
      "dashboard/shift-statistic/tasks/by-teams/vols",
      "dashboard/shift-statistic/tasks/by-customer-department/vols",
      "dashboard/vols-maintenance-plan-fact-statistic",
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getVolsCrossMonthPlansStats: builder.query<IVolsCrossesMonthPlanStats, IGetVolsCrossMonthPlansStatsQueryParams>({
        query: (queryParams) => `vols-cross-month-plans/stat/?${qs.stringify(queryParams)}`,
        providesTags: ["vols-cross-month-plans-stats"],
      }),
      getVolsCrossMonthPlansTotalsByObjects: builder.query<
        IResponseBody<IVolsCrossesMonthPlanByObject>,
        IGetVolsCrossMonthPlansTotalsByObjectsQueryParams
      >({
        query: (queryParams) => `vols-cross-month-plans/totals-by-objects/?${qs.stringify(queryParams)}`,
        providesTags: (response) => [
          { type: "vols-cross-month-plans-totals-by-objects", id: "LIST" },
          ...(response?.results || []).map(({ id }) => ({
            type: "vols-cross-month-plans-totals-by-objects" as const,
            id,
          })),
        ],
      }),
      getVolsMonthPlanStatus: builder.query<IVolsMonthPlanStatus[], IGetVolsMonthPlanStatusQueryParams>({
        query: (queryParams) => `vols-month-plan-status/?${qs.stringify(queryParams)}`,
        providesTags: ["vols-month-plan-status"],
        transformResponse: extractResults,
      }),
      getVolsCrossMonthPlans: builder.query<IVolsCrossMonthPlan[], IGetVolsCrossMonthPlansQueryParams>({
        query: (queryParams) => `vols-cross-month-plans/?${qs.stringify(queryParams)}`,
        providesTags: (_, __, { object_id }) => [{ type: "vols-cross-month-plans", id: object_id }],
        transformResponse: extractResults,
      }),
      updateVolsCrossMonthPlan: builder.mutation<void, IUpdateVolsCrossMonthPlanPayload>({
        query: ({ id, object_id, ...body }) => ({
          url: `vols-cross-month-plans/${id}/`,
          method: `PATCH`,
          body,
        }),
        invalidatesTags: (_, __, { object_id }) => [
          { type: "vols-cross-month-plans-totals-by-objects", id: object_id },
          { type: "vols-cross-month-plans", id: object_id },
          "vols-cross-month-plans-stats",
        ],
      }),
      approveVolsCrossMonthPlan: builder.mutation<void, IApproveVolsMonthPlanPayload>({
        query: (body) => ({
          url: `vols-cross-month-plans/approve/`,
          method: "POST",
          body,
        }),
        invalidatesTags: [
          "vols-month-plan-status",
          { type: "vols-cross-month-plans-totals-by-objects", id: "LIST" },
          "vols-cross-month-plans",
        ],
      }),
      undoApproveVolsCrossMonthPlan: builder.mutation<void, IApproveVolsMonthPlanPayload>({
        query: (body) => ({
          url: `vols-cross-month-plans/undo-approve/`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["vols-month-plan-status", { type: "vols-cross-month-plans-totals-by-objects", id: "LIST" }],
      }),
      createVolsTask: builder.mutation<IVolsTask, TCreateVolsTaskBody>({
        query: (body) => ({
          url: `vols-tasks/`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["tasks", "open-tasks", "shifttask", "tasks-filtered", "kanban-board"],
      }),
      getVolsObjects: builder.query<IVolsObject[], string>({
        query: (queryString) => `vols-object/?${queryString}`,
        providesTags: ["vols-object"],
        transformResponse: extractResults,
      }),
      getVolsLines: builder.query<IVolsLine[], string>({
        query: (queryString) => `vols-line/?${queryString}`,
        providesTags: ["vols-line"],
        transformResponse: extractResults,
      }),
      getVolsCrosses: builder.query<IVolsCross[], string>({
        query: (queryString) => `vols-cross/?${queryString}`,
        providesTags: ["vols-cross"],
        transformResponse: extractResults,
      }),
      updateVolsTask: builder.mutation({
        query: ({ rtkQueryCacheKey, ...task }) => ({
          url: `vols-tasks/${task.id}/`,
          method: "PATCH",
          body: task,
        }),
        async onQueryStarted(args, queryContext) {
          await handleUpdateTaskQueryStarted(args, queryContext);
        },
        invalidatesTags: (result, error, arg) => [
          { type: "task", id: arg.id },
          ...TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE,
        ],
      }),

      getVolsLineMonthPlansStat: builder.query<IVolsLineMonthPlansStat, string>({
        query: (queryString) => `vols-line-month-plans/stat/?${queryString}`,
        providesTags: ["vols-line-month-plans-stat"],
      }),
      getVolsLineMonthPlansStatByMonths: builder.query<IVolsLineMonthPlansStatByMonths, string>({
        query: (queryString) => `vols-line-month-plans/stats-by-months/?${queryString}`,
        transformResponse: (res: IRemoteVolsLineMonthPlansStatByMonths) => {
          const totalPlanFact = Object.entries(res?.plans_by_months || {}).reduce(
            (acc, [month, value]) => ({
              totalPlan: acc.totalPlan + value.plan,
              totalFact: acc.totalFact + value.fact,
            }),
            { totalPlan: 0, totalFact: 0 }
          );
          return { ...res, ...totalPlanFact };
        },
        providesTags: ["vols-line-month-plans/stats-by-months"],
      }),

      getVolsLineMonthPlans: builder.query<
        IResponseBody<IVolsLineMonthPlan>,
        Partial<IGetVolsLineMonthPlansQueryParams>
      >({
        query: (queryParams) => `vols-line-month-plans/?${qs.stringify(queryParams)}`,
        providesTags: (volsLineMonthPlans) =>
          volsLineMonthPlans?.results
            ? [
                ...volsLineMonthPlans.results.map(({ id }) => ({ type: "vols-line-month-plans" as const, id })),
                "vols-line-month-plans",
              ]
            : ["vols-line-month-plans"],
      }),
      updateVolsLineMonthPlan: builder.mutation<void, Partial<IVolsLineMonthPlan>>({
        query: ({ id, ...body }) => ({
          url: `vols-line-month-plans/${id}/`,
          method: `PATCH`,
          body,
        }),
        invalidatesTags: (_, __, { id }) => [
          { type: "vols-line-month-plans", id },
          "vols-line-month-plans/stats-by-months",
        ],
      }),
      approveVolsLineMonthPlan: builder.mutation<void, IApproveVolsMonthPlanPayload>({
        query: (body) => ({
          url: `vols-line-month-plans/approve/`,
          method: "POST",
          body,
        }),
        invalidatesTags: [
          "vols-line-month-plans/stats-by-months",
          "vols-line-month-plans-stat",
          "vols-month-plan-status",
          { type: "vols-cross-month-plans-totals-by-objects", id: "LIST" },
          "vols-line-month-plans",
        ],
      }),
      undoApproveVolsLineMonthPlan: builder.mutation<void, IApproveVolsMonthPlanPayload>({
        query: (body) => ({
          url: `vols-line-month-plans/undo-approve/`,
          method: "POST",
          body,
        }),
        invalidatesTags: [
          "vols-line-month-plans/stats-by-months",
          "vols-line-month-plans-stat",
          "vols-month-plan-status",
          { type: "vols-cross-month-plans-totals-by-objects", id: "LIST" },
          "vols-line-month-plans",
        ],
      }),

      getDashboardShiftStatisticTasksByTeamsVols: builder.query<
        IDashboardShiftStatisticTasksByTeams<IDashboardShiftStatisticTasksByTeamsVolsTask>[],
        string
      >({
        query: (queryString: string) => `dashboard/shift-statistic/tasks/by-teams/vols/?${queryString}`,
        providesTags: ["dashboard/shift-statistic/tasks/by-teams/vols"],
      }),

      getDashboardShiftStatisticTasksByCustomerDepartmentsVols: builder.query<
        IDashboardShiftStatisticTasksByCustomerDepartmentsVols,
        string
      >({
        query: (queryString: string) => `dashboard/shift-statistic/tasks/by-objects/vols/?${queryString}`,
        providesTags: ["dashboard/shift-statistic/tasks/by-customer-department/vols"],
      }),

      getDashboardVolsMaintenancePlanFactStatistic: builder.query<IDashboardVolsMaintenancePlanFactStatistic, string>({
        query: (queryString) => `/dashboard/vols-maintenance-plan-fact-statistic/?${queryString}`,
        providesTags: ["dashboard/vols-maintenance-plan-fact-statistic"],
      }),
    }),
  });

export const {
  useGetVolsCrossMonthPlansTotalsByObjectsQuery,
  useGetVolsCrossMonthPlansStatsQuery,
  useGetVolsMonthPlanStatusQuery,
  useGetVolsCrossMonthPlansQuery,
  useUpdateVolsCrossMonthPlanMutation,
  useApproveVolsCrossMonthPlanMutation,
  useUndoApproveVolsCrossMonthPlanMutation,
  useCreateVolsTaskMutation,
  useGetVolsObjectsQuery,
  useGetVolsLinesQuery,
  useGetVolsCrossesQuery,
  useUpdateVolsTaskMutation,
  useGetVolsLineMonthPlansStatQuery,
  useGetVolsLineMonthPlansStatByMonthsQuery,
  useGetVolsLineMonthPlansQuery,
  useApproveVolsLineMonthPlanMutation,
  useUndoApproveVolsLineMonthPlanMutation,
  useUpdateVolsLineMonthPlanMutation,
  useGetDashboardShiftStatisticTasksByTeamsVolsQuery,
  useGetDashboardShiftStatisticTasksByCustomerDepartmentsVolsQuery,
  useGetDashboardVolsMaintenancePlanFactStatisticQuery,
} = volsApiSlice;
