import { Select, Form, Button } from "antd";
import { ReactElement, useState } from "react";
import { FormInstance } from "antd/es/form/Form";

import { useCustomerRepresentatives } from "core/shared/main/hooks/useCustomerRepresentatives";
import { useNetworkState } from "core/shared/main/hooks/useNetworkState";
import { ICustomerRepresentative } from "core/app/api/task/interfaces";

import { NewCustomerModal } from "./NewCustomerModal";

interface CustomerSelectProps {
  form: FormInstance;
}

function CustomerSelect({ form }: CustomerSelectProps): ReactElement {
  const { data: customers } = useCustomerRepresentatives({ can_sign_maintenance_act: true });
  const { isOnline } = useNetworkState();
  const [open, setOpen] = useState(false);
  let options = [
    {
      label: "",
      value: 0,
    },
  ];
  if (customers) {
    options = customers.map((customer: ICustomerRepresentative) => ({
      label: customer.full_name,
      value: customer.id,
    }));
  }

  const handleNewCustomerClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Form.Item
        name="executor"
        label="ФАМИЛИЯ ИМЯ ОТЧЕТСТВО"
        rules={[{ required: true, message: "Пожалуйста, введите заказчика!" }]}
      >
        <Select
          style={{ width: "100%" }}
          showSearch
          allowClear
          optionFilterProp="children"
          dropdownStyle={{ zIndex: 2000 }}
          filterOption={(input, option) =>
            (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={options}
        />
      </Form.Item>
      <Button disabled={!isOnline} onClick={handleNewCustomerClick} style={{ width: "100%" }}>
        МЕНЯ НЕТ В СПИСКЕ
      </Button>
      <NewCustomerModal form={form} open={open} setOpen={setOpen} />
    </>
  );
}

export { CustomerSelect };
