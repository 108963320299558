import { Form, Input } from "antd";

import { useDisabled } from "core/shared/task/hooks/useDisabled";
import { useGetNoPhotoReasonsQuery } from "core/app/api/task";

const { TextArea } = Input;

export function Comment() {
  const disabled = useDisabled();
  const { data } = useGetNoPhotoReasonsQuery("");
  const reasonId = Form.useWatch("no_photo_reason_type_id");
  const reason = data?.find((item) => reasonId === item.id);
  const required = reason?.is_comment_required;

  return (
    <Form.Item
      label="КОММЕНТАРИЙ"
      name="no_photo_reason"
      required={required}
      rules={[
        {
          validator: (_, value) => {
            if (!value && required) {
              return Promise.reject(new Error("Пожалуйста, введите КОММЕНТАРИЙ"));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <TextArea disabled={disabled} rows={5} placeholder="Причина, по которой фото и видео фиксация не требуется" />
    </Form.Item>
  );
}
