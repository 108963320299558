import { IResponseBody } from "core/shared/interfaces";
import { IDashboardShiftStatisticTasksByTeams } from "core/shared/dashboard/hooks/interfaces";

import { handleUpdateTaskQueryStarted, TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE } from "../../tools";
import { extractResults } from "../../emptyApi";
import { apiSlice } from "../..";

import {
  IApproveLkuMonthPlanPayload,
  ILkuMonthPlan,
  ILkuMonthPlanApprovalState,
  ILkuYearPlan,
  ILkuYearPlanByObject,
  IUpdateLkuMonthPlanPayload,
  IUpdateLkuYearPlanPayload,
  ILkuRack,
  ILkuObject,
  ILkuEquipment,
  IApproveLkuMonthPlanResponse,
  IMakeLkuMonthSchedulePayload,
  ILkuMonthStat,
  ILkuYearPlansByObjectsStat,
  IDashboardShiftStatisticTasksByObjectsLku,
  IDashboardLkuMaintenancePlanFactStatistic,
  IDashboardShiftStatisticTasksByTeamsLkuTask,
} from "./interfaces";

const lkuApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      "lku-year-plans",
      "lku-month-plans",
      "lku-year-plans-by-objects",
      "lku-year-plans/stat",
      "lku-month-plan-status",
      "lku-month-plans/stat",
      "lku-objects",
      "lku-racks",
      "lku-equipments",
      "lku-work-types",
      "dashboard/shift-statistic/tasks/by-teams/lku",
      "dashboard/shift-statistic/tasks/by-objects/lku",
      "dashboard/lku-maintenance-plan-fact-statistic",
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLkuYearPlansByObjects: builder.query<IResponseBody<ILkuYearPlanByObject>, string>({
        query: (queryString) => `lku-year-plans-by-objects/?${queryString}`,
        providesTags: ["lku-year-plans-by-objects"],
      }),
      getLkuYearPlans: builder.query<IResponseBody<ILkuYearPlan>, string>({
        query: (queryString) => `lku-year-plans/?${queryString}`,
        providesTags: ["lku-year-plans"],
      }),
      getLkuMonthPlans: builder.query<ILkuMonthPlan[], string>({
        query: (queryString) => `lku-month-plans/?${queryString}`,
        providesTags: ["lku-month-plans"],
        transformResponse: extractResults,
      }),
      getLkuYearPlansByObjectsStat: builder.query<ILkuYearPlansByObjectsStat, string>({
        query: (queryString) => `lku-year-plans-by-objects/lku-stat/?${queryString}`,
        providesTags: ["lku-year-plans/stat"],
      }),
      getLkuMonthPlansStat: builder.query<ILkuMonthStat, string>({
        query: (queryString) => `lku-month-plans/stat/?${queryString}`,
        providesTags: ["lku-month-plans/stat"],
      }),
      getLkuObjects: builder.query<ILkuObject[], string>({
        query: (queryString) => `lku-objects/?${queryString}`,
        providesTags: ["lku-objects"],
        transformResponse: extractResults,
      }),
      getLkuObject: builder.query<ILkuObject, number>({
        query: (id) => `lku-objects/${id}/`,
        providesTags: ["lku-objects"],
      }),
      getLkuRacks: builder.query<ILkuRack[], string>({
        query: (queryString) => `lku-racks/?${queryString}`,
        providesTags: ["lku-racks"],
        transformResponse: extractResults,
      }),
      getLkuRack: builder.query<ILkuRack, number>({
        query: (id) => `lku-racks/${id}/`,
        providesTags: ["lku-racks"],
      }),
      getLkuEquipments: builder.query<ILkuEquipment[], string>({
        query: (queryString) => `lku-equipments/?${queryString}`,
        providesTags: ["lku-equipments"],
        transformResponse: extractResults,
      }),
      getLkuEquipment: builder.query<ILkuEquipment, number>({
        query: (id) => `lku-equipments/${id}/`,
        providesTags: ["lku-equipments"],
      }),
      updateLkuYearPlan: builder.mutation<ILkuYearPlan, IUpdateLkuYearPlanPayload>({
        query: (payload) => ({
          url: `lku-year-plans/${payload.id}/`,
          method: "PATCH",
          body: {
            plans_by_months: payload.plans_by_months,
          },
        }),
        invalidatesTags: [
          "lku-year-plans-by-objects",
          "lku-year-plans/stat",
          "lku-year-plans",
          "lku-month-plans",
          "lku-month-plans/stat",
        ],
      }),
      createLkuTask: builder.mutation({
        query: (body) => ({
          url: "lku-tasks/",
          method: "POST",
          body,
        }),
        invalidatesTags: ["tasks", "open-tasks", "shifttask", "tasks-filtered", "kanban-board"],
      }),
      updateLkuMonthPlan: builder.mutation<void, IUpdateLkuMonthPlanPayload>({
        query: (payload) => ({
          url: `lku-month-plans/${payload.id}/`,
          method: "PATCH",
          body: payload.body,
        }),
        invalidatesTags: ["lku-month-plans", "lku-month-plans/stat"],
      }),
      approveLkuMonthPlan: builder.mutation<IApproveLkuMonthPlanResponse, IApproveLkuMonthPlanPayload>({
        query(payload) {
          return {
            url: `lku-month-plans/approve/`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["lku-year-plans", "lku-month-plan-status", "lku-month-plans"],
      }),
      undoApproveLkuMonthPlan: builder.mutation<string, IApproveLkuMonthPlanPayload>({
        query(payload) {
          return {
            url: `lku-month-plans/undo-approve/`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["lku-year-plans", "lku-month-plan-status", "lku-month-plans"],
      }),
      getLkuMonthPlanApprovalState: builder.query<ILkuMonthPlanApprovalState[], string>({
        query: (queryString) => `lku-month-plan-status/?${queryString}`,
        providesTags: ["lku-month-plan-status"],
        transformResponse: extractResults,
      }),
      updateLkuTask: builder.mutation({
        query: ({ rtkQueryCacheKey, ...task }) => ({
          url: `lku-tasks/${task.id}/`,
          method: "PATCH",
          body: task,
        }),
        async onQueryStarted(args, queryContext) {
          await handleUpdateTaskQueryStarted(args, queryContext);
        },
        invalidatesTags: (result, error, arg) => [
          { type: "task", id: arg.id },
          ...TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE,
        ],
      }),

      makeLkuMonthSchedule: builder.mutation<any, IMakeLkuMonthSchedulePayload>({
        query(payload) {
          return {
            url: `lku-month-plans/make-schedule/`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["lku-month-plans", "lku-month-plans/stat"],
      }),

      getDashboardShiftStatisticTasksByTeamsLku: builder.query<
        IDashboardShiftStatisticTasksByTeams<IDashboardShiftStatisticTasksByTeamsLkuTask>[],
        string
      >({
        query: (queryString: string) => `dashboard/shift-statistic/tasks/by-teams/lku/?${queryString}`,
        providesTags: ["dashboard/shift-statistic/tasks/by-teams/lku"],
      }),

      getDashboardShiftStatisticTasksByObjectsLku: builder.query<IDashboardShiftStatisticTasksByObjectsLku, string>({
        query: (queryString: string) => `dashboard/shift-statistic/tasks/by-objects/lku/?${queryString}`,
        providesTags: ["dashboard/shift-statistic/tasks/by-objects/lku"],
      }),

      getDashboardLkuMaintenancePlanFactStatistic: builder.query<IDashboardLkuMaintenancePlanFactStatistic, string>({
        query: (queryString) => `/dashboard/lku-maintenance-plan-fact-statistic/?${queryString}`,
        providesTags: ["dashboard/lku-maintenance-plan-fact-statistic"],
      }),
    }),
  });

export const {
  useGetLkuYearPlansByObjectsStatQuery,
  useUpdateLkuYearPlanMutation,
  useGetLkuYearPlansByObjectsQuery,
  useGetLkuYearPlansQuery,
  useGetLkuMonthPlansQuery,
  useApproveLkuMonthPlanMutation,
  useUndoApproveLkuMonthPlanMutation,
  useGetLkuMonthPlanApprovalStateQuery,
  useUpdateLkuMonthPlanMutation,
  useGetLkuObjectsQuery,
  useGetLkuObjectQuery,
  useGetLkuRacksQuery,
  useGetLkuRackQuery,
  useGetLkuEquipmentsQuery,
  useGetLkuEquipmentQuery,
  useUpdateLkuTaskMutation,
  useCreateLkuTaskMutation,
  useMakeLkuMonthScheduleMutation,
  useGetLkuMonthPlansStatQuery,
  useGetDashboardShiftStatisticTasksByTeamsLkuQuery,
  useGetDashboardShiftStatisticTasksByObjectsLkuQuery,
  useGetDashboardLkuMaintenancePlanFactStatisticQuery,
} = lkuApiSlice;
