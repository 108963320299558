import { Form, Select } from "antd";
import qs from "qs";

import { useUnit } from "core/shared/unit/useUnit";
import { TaskTypesIdsEnum } from "core/app/api/task/enums";
import { useGetEquipmentTypesQuery } from "core/app/api";

interface EquipmentTypeFieldProps {
  required?: true;
  taskType?: TaskTypesIdsEnum;
}

function EquipmentTypeField({ required, taskType }: EquipmentTypeFieldProps) {
  const form = Form.useFormInstance();
  const isMounted = Form.useWatch("isMounted");
  const transportId = Form.useWatch("transportId");
  const unit = useUnit();

  const queryParams: Record<string, string | number | boolean | undefined | null> = {
    is_mounted: isMounted,
  };

  if (unit) {
    queryParams.service = unit.service.id;
    queryParams.unit_id = unit.id;
  }
  if (transportId) {
    queryParams.transport = transportId;
  }

  const { data, isFetching } = useGetEquipmentTypesQuery(qs.stringify(queryParams));

  const options = (data?.results || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  if (data?.results && data.results.length === 1) {
    form.setFieldValue("equipmentTypeId", data.results[0].id);
  }

  const handleSelectChange = () => {
    form.setFieldValue("equipmentId", null);
    if (!isMounted && taskType !== TaskTypesIdsEnum.OTHER) {
      form.setFieldValue("locationId", null);
    }
  };

  return (
    <Form.Item label="ТИП ОБОРУДОВАНИЯ" name="equipmentTypeId" rules={required && [{ required: true, message: "" }]}>
      <Select
        className="equipment-type-field"
        size="large"
        allowClear
        showSearch
        loading={isFetching}
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleSelectChange}
      />
    </Form.Item>
  );
}

export default EquipmentTypeField;
