import { ReactElement } from "react";
import { Select, Form } from "antd";
import qs from "qs";

import { useGetEquipmentTypesQuery } from "core/app/api";
import { useUnit } from "core/shared/unit/useUnit";

import { FIELD_NAMES } from "../../constants";

export function LkuEquipmentTypeField(): ReactElement {
  const unit = useUnit();
  const form = Form.useFormInstance();

  const queryProps = {
    service: unit?.service?.id,
  };
  const { data, isFetching } = useGetEquipmentTypesQuery(qs.stringify(queryProps));

  const options = (data?.results || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleSelectChange = (value: number) => {
    form.setFieldsValue({
      [FIELD_NAMES.EQUIPMENT_TYPE]: value,
      [FIELD_NAMES.TECH_NODE]: undefined,
      [FIELD_NAMES.EQUIPMENT]: undefined,
    });
  };

  return (
    <Form.Item label="ТИП ОБОРУДОВАНИЯ" name={FIELD_NAMES.EQUIPMENT_TYPE} colon={false}>
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleSelectChange}
      />
    </Form.Item>
  );
}
