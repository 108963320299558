import { Table } from "antd";
import { ReactElement } from "react";

import { IDashboardShiftStatisticTasksByTeamsVolsTask } from "core/app/api/units/VOLS/interfaces";
import { EffortTypeEnum } from "core/models/enums";
import { IGroup } from "core/shared/dashboard";
import { useDashboardShiftStatisticTasksByTeamsVols } from "core/shared/dashboard/hooks/useDashboardShiftStatisticTasksByTeamsVols";

import ClosingNote from "../Fields/ClosingNote";
import { Comment } from "../Fields/Comment";
import Effort from "../Fields/Effort";
import ElapsedTime from "../Fields/ElapsedTime";
import RequestDescription from "../Fields/RequestDescription";

import { VolsEquipmentType } from "./VolsEquipmentType";
import { VolsLine } from "./VolsLine";
import { VolsObject } from "./VolsObject";

type TTableRecord = IDashboardShiftStatisticTasksByTeamsVolsTask | IGroup<IDashboardShiftStatisticTasksByTeamsVolsTask>;

const columns = [
  {
    title: "ЛИНИЯ",
    dataIndex: "line",
    render: (_: string, record: TTableRecord) => <VolsLine record={record} />,
    width: 140,
  },
  {
    title: "ОБЪЕКТ",
    dataIndex: "object",
    render: (_: string, record: TTableRecord) => <VolsObject record={record} />,
    width: 140,
  },
  {
    title: "ЛИНИЯ/КРОСС",
    dataIndex: "system",
    render: (_: string, record: TTableRecord) => <VolsEquipmentType record={record} />,
    width: 100,
  },
  {
    title: "ОПИСАНИЕ ЗАДАЧИ",
    dataIndex: "request_description",
    width: 300,
    render: (_: string, record: TTableRecord) => <RequestDescription record={record} />,
  },
  {
    title: "СТАТУС",
    dataIndex: "status",
  },
  {
    title: "КОММЕНТАРИЙ",
    dataIndex: "request_comment",
    render: (_: string, record: TTableRecord) => <Comment record={record} />,
  },
  {
    title: "ПРИМЕЧАНИЕ К РАЗРЕШЕНИЮ",
    dataIndex: "closing_note",
    render: (_: string, record: TTableRecord) => <ClosingNote record={record} />,
  },
  {
    title: "ЗАГРУЗКА (ПЛАН)",
    dataIndex: "effort",
    render: (_: string, record: TTableRecord) => <Effort record={record} effortType={EffortTypeEnum.HOURS} />,
  },
  {
    title: "ЗАГРУЗКА (ФАКТ)",
    dataIndex: "elapsed_time",
    render: (_: string, record: TTableRecord) => <ElapsedTime record={record} effortType={EffortTypeEnum.HOURS} />,
  },
];

export function StatisticByTeamVolsTable(): ReactElement {
  const dataSource = useDashboardShiftStatisticTasksByTeamsVols();

  return (
    <Table
      expandable={{
        defaultExpandAllRows: true,
        indentSize: 0,
      }}
      size="small"
      dataSource={dataSource}
      rowKey="id"
      columns={columns}
    />
  );
}
