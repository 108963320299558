import { ReactElement } from "react";
import { Row, Col } from "antd";

import { useShiftPlan } from "core/shared/shift/useShiftPlan";
import { ShiftPlanStatusesEnum } from "core/app/api/shift/enums";
import { TaskAttachmentCategoryEnum } from "core/app/api/task/enums";
import { EtoTaskAttachmentCategoryEnum } from "core/app/api/task/eto/enums";
import { useGetTaskAttachmentsQuery } from "core/app/api/task";

import SelectMediaSource from "./SelectMediaSource";
import { MediaItem } from "./MediaItem";

interface MediaProps {
  task: { id: number };
  category: TaskAttachmentCategoryEnum | EtoTaskAttachmentCategoryEnum;
  isDisabled?: boolean;
}

function Category({ task, category, isDisabled }: MediaProps): ReactElement {
  const { data } = useGetTaskAttachmentsQuery(task.id);
  const shiftPlan = useShiftPlan();

  let isLocalDisabled = true;
  if (shiftPlan?.status === ShiftPlanStatusesEnum.APPROVED && category !== TaskAttachmentCategoryEnum.FilesByTask) {
    isLocalDisabled = false;
  }
  isLocalDisabled = isDisabled ?? isLocalDisabled;

  return (
    <Row gutter={16}>
      {!isLocalDisabled && (
        <Col span={3} className="mb-7" sm={3} xs={6}>
          <div className="media-upload">
            <SelectMediaSource taskId={task.id} category={category} />
          </div>
        </Col>
      )}
      {data
        ?.filter((attachment) => attachment.category === category)
        .map((attachment) => (
          <Col span={3} key={attachment.id} className="picture-card-wrapper mb-7" sm={3} xs={6}>
            <MediaItem taskId={task.id} attachment={attachment} disabled={isLocalDisabled} />
          </Col>
        ))}
    </Row>
  );
}

export { Category };
