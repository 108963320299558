import { Col, Row } from "antd";
import clsx from "clsx";

import { ITask } from "core/app/api/task/interfaces";
import { EffortTypeEnum } from "core/models/enums";
import {
  CustomerDepartment,
  EffortField,
  Initiator,
  OossaObject,
  OossaSystem,
  RequestCreationDatetime,
  WorkType,
} from "core/widgets/TaskModals/shared/Fields";

type TOossaGeneralInfoProps = {
  task: ITask;
};

/** Заглавная часть (раздел) в карточке доп. работ ООССА. */
export default function OossaGeneralInfo({ task }: TOossaGeneralInfoProps) {
  return (
    <Row gutter={16} className={clsx("mb-1", "mt-1")}>
      <Col span={12}>
        <WorkType task={task} />
        <OossaObject task={task} />
        <RequestCreationDatetime label="Дата создания" task={task} format="DD.MM.YYYY в HH:mm:ss" />
      </Col>
      <Col span={12}>
        <CustomerDepartment task={task} />
        <OossaSystem task={task} />
        <EffortField task={task} effortType={EffortTypeEnum.HOURS} />
        <Initiator task={task} />
      </Col>
    </Row>
  );
}
