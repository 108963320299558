import { skipToken } from "@reduxjs/toolkit/query/react";
import qs from "qs";

import { useUnitId } from "core/shared/unit/useUnitId";
import { useGetCustomerDepartmentsQuery } from "core/app/api";

export function useCustomerDepartments(filters?: Record<string, any>, skip?: boolean) {
  const [unitId] = useUnitId();
  const queryString = qs.stringify({
    unit_id: unitId,
    ...filters,
  });
  return useGetCustomerDepartmentsQuery(unitId ? queryString : skipToken, {
    skip,
  });
}
