import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { PictureOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { useFileBuffer } from "core/widgets/BackgroundUpload/useFileBuffer";
import { getUuid } from "core/shared/tools";
import { TaskAttachmentCategoryEnum } from "core/app/api/task/enums";
import { EtoTaskAttachmentCategoryEnum } from "core/app/api/task/eto/enums";

interface UploadMediaProps {
  taskId: number;
  category: TaskAttachmentCategoryEnum | EtoTaskAttachmentCategoryEnum;
  hide: () => void;
}

function UploadMedia({ taskId, category, hide }: UploadMediaProps) {
  const [fileState, setFileState] = useState<File[]>([]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClickInput = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
    hide();
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFileState(Object.values(e.target.files));
    }
  };

  const { bufferOverflow } = useFileBuffer();

  useEffect(() => {
    if (fileState.length) {
      const bufferData = fileState.map((item) => ({
        taskId,
        category,
        key: getUuid(),
        file: item,
        compression: true,
      }));
      bufferOverflow(bufferData);
      setFileState([]);
    }
  }, [fileState]);

  return (
    <>
      <input multiple onChange={handleChangeInput} type="file" hidden ref={inputRef} />
      <Button
        onClick={handleClickInput}
        shape="circle"
        type="default"
        icon={<PictureOutlined style={{ fontSize: "20px" }} />}
      />
    </>
  );
}

export { UploadMedia };
