import { useContext } from "react";
import qs from "qs";

import { useGetDashboardShiftStatisticTasksByObjectsSlpiQuery } from "core/app/api/units/SLPI";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { useUnitId } from "core/shared/unit/useUnitId";
import { apiDateFormat } from "core/shared/tools/dayjs";

const useDashboardShiftStatisticTasksByObjectsSlpi = () => {
  const { date, shiftNum } = useContext(ShiftContext);
  const [unitId] = useUnitId();

  let queryString = "";
  if (unitId && shiftNum && date) {
    const queryParams: Record<string, string | number> = {
      unit_id: unitId,
      shift_num: shiftNum,
      date: date?.format(apiDateFormat),
    };
    queryString = qs.stringify(queryParams);
  }

  return useGetDashboardShiftStatisticTasksByObjectsSlpiQuery(queryString, {
    skip: !queryString,
  });
};

export { useDashboardShiftStatisticTasksByObjectsSlpi };
