import { Row, Col } from "antd";
import { ReactElement } from "react";

import { VolsEquipmentTypeNameEnum } from "core/app/api/units/VOLS/enums";
import { ITask } from "core/app/api/task/interfaces";
import { Customer, AccumulatorElapsedTime, EffortField } from "core/widgets/TaskModals/shared/Fields";
import { ZabbixAlert } from "core/widgets/Equipment/ZabbixAlert";
import { VolsObject } from "core/widgets/TaskModals/shared/Fields/VolsObject";
import { VolsDepartment } from "core/widgets/TaskModals/shared/Fields/VolsDepartment";
import { VolsEquipmentType } from "core/widgets/TaskModals/shared/Fields/VolsEquipmentType";
import { VolsFactVolume } from "core/widgets/TaskModals/shared/Fields/VolsFactVolume";
import { VolsLineNumber } from "core/widgets/TaskModals/shared/Fields/VolsLineNumber";
import { VolsPlannedVolume } from "core/widgets/TaskModals/shared/Fields/VolsPlannedVolume";
import { EffortTypeEnum } from "core/models/enums";
import { Deadline } from "core/widgets/TaskModals/shared/Fields/Deadline";

interface GeneralInfoProps {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке ТО ВОЛС */
function GeneralInfo(props: GeneralInfoProps): ReactElement {
  const { task } = props;
  const isLine = task.equipment_type?.code_enum === VolsEquipmentTypeNameEnum.LINE;
  return (
    <>
      <ZabbixAlert equipmentId={task.equipment?.id} />
      <Row className="mb-1">
        <Col>
          <Deadline task={task} />
          <Customer task={task} />
          <VolsDepartment task={task} />
          {!isLine && <VolsObject task={task} />}
          <VolsLineNumber task={task} />
          <VolsEquipmentType task={task} label="Тип ТО" />
          <EffortField task={task} effortType={EffortTypeEnum.HOURS} />
          <AccumulatorElapsedTime task={task} />
          {isLine && <VolsPlannedVolume task={task} />}
          {isLine && <VolsFactVolume task={task} />}
        </Col>
      </Row>
    </>
  );
}

export default GeneralInfo;
