import { Select, Form, FormInstance } from "antd";
import { ReactElement } from "react";
import qs from "qs";

import { StateNamesEnum } from "core/app/api/task/enums";
import { useTask } from "core/shared/task/hooks/useTask";
import { useGetItsmEquipmentQuery } from "core/app/api";

interface ElementSelectProps {
  disabled: boolean;
  form: FormInstance;
}

function ElementSelect({ disabled, form }: ElementSelectProps): ReactElement {
  const { data: task } = useTask();
  const queryParams = {
    equipment_type_id: task?.itsm_equipment?.id,
  };
  const { data: itsmEquipmentTypeElements } = useGetItsmEquipmentQuery(qs.stringify(queryParams));

  let options: {
    label: string;
    value: number;
  }[] = [];

  if (itsmEquipmentTypeElements) {
    options = itsmEquipmentTypeElements.map((itsmEquipmentTypeElement) => ({
      label: itsmEquipmentTypeElement.name,
      value: itsmEquipmentTypeElement.id,
    }));
  }

  return (
    <Form.Item
      label="ЭЛЕМЕНТ"
      name="element_id"
      rules={[
        {
          validator: (_, value) => {
            const newState = form.getFieldValue("newState");
            if (newState === StateNamesEnum.Closed && !value && !!task?.itsm_equipment) {
              return Promise.reject(new Error("Пожалуйста, введите ЭЛЕМЕНТ"));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Select
        disabled={disabled || !task?.itsm_equipment}
        style={{ width: "100%" }}
        dropdownStyle={{ zIndex: 2000 }}
        dropdownMatchSelectWidth={650}
        options={options}
        allowClear
        showSearch
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
        onChange={() => {
          form.setFieldValue("malfunction_id", null);
          form.setFieldValue("malfunction_reason_id", null);
        }}
      />
    </Form.Item>
  );
}

export default ElementSelect;
