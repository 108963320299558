import { ReactElement } from "react";
import { Select, Form } from "antd";

import { useCustomerDepartments } from "core/shared/main/hooks/useCustomerDepartments";

type TCustomerDepartmentFieldProps = {
  name: string;
  label?: string;
  required?: boolean;
  onChange?: (value: number) => void;
};

export function CustomerDepartmentField({
  required,
  name,
  label,
  onChange,
}: TCustomerDepartmentFieldProps): ReactElement {
  const customerId = Form.useWatch("customerId");
  const { data, isFetching } = useCustomerDepartments({
    customer_id: customerId,
  });
  const options = (data || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleSelectChange = (value: number) => {
    onChange?.(value);
  };

  return (
    <Form.Item
      label={label || "БАЛАНСОДЕРЖАТЕЛЬ"}
      name={name}
      colon={false}
      required={required}
      rules={
        required
          ? [
              {
                required,
                message: "",
              },
            ]
          : undefined
      }
    >
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleSelectChange}
      />
    </Form.Item>
  );
}
