import { Form, Select } from "antd";
import qs from "qs";

import { useAppSelector } from "core/app/store";
import { taskTypeToCategoryGroupFilterMap } from "core/widgets/CreateTaskModal/constants";
import { useGetItsmBaseEquipmentTypeQuery } from "core/app/api/task";

function CreatioEquipmentField() {
  const { createTaskModalType } = useAppSelector((state) => state.temp);
  const requestCategoryId = Form.useWatch("requestCategoryId");
  const isMounted = Form.useWatch("isMounted");
  let typeGroupCategory: string | undefined;
  if (createTaskModalType) {
    typeGroupCategory = taskTypeToCategoryGroupFilterMap[createTaskModalType];
  }
  const queryParams = {
    itsm_request_category: requestCategoryId,
    type_group_category: typeGroupCategory,
    is_mounted: isMounted,
  };

  const { data, isFetching: isBaseEquipmentDataFetching } = useGetItsmBaseEquipmentTypeQuery(qs.stringify(queryParams));

  const options = (data || []).map((equipment) => ({
    label: equipment.name,
    value: equipment.id,
  }));

  return (
    <Form.Item
      label="ОБОРУДОВАНИЕ В CREATIO"
      name="creatioEquipmentId"
      colon={false}
      rules={[{ required: true, message: "" }]}
    >
      <Select
        className="creatio-equipment-field"
        size="large"
        allowClear
        showSearch
        loading={isBaseEquipmentDataFetching}
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
      />
    </Form.Item>
  );
}

export default CreatioEquipmentField;
