import { createContext } from "react";

import { EtoTaskStepNameEnum } from "core/app/api/task/eto/enums";

import { EtoTaskSignatureSideEnum } from "./AssistanceCarMaintenance/interfaces";

export enum FormPagesEnum {
  TASK,
  SIGNATURE,
}

export interface IAssistanceCarMaintenanceContextValues {
  page: FormPagesEnum;
  setPage: (arg: FormPagesEnum) => void;
  showErrorMessage: boolean;
  setShowErrorMessage: (arg: boolean) => void;
  errorsSteps: Record<EtoTaskStepNameEnum, number>;
  setErrorsSteps: (arg: Record<EtoTaskStepNameEnum, number>) => void;
  signatureSide: EtoTaskSignatureSideEnum | null;
  setSignatureSide: (side: EtoTaskSignatureSideEnum | null) => void;
  selectedStepTab: EtoTaskStepNameEnum | null;
  setSelectedStepTab: (selectedStepTab: EtoTaskStepNameEnum) => void;
}

export const AssistanceCarMaintenanceContext = createContext<IAssistanceCarMaintenanceContextValues>({
  page: FormPagesEnum.TASK,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPage: () => {},
  showErrorMessage: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowErrorMessage: () => {},
  errorsSteps: {
    PERFORMING_ETO: 0,
    PRE_SHIFT_CHECK: 0,
    POST_SHIFT_CHECK: 0,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrorsSteps: () => {},
  signatureSide: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSignatureSide: () => {},
  selectedStepTab: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedStepTab: () => {},
});
