import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function Horizon({ task }: FieldProps): ReactElement {
  return <Field label="Горизонт" value={task.horizon?.name} />;
}

export { Horizon };
