import { ReactElement } from "react";
import { Tabs } from "antd";

import { ITask } from "core/app/api/task/interfaces";
import Tab from "core/widgets/TaskModals/shared/Tab";
import { isSlpiTask } from "core/shared/task";

import { MaintenanceExecution } from "./MaintenanceExecution";

interface Props {
  task: ITask;
}

/** Раздел выполнения работ в карточке технического обслуживания (ТО).
 * Используется для стационарного оборудования ПСПА, СДГР, СЛПИ. */
export function MaintenanceExecutions({ task }: Props): ReactElement {
  if (isSlpiTask(task)) {
    return (
      <Tabs
        items={task.maintenance_execution?.map((item, index) => ({
          key: `${item.equipment_type.id}`,
          label: <Tab maintenanceExecution={item} />,
          children: <MaintenanceExecution maintenanceExecution={item} index={index} />,
          forceRender: true,
        }))}
      />
    );
  }

  return <MaintenanceExecution maintenanceExecution={task.maintenance_execution[0]} index={0} />;
}
