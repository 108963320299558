import { useLocation } from "react-router-dom";

import { ITask } from "core/app/api/task/interfaces";
import { AppRoutes } from "core/shared/constants.routes";
import { useAppSelector } from "core/app/store";

const useClosingDate = (task: ITask): string | null => {
  const location = useLocation();
  const { date } = useAppSelector((state) => state.maintenanceAttached);

  if (task.closing_date) {
    return task.closing_date;
  }
  if (location.pathname === AppRoutes.pspa && date) {
    return date;
  }

  return task.planned_date;
};

export { useClosingDate };
