import { capitalize } from "lodash";

import { useUnitShifts } from "core/shared/unit/useUnitShifts";
import { ITask } from "core/app/api/task/interfaces";
import { IEtoTask } from "core/app/api/task/eto/interfaces";

import { Field } from "../Field";

type TShiftProps = {
  task: ITask | IEtoTask;
};

export function Shift({ task }: TShiftProps) {
  const { data } = useUnitShifts();

  const taskShift = data?.find((shift) => shift.number === task.shift_number);
  const shiftName = taskShift?.name || String(task.shift_number);

  return <Field label="Смена" value={capitalize(shiftName)} />;
}
