import { Carousel } from "react-responsive-carousel";

import { ILocalAttachment } from "core/app/api/task/interfaces";

import AttachmentGalleryItem from "./AttachmentGalleryItem";

type TAttachmentsGalleryProps = {
  attachments: ILocalAttachment[];
  selectedAttachmentId: string;
};

function AttachmentsGallery({ attachments, selectedAttachmentId }: TAttachmentsGalleryProps) {
  const selectedAttachment = attachments.findIndex((attachment) => attachment.uid === selectedAttachmentId);
  return (
    <Carousel
      className="attachment-gallery"
      swipeable
      swipeScrollTolerance={5}
      emulateTouch
      showStatus
      showThumbs={false}
      selectedItem={selectedAttachment}
    >
      {attachments.map((attachment) => (
        <AttachmentGalleryItem key={attachment.uid} attachment={attachment} />
      ))}
    </Carousel>
  );
}

export default AttachmentsGallery;
