export enum ProcessingStatusEnum {
  UNPROCESSED = "UNPROCESSED",
  SKIPPED = "SKIPPED",
  IN_PROCESS = "IN_PROCESS",
  PROCESSED = "PROCESSED",
}

export enum RecoveryTasksNotificationEventEnum {
  NEW = "NEW",
  STATE_CHANGE_TO_WORK = "STATE_CHANGE_TO_WORK",
}
