import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Modal, Row, Space } from "antd";
import { get } from "lodash";
import { ReactElement, useEffect, useState } from "react";

import { useAppDispatch } from "core/app/store";
import { setTaskToReset } from "core/app/store/maintenance-attached-slice";
import { setTask } from "core/app/store/task-slice";
import Text from "core/lib/Text/Text";
import { IDictionaryItem } from "core/shared/interfaces";
import { useNetworkState } from "core/shared/main/hooks/useNetworkState";
import { hasEmptyAttachmentsCategory } from "core/shared/task";
import { useClosingDate } from "core/shared/task/hooks/useClosingDate";
import { useDisabledByLocation } from "core/shared/task/hooks/useDisabledByLocation";
import { useDisabledByShiftStatus } from "core/shared/task/hooks/useDisabledByShift";
import { useUpdateTask } from "core/shared/task/hooks/useUpdateTask";
import { ITask } from "core/app/api/task/interfaces";
import { useGetTaskAttachmentsQuery, useGetTaskStatesQuery } from "core/app/api/task";
import { StateNamesEnum, TaskAttachmentCategoryEnum } from "core/app/api/task/enums";

import { EmptyAttachmentWarning } from "../../Media/EmptyAttachmentWarning";

interface StateButtonsProps {
  task: ITask;
  hideClearButton?: boolean;
  onValidationFailed?: (errorInfo: any) => void;
}

function StateButtons(props: StateButtonsProps): ReactElement {
  const { task, hideClearButton: shouldHideClearButton, onValidationFailed } = props;
  const dispatch = useAppDispatch();
  const { data: { results: states } = {} } = useGetTaskStatesQuery("");
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task?.discriminator);
  const [newStateName, setNewStateName] = useState<StateNamesEnum>();
  const isDisabledByShiftStatus = useDisabledByShiftStatus();
  const isDisabledByLocation = useDisabledByLocation();
  const { isOnline } = useNetworkState();
  const isDisabled = isDisabledByShiftStatus || isDisabledByLocation || !isOnline;
  const closingDate = useClosingDate(task);
  const { data: attachments } = useGetTaskAttachmentsQuery(task.id);

  const form = Form.useFormInstance<ITask>();
  const values = Form.useWatch([], form);

  const handleChangeStatusClick = async (stateName: StateNamesEnum) => {
    setNewStateName(stateName);
    if (!states) {
      return;
    }
    const newState = states.find((state: IDictionaryItem) => state.name === stateName);
    if (newState) {
      if (stateName === StateNamesEnum.Closed && form) {
        try {
          await form.validateFields();
        } catch (e) {
          onValidationFailed?.(e);
          return;
        }
      }

      const payload = {
        ...values,
        state_id: newState.id,
      };

      if (stateName === StateNamesEnum.Closed) {
        payload.closing_date = closingDate;
      }

      if (stateName === StateNamesEnum.InWork) {
        payload.closing_date = null;
      }

      updateTaskRequest(payload);
    }
  };

  const handleCloseClick = () => {
    const hasVandalActions =
      values?.is_vandalism_detected ||
      // eslint-disable-next-line camelcase
      values?.maintenance_execution?.some(({ is_vandalism_detected }) => is_vandalism_detected);
    const handleOk = () => {
      handleChangeStatusClick(StateNamesEnum.Closed);
    };
    if (hasEmptyAttachmentsCategory(attachments, hasVandalActions)) {
      const categories = Object.values(TaskAttachmentCategoryEnum).filter(
        (category) => hasVandalActions || category !== TaskAttachmentCategoryEnum.Vandalism
      );
      Modal.confirm({
        title: <Text boldness="black">Внимание</Text>,
        icon: <ExclamationCircleOutlined />,
        content: <EmptyAttachmentWarning attachments={attachments} categories={categories} />,
        okText: "Подтвердить",
        cancelText: "Отменить",
        onOk: handleOk,
      });
    } else {
      handleOk();
    }
  };

  const handleResetClick = () => {
    if (task) {
      dispatch(setTaskToReset(task.id));
    }
  };

  useEffect(() => {
    if (updateTaskResult.isSuccess) {
      dispatch(setTask(updateTaskResult.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateTaskResult.isSuccess]);

  useEffect(() => {
    if (updateTaskResult.isError) {
      const errorMessage: string =
        get(updateTaskResult, "error.data.used_materials[0]") || get(updateTaskResult, "error.data.comment[0]", "");
      if (errorMessage) {
        message.error(errorMessage);
      }
    }
  }, [updateTaskResult]);

  return (
    <Row justify="end" className="mb-2">
      <Col>
        {task && task.state && (
          <Space>
            {task.state.name !== StateNamesEnum.Closed && !shouldHideClearButton && (
              <Button disabled={isDisabled} onClick={handleResetClick}>
                ОЧИСТИТЬ ВВЕДЕННЫЕ ДАННЫЕ
              </Button>
            )}
            {task.state.name === StateNamesEnum.Closed && (
              <Button
                disabled={isDisabled}
                onClick={() => {
                  handleChangeStatusClick(StateNamesEnum.InWork);
                }}
                type="primary"
                loading={updateTaskResult.isLoading && newStateName === StateNamesEnum.InWork}
              >
                ВЕРНУТЬ В РАБОТУ
              </Button>
            )}
            {task.state.name !== StateNamesEnum.Closed && task.state.name !== StateNamesEnum.InWork && (
              <Button
                onClick={() => {
                  handleChangeStatusClick(StateNamesEnum.InWork);
                }}
                type="primary"
                disabled={isDisabled}
                loading={updateTaskResult.isLoading && newStateName === StateNamesEnum.InWork}
              >
                ВЗЯТЬ В РАБОТУ
              </Button>
            )}
            {task.state.name === StateNamesEnum.InWork && (
              <Button
                onClick={() => {
                  handleChangeStatusClick(StateNamesEnum.OnPause);
                }}
                type="primary"
                disabled={isDisabled}
                loading={updateTaskResult.isLoading && newStateName === StateNamesEnum.OnPause}
              >
                ПРИОСТАНОВИТЬ
              </Button>
            )}
            {task.state.name !== StateNamesEnum.Closed && task.state.name !== StateNamesEnum.New && (
              <Button
                onClick={() => {
                  handleCloseClick();
                }}
                type="primary"
                disabled={isDisabled}
                loading={updateTaskResult.isLoading && newStateName === StateNamesEnum.Closed}
              >
                ЗАКРЫТЬ ЗАДАЧУ
              </Button>
            )}
          </Space>
        )}
      </Col>
    </Row>
  );
}

export default StateButtons;
