import { ReactElement } from "react";
import { Row, Col } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

import LegendBullet from "core/widgets/LegendBullet";
import Text from "core/lib/Text/Text";
import { chartColorRed, chartColorGreen, chartColorMagenta, chartColorYellow } from "uikit/themes/default/colors";
import { IDashboardAggregation } from "core/shared/dashboard/hooks/interfaces";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IPriorityProps {
  aggregations: IDashboardAggregation[] | undefined;
}

const colors = [chartColorGreen, chartColorMagenta, chartColorYellow, chartColorRed];

function Priority({ aggregations }: IPriorityProps): ReactElement {
  let aggregationsSorted = [] as IDashboardAggregation[];
  if (aggregations) {
    aggregationsSorted = [...aggregations]?.sort((a, b) => b.key - a.key);
  }

  return (
    <>
      <div className="mb-1">
        <Text className="uppercase" boldness="semibold">
          Приоритет
        </Text>
      </div>
      <Row>
        <Col style={{ width: "120px", height: "120px" }}>
          <Pie
            data={{
              datasets: [
                {
                  data: aggregationsSorted.map((item) => item.amount),
                  backgroundColor: aggregationsSorted.map((item) => colors[item.key]),
                },
              ],
            }}
          />
        </Col>
        <Col>
          <table>
            <tbody>
              {aggregationsSorted.map((item, index) => (
                <tr key={item.key}>
                  <td>
                    <LegendBullet color={colors[item.key]} />
                  </td>
                  <td>
                    <Text size="label3" boldness="semibold" className="uppercase mr-3">
                      {item.verbose_name}
                    </Text>
                  </td>
                  <td>
                    <Text size="label3" boldness="semibold">
                      {item.amount} шт.
                    </Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
}

export default Priority;
