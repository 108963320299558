import { ReactElement, useContext } from "react";

import { StatisticTeamSelector } from "core/shared/dashboard/ui/TeamSelector";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { useGetDashboardShiftStatisticTasksByTeamsSlpiQuery } from "core/app/api/units/SLPI";

export function StatisticTeamSelectorSlpi(): ReactElement {
  const { queryString } = useContext(ShiftContext);
  const { data, isSuccess } = useGetDashboardShiftStatisticTasksByTeamsSlpiQuery(queryString, { skip: !queryString });

  if (isSuccess && data) {
    return <StatisticTeamSelector teams={data} />;
  }
  return <div />;
}
