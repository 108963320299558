import { useContext } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetDashboardShiftStatisticTasksByTeamsPspaQuery } from "core/app/api/units/PSPA";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { getDashboardShiftStatisticTasksByTeam } from "core/shared/dashboard/hooks/getDashboardShiftStatisticTasksByTeam";

function useDashboardShiftStatisticTasksByTeamsPspa() {
  const { queryString, teamId } = useContext(ShiftContext);
  const { data } = useGetDashboardShiftStatisticTasksByTeamsPspaQuery(queryString ?? skipToken);
  return getDashboardShiftStatisticTasksByTeam(data, teamId);
}

export { useDashboardShiftStatisticTasksByTeamsPspa };
