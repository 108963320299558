import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function LsoObject({ task }: FieldProps): ReactElement {
  return <Field label="Объект" value={task.lso_object?.name} />;
}

export { LsoObject };
