import { ReactElement } from "react";
import { Select, Form } from "antd";
import qs from "qs";

import { useGetOossaObjectsQuery } from "core/app/api/units/OOSSA";

import { useIsFieldRequired } from "../../useIsFieldRequired";
import { FIELD_NAMES } from "../../constants";

export function Object(): ReactElement {
  const customerDepartmentId = Form.useWatch("customerDepartmentId");
  const form = Form.useFormInstance();
  const required = useIsFieldRequired(FIELD_NAMES.OBJECT);
  const queryProps = {
    customer_department_id: customerDepartmentId,
    limit: 1000,
  };

  const { data, isFetching } = useGetOossaObjectsQuery(qs.stringify(queryProps));

  const options = (data?.results || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleSelectChange = () => {
    form.setFieldsValue({
      nodeId: undefined,
      systemId: null,
      equipmentId: undefined,
    });
  };

  return (
    <Form.Item
      label="ОБЪЕКТ"
      name={FIELD_NAMES.OBJECT}
      colon={false}
      required={required}
      rules={[
        {
          required,
          message: "",
        },
      ]}
    >
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleSelectChange}
      />
    </Form.Item>
  );
}
