import { Flex, Form } from "antd";

import Text from "core/lib/Text/Text";
import FileTypeIcon from "core/shared/components/FileTypeIcon";
import { ITask } from "core/app/api/task/interfaces";
import { useGetTaskDocumentsQuery } from "core/app/api/task";

import "./styles.scss";

export interface IDocumentPacks {
  task: ITask;
}

function DocumentPacks({ task }: IDocumentPacks) {
  const hasEquipment = Boolean(task.equipment_id);
  const hasTransport = Boolean(task.transport_id);
  const hasEquipmentType = Boolean(task.equipment_type_id);
  const hasEquipmentDocuments = hasEquipment || hasTransport || hasEquipmentType;

  const getDocumentPacksQueryParams = () => {
    if (hasEquipment) {
      return { equipment: task.equipment_id };
    }

    if (hasTransport) {
      return { transport: task.transport_id };
    }

    return { equipment_type: task.equipment_type_id };
  };

  const { data: equipmentDocuments } = useGetTaskDocumentsQuery(getDocumentPacksQueryParams(), {
    skip: !hasEquipmentDocuments,
  });

  const fileObjects = equipmentDocuments?.map((document) => document.file_objects).flat();
  if (!hasEquipmentDocuments || !fileObjects?.length) return null;
  return (
    <Form.Item label={<Text size="label2">ДОКУМЕНТЫ ПО ТИПУ ОБОРУДОВАНИЯ</Text>}>
      <Flex gap={8} wrap className="task-media-document-list">
        {fileObjects?.map((file) => (
          <Flex style={{ cursor: "pointer", width: "100px" }} vertical align="center" justify="flex-start">
            <a href={file.file} rel="noreferrer" target="_blank">
              <div className="task-media-document">
                <FileTypeIcon originalName={file.name} mimeType="txt" size={16} />
              </div>
            </a>
            <Text color="gray2" size="label3">
              {file.name}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Form.Item>
  );
}
export default DocumentPacks;
