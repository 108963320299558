import { ReactElement } from "react";
import { Row, Col } from "antd";

import { IGroup, isGroup } from "core/shared/dashboard";
import { useTaskId } from "core/shared/task/hooks/useTaskId";
import { IDashboardShiftStatisticTasksByTeamsPspaTask } from "core/app/api/units/PSPA/interfaces";
import { TaskTypesIdsEnum } from "core/app/api/task/enums";

interface ICellProps {
  record: IDashboardShiftStatisticTasksByTeamsPspaTask | IGroup<IDashboardShiftStatisticTasksByTeamsPspaTask>;
}

const styles = {
  cursor: "pointer",
  color: "#1890ff",
};

function Equipment({ record }: ICellProps): ReactElement {
  const [, setTaskId] = useTaskId();

  if (isGroup(record)) {
    return <div>{record.name}</div>;
  }
  let result = "Не выбрано";
  if (record.type === TaskTypesIdsEnum.OTHER && record.work_type) {
    result = record.work_type;
  }
  if (record.is_mounted && record.transport) {
    result = record.transport;
  }
  if (!record.is_mounted && record.equipment) {
    result = record.equipment;
  }

  const handleShowTask = async () => {
    setTaskId(record.id);
  };

  return (
    <Row onClick={handleShowTask}>
      <Col style={styles}>{result}</Col>
    </Row>
  );
}

export default Equipment;
