/** @jsxImportSource @emotion/react */
import { Row, Col } from "antd";

import { RecoveryTasksNotificationEventEnum } from "core/app/api/task/recovery/enums";
import { IRecoveryTasksNotification } from "core/app/api/task/recovery/interfaces";
import Text from "core/lib/Text/Text";
import TaskUrgency from "core/widgets/TaskUrgency";

interface TitleProps {
  notification: IRecoveryTasksNotification;
  count: number;
}

function Title({ count, notification }: TitleProps) {
  const { task } = notification;
  return (
    <div css={{ marginRight: 30 }}>
      <Row gutter={16} justify="space-between">
        <Col>
          <Row gutter={16}>
            <Col style={{ alignSelf: "center" }}>
              <TaskUrgency requestPriority={task.priority} />
            </Col>
            <Col>
              {notification.event === RecoveryTasksNotificationEventEnum.STATE_CHANGE_TO_WORK ? (
                <Text boldness="black" size="body1">
                  Заявка переведена в работу
                </Text>
              ) : (
                <Text boldness="black" size="body1">
                  Новая АВР заявка
                </Text>
              )}
              <br />
              <Text size="label3" boldness="semibold" css={{ color: "#0077E2" }}>
                {task.number}
              </Text>
              <Text size="label3" boldness="black">
                &nbsp; {task.priority_label?.toUpperCase()}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col>
          <Text boldness="black" size="body1">
            ({count})
          </Text>
        </Col>
      </Row>
    </div>
  );
}

export { Title };
