import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";
import { OossaSystemTypeEnum } from "core/app/api/units/OOSSA/enums";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
  label?: string;
}

/** Атрибут "Балансодержатель" в карточке ТО. */
function CustomerDepartment({ task, label }: FieldProps): ReactElement {
  const defaultLabel = task.system?.code_enum === OossaSystemTypeEnum.SMZIS ? "РОКС" : "Балансодержатель";
  return <Field label={label || defaultLabel} value={task.customer_department?.name} />;
}

export { CustomerDepartment };
