import { useRef, MutableRefObject, useCallback, useState } from "react";
import { Row, Col, Button, message, Space } from "antd";
import SignatureCanvas from "react-signature-canvas";

import Text from "core/lib/Text/Text";
import { IEtoTask } from "core/app/api/task/eto/interfaces";
import { useCreateEtoStepDriverSignatureMutation, useCreateEtoStepMechanicSignatureMutation } from "core/app/api";
import { dataUrlToFile } from "core/shared/tools/dataUrlToFile";
import { useAssistanceCarMaintenanceContext } from "core/widgets/AssistanceCarTaskModal/hooks/useAssistanceCarMaintenanceContext";
import { FormPagesEnum } from "core/widgets/AssistanceCarTaskModal/AssistanceCarMaintenanceContext";
import { useCurrentStep } from "core/widgets/AssistanceCarTaskModal/hooks/useCurrentStep";

import { EtoTaskSignatureSideEnum } from "../../interfaces";

import ElectricianSelect from "./ElectricianSelect";
import MachinistSelect from "./MachinistSelect";

interface SignatureFormProps {
  task: IEtoTask;
}

function SignatureForm({ task }: SignatureFormProps) {
  const step = useCurrentStep();
  const { signatureSide, setPage, setSignatureSide } = useAssistanceCarMaintenanceContext();
  const [electricianId, setElectricianId] = useState<number | null>(null);
  const [machinistId, setMachinistId] = useState<number | null>(null);
  const [createElectricianSignature, createElectricianSignatureResult] = useCreateEtoStepMechanicSignatureMutation();
  const [createDriverSignature, createDriverSignatureResult] = useCreateEtoStepDriverSignatureMutation();

  const canvasRef = useRef() as MutableRefObject<SignatureCanvas>;

  const handleClear = () => {
    canvasRef.current.clear();
  };

  const handleSendSignature = useCallback(async () => {
    if (canvasRef?.current && signatureSide && step) {
      const dataUrl = canvasRef.current.toDataURL();
      const file = await dataUrlToFile(dataUrl, "signature");
      const { id } = step;
      const body = new FormData();
      body.append("file", file);

      const handleSuccess = () => {
        setPage(FormPagesEnum.TASK);
        setSignatureSide(null);
      };
      const handleError = (e: Error) => {
        message.error(`При отправке подписи произошла ошибка`);
        console.log(e);
      };

      if (signatureSide === EtoTaskSignatureSideEnum.SENIOR_ELECTRICIAN && electricianId) {
        body.append("mechanic", String(electricianId));
        createElectricianSignature({ id, body, taskId: task.id }).unwrap().then(handleSuccess).catch(handleError);
      }
      if (signatureSide === EtoTaskSignatureSideEnum.MACHINIST && machinistId) {
        body.append("driver", String(machinistId));
        createDriverSignature({ id, body, taskId: task.id }).unwrap().then(handleSuccess).catch(handleError);
      }
    }
  }, [
    createElectricianSignature,
    createDriverSignature,
    step,
    signatureSide,
    setPage,
    setSignatureSide,
    electricianId,
    machinistId,
    task.id,
  ]);

  return (
    <Space className="w-100" direction="vertical" size="small">
      <Row justify="center">
        <Col>
          {signatureSide === EtoTaskSignatureSideEnum.SENIOR_ELECTRICIAN && (
            <Text size="h4" boldness="regular">
              ВЫПУСКАЮЩИЙ ЭЛЕКТРОМЕХАНИК
            </Text>
          )}
          {signatureSide === EtoTaskSignatureSideEnum.MACHINIST && (
            <Text size="h4" boldness="regular">
              МАШИНИСТ
            </Text>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {signatureSide === EtoTaskSignatureSideEnum.SENIOR_ELECTRICIAN && (
            <ElectricianSelect value={electricianId} onChange={setElectricianId} />
          )}
          {signatureSide === EtoTaskSignatureSideEnum.MACHINIST && (
            <MachinistSelect value={machinistId} onChange={setMachinistId} />
          )}
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button size="small" onClick={handleClear}>
            ОЧИСТИТЬ
          </Button>
        </Col>
      </Row>
      <Row className="mt-1 mb-1">
        <Col
          span={24}
          style={{
            border: "1px solid #d9d9d9",
          }}
        >
          <div>
            {!!canvasRef && (
              <SignatureCanvas
                ref={canvasRef}
                maxWidth={5}
                penColor="#0000FF"
                canvasProps={{ width: 648, height: 456, className: "sigCanvas" }}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row gutter={36} className="mt-3">
        <Col span={12}>
          <Button
            style={{ width: "100%" }}
            onClick={() => {
              setPage(FormPagesEnum.TASK);
              setSignatureSide(null);
            }}
          >
            ОТМЕНИТЬ
          </Button>
        </Col>
        <Col span={12}>
          <Button
            loading={createElectricianSignatureResult.isLoading || createDriverSignatureResult.isLoading}
            style={{ width: "100%" }}
            type="primary"
            onClick={handleSendSignature}
          >
            СОХРАНИТЬ
          </Button>
        </Col>
      </Row>
    </Space>
  );
}

export { SignatureForm };
