import { ReactElement } from "react";

import { TPlanFactStatistic } from "core/shared/dashboard/hooks/interfaces";
import { formattedSafePercent } from "core/shared/tools/safePercent";

interface ICellProps {
  value: TPlanFactStatistic;
  baseValue?: TPlanFactStatistic;
  isMounted?: boolean;
}

function Cell({ value, baseValue, isMounted = false }: ICellProps): ReactElement {
  const val = value.find((item) => item.is_mounted === isMounted);
  const baseVal = baseValue?.find((item) => item.is_mounted === isMounted) ?? val;
  return (
    <span>
      {val?.amount} {formattedSafePercent(val?.amount ?? 0, baseVal?.amount ?? 0)}
      {val?.repeated_amount !== undefined && ` / ${val.repeated_amount}`}
    </span>
  );
}

export { Cell };
