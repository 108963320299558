import { Tag } from "antd";

import { IDashboardShiftStatisticTasksByTeamsOossaTask } from "core/app/api/units/OOSSA/interfaces";
import { IGroup, isGroup } from "core/shared/dashboard";
import { OOSSA_SYSTEMS_TAG_COLORS } from "core/shared/oossa";

interface CellProps {
  record: IDashboardShiftStatisticTasksByTeamsOossaTask | IGroup<IDashboardShiftStatisticTasksByTeamsOossaTask>;
}
export function OossaSystem({ record }: CellProps) {
  if (isGroup(record)) {
    return <div />;
  }

  return <Tag color={OOSSA_SYSTEMS_TAG_COLORS[record.code_enum]}>{record.system}</Tag>;
}
