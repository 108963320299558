import qs, { IStringifyOptions } from "qs";

import { IEmployee } from "core/app/store/employee-slice";
import { baseUrl } from "core/shared/constants";
import { IUnit } from "core/shared/unit/types";
import { IDictionaryItem, IResponseBody, TQueryProps, TQueryPropsWithStringifyOptions } from "core/shared/interfaces";
import {
  ICredentials,
  IPermission,
  IPermissionResponseBody,
  IUiPermission,
  IUiPermissionBodyPayload,
  IWhoami,
} from "core/shared/permissions/interfaces";
import {
  ILeadsRating,
  IPerfomanceRatingHallOfFame,
  IPerfomanceRatingTasksByShift,
  IPerfomanceRatingTree,
} from "core/shared/rating/hooks/interfaces";
import {
  ITasksStatisticsResponse,
  ITasksStatisticsByShift,
  ITasksStatisticsByTeam,
} from "core/shared/dashboard/hooks/interfaces";

import { ITeamCopyModalState } from "../store/team-copy-modal-slice";

import { TWorkType } from "./types";
import { ApiSliceEnum } from "./enums";
import { IAddress, ICustomerDepartment, ILocation, ISystemInterface, IVideoHelp, IWorkRegime } from "./interfaces";
import {
  IAvailableShiftPlanStatusesQueryPayload,
  IClosingCode,
  IDelayReason,
  IGetTasksQueryPayload,
  IGroupTasks,
  IItsmServiceOfferinge,
  IPrintTasksFormProps,
  ITask,
  IUnitPreferences,
} from "./task/interfaces";
import {
  ICreateEtoMonthPlansPayload,
  ICreateEtoStepSignaturePayload,
  IEtoMonthPlan,
  IEtoMonthPlanStat,
  IEtoTask,
  IUpdateEtoMonthPlanPayload,
  IUpdateEtoStepPayload,
} from "./task/eto/interfaces";
import { ITeamTasks, IUnitDepartment } from "./team/interfaces";
import {
  IEquipment,
  IEquipmentCommentJournalItem,
  IEquipmentLastProblem,
  IEquipmentsTreeItem,
  IEquipmentType,
} from "./equipment/interfaces";
import { IEmployeeSignature, IGetTransportStatPayload, ITransport } from "./transport/interfaces";
import {
  IAvailableShiftPlanStatuses,
  IChangeCreateVacationOrSickLeaveDaysPayload,
  IChangeShiftPlanStatusPayload,
  IExtendPreviousMonthQueryPayload,
  IScheduleStatsEmployees,
  IShiftPlan,
  IUnitShift,
  IWorkingRhythm,
} from "./shift/interfaces";
import {
  IDailySummaryReport,
  IDailySummaryReportExist,
  ITeamsShiftTasksReportQueryProps,
  IUpdateDailySummaryReportBody,
} from "./reports/interfaces";
import { emptyApiSlice, extractResults } from "./emptyApi";
import { getFileNameFromContentDisposition } from "./tools";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractShifts = (responseData: any) => responseData[0]?.details;

/** Обращение к API бэкенда за ручками общего назначения. */
export const apiSlice = emptyApiSlice
  .enhanceEndpoints({
    addTagTypes: [
      "dashboard/shift-statistic",
      "dashboard/shift-statistic/tasks",
      "dashboard/shift-statistic/tasks/by-equipment-types",
      "dashboard/shift-statistic/tasks/by-objects/slpi",
      "teams",
      "teams/teams-to-copy",
      "team",
      "shifttask",
      "shift-plan",
      "shift-plan/permissions",
      "shift-plan/available-statuses",
      "task",
      "task-attachment",
      "tasks",
      "units",
      "unit",
      "tasks-filtered",
      "year-plan",
      "month-plan",
      "employees",
      "month-plan/check",
      "month-plan/get_initial_plan_period",
      "scheduled-task-count",
      "transport/get_required_task_count",
      "transport/maintenance",
      "kanban-board",
      "locations",
      "location",
      "repair-areas",
      "systems",
      "system",
      "equipments",
      "horizons",
      "month-schedule",
      "creatio-states",
      "group-tasks",
      "tasks/statistics-by-days",
      "open-tasks",
      "tasks/statistics-by-shifts",
      "tasks/statistics-by-teams",
      "unit-shifts",
      "working-rhythm",
      "month-plan/permission",
      "month-schedule/available-employees",
      "month-schedule/available-locations",
      "need-create-recovery-tasks",
      "month-plan/recovery-tasks-layer",
      "addresses",
      "address",
      "year-plan/transports-stats",
      "auth/permissions",
      "customers",
      "itsm-groups",
      "equipments/tree",
      "equipment",
      "equipments/maintenance",
      "equipments/maintenance-journal",
      "equipments/comment-journal",
      "services",
      "video-help",
      "perfomance-rating",
      "perfomance-rating/average-shift-tasks-quantity",
      "perfomance-rating/hall-of-fame",
      "report/perfomance-rating/rating-tree",
      "report/perfomance-rating/tasks-by-shift",
      "report/perfomance-rating/team-leads-rating",
      "report/perfomance-rating/shift-leads-rating",
      "customer-representatives",
      "positions",
      "unit-preferences",
      "notifications/tasks",
      "ui-elements/ui-elements-permissions",
      "user-settings/task-notifications",
      "work-types",
      "auth/whoami",
      "units/work-regimes",
      "itsm-service-offerings",
      "report/daily-summary",
      "report/daily-summary-is-exist",
      "customer-departments",
      "unit-departments",
      "customer-department",
      "approve-month-plan",
      "manufacturers",
      "performance-rating/useful-time-factor",
      "matomo-environment",
      "schedule-stats-employees",
      "eto-month-plans/eto-statistic",
      "eto-month-plans",
      "maintenance-types/maintenance-cycle",
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getEmployees: builder.query({
        query: (queryString: string) => `employees/?${queryString}`,
        providesTags: ["employees"],
      }),
      getUnits: builder.query<IUnit[], string>({
        query: () => "units/",
        providesTags: ["units"],
        transformResponse: extractResults,
      }),
      getUnit: builder.query<IUnit, number | string>({
        query: (id) => `units/${id}/`,
        providesTags: ["unit"],
      }),
      getTasks: builder.query<IResponseBody<ITask>, TQueryPropsWithStringifyOptions>({
        query: ({ url, stringifyOptions, ...rest }) => {
          const queryString = qs.stringify(rest, stringifyOptions as IStringifyOptions);
          if (url) {
            return `${url}/?${queryString}`;
          }
          return `tasks/?${queryString}`;
        },
        providesTags: ["tasks"],
      }),
      getOpenTasks: builder.query<any, string | IGetTasksQueryPayload>({
        query: (payload) => {
          if (typeof payload === "string") {
            return `tasks/?${payload}`;
          }
          return `${payload.url}/?${payload.queryProps}`;
        },
        providesTags: ["open-tasks"],
      }),
      getEtoTasks: builder.query<IResponseBody<IEtoTask>, string | undefined>({
        query: (queryProps) => `eto-tasks/?${queryProps}`,
        providesTags: ["open-tasks"],
      }),
      getTasksFiltered: builder.query<IResponseBody<ITask>, string>({
        query: (queryString: string) => `tasks/?${queryString}`,
        providesTags: ["tasks-filtered"],
      }),
      getShiftTasks: builder.query<ITeamTasks[], string>({
        query: (queryString: string) => `shifttask/?${queryString}`,
        providesTags: ["shifttask"],
        transformResponse: extractResults,
      }),
      getEquipmentTypes: builder.query<IResponseBody<IEquipmentType>, string>({
        query: (queryString: string) => `equipment-types/?${queryString}`,
      }),
      getEquipmentType: builder.query<IEquipmentType, string | number>({
        query: (id: string | number) => `equipment-types/${id}/`,
      }),
      getItsmEquipmentTypes: builder.query<IResponseBody<IDictionaryItem>, string>({
        query: (queryString: string) => `itsm-equipment-types/?${queryString}`,
      }),
      getItsmEquipment: builder.query<IDictionaryItem[], string>({
        query: (queryString: string) => `itsm-equipment-type-elements/?limit=1000&${queryString}`,
        transformResponse: extractResults,
      }),
      getItsmEquipmentMalfunction: builder.query<IDictionaryItem[], string>({
        query: (queryString: string) => `itsm-equipment-type-malfunction/?limit=1000&${queryString}`,
        transformResponse: extractResults,
      }),
      getItsmEquipmentMalfunctionReason: builder.query<IDictionaryItem[], string>({
        query: (queryString: string) => `itsm-equipment-type-malfunction-reason/?${queryString}`,
        transformResponse: extractResults,
      }),
      getItsmDelayReasons: builder.query<IDelayReason[], undefined>({
        query: () => `itsm-delay-reasons/?is_deactivated=false`,
        transformResponse: extractResults,
      }),
      getItsmClosingCodes: builder.query<IClosingCode[], string>({
        query: (queryString: string) => `itsm-closing-codes/?${queryString}`,
        transformResponse: extractResults,
      }),
      getTransportStat: builder.mutation({
        query: (payload: IGetTransportStatPayload) => ({
          url: `transport/stat/`,
          method: "POST",
          body: payload,
        }),
      }),
      monthPlanFindOrCreateTask: builder.mutation<{ task_id: number | null }, any>({
        query: (payload) => ({
          url: `month-plan/find-or-create-task/`,
          method: "POST",
          body: payload,
        }),
      }),
      getTransport: builder.query<IResponseBody<ITransport>, string>({
        query: (queryString: string) => `transport/?${queryString}`,
      }),
      getScheduledTaskCount: builder.query({
        query: (queryString: string) => `month-plan/get_scheduled_task_count/?${queryString}`,
        providesTags: ["scheduled-task-count"],
      }),
      getRequiredTaskCount: builder.query({
        query: (queryString: string) => `transport/get_required_task_count/?${queryString}`,
        providesTags: ["transport/get_required_task_count"],
      }),
      getKanbanBoard: builder.query({
        query: (teamId: string | number) => `kanban-board/${teamId}`,
        providesTags: ["kanban-board"],
      }),
      getLocations: builder.query<IResponseBody<ILocation>, string>({
        query: (queryString: string) => `locations/?${queryString}`,
        providesTags: ["locations"],
      }),
      getLocation: builder.query<ILocation, number>({
        query: (id) => `locations/${id}/`,
        providesTags: ["location"],
      }),
      getSystems: builder.query<ISystemInterface[], string>({
        query: (queryString: string) => `systems/?${queryString}`,
        providesTags: ["systems"],
        transformResponse: extractResults,
      }),
      getSystem: builder.query<ISystemInterface, number>({
        query: (id) => `systems/${id}/`,
        providesTags: ["system"],
      }),
      getEquipments: builder.query<IResponseBody<IEquipment>, string>({
        query: (queryString: string) => `equipments/?${queryString}`,
        providesTags: ["equipments"],
      }),
      getEquipment: builder.query<IEquipment, string>({
        query: (id: string) => `equipments/${id}`,
        providesTags: ["equipment"],
      }),
      getEquipmentLastProblem: builder.query<IEquipmentLastProblem, number>({
        query: (id: number) => `equipments/${id}/last-problem`,
        providesTags: ["equipment"],
      }),
      getEquipmentsLastProblems: builder.query<Record<string, IEquipmentLastProblem>, string>({
        query: (idsCommaSeparated: string) => `equipments/last-problems/?equipments=${idsCommaSeparated}`,
        providesTags: ["equipment"],
      }),
      getHorizons: builder.query({
        query: (queryString: string) => `horizons/?${queryString}`,
        providesTags: ["horizons"],
      }),
      createToken: builder.mutation({
        query: (payload: ICredentials) => ({
          url: `auth/jwt/create/`,
          method: "POST",
          body: payload,
        }),
      }),
      getActByPeriod: builder.mutation({
        query(queryString: string) {
          return {
            url: `tasks/print-act-by-period/?${queryString}`,
            method: "GET",
            responseHandler: async (response) =>
              window.location.assign(window.URL.createObjectURL(await response.blob())),
            cache: "no-cache",
          };
        },
      }),
      getAct: builder.mutation({
        query(taskId: number) {
          return {
            url: `tasks/${taskId}/print-act/`,
            method: "GET",
            responseHandler: async (response) => {
              const disposition = await response.headers.get("content-disposition");
              const fileName = getFileNameFromContentDisposition(disposition);
              const fileLink = document.createElement("a");
              fileLink.href = window.URL.createObjectURL(await response.blob());
              fileLink.download = fileName ?? `report_${taskId}`;
              fileLink.click();
            },
            cache: "no-cache",
          };
        },
      }),
      getMedia: builder.query({
        query(arg: { taskId: number; attachmentId: number }) {
          return {
            url: `/tasks/${arg.taskId}/attachments/${arg.attachmentId}/download/`,
            method: "GET",
            responseHandler: async (response) => {
              const fileLink = document.createElement("a");
              fileLink.href = window.URL.createObjectURL(await response.blob());
              return fileLink.href;
            },
          };
        },
        providesTags: ["task-attachment"],
      }),
      getMonthSchedule: builder.query({
        query: (queryString: string) => `month-schedule/?${queryString}`,
        providesTags: ["month-schedule"],
      }),
      getGroupTasks: builder.query<IGroupTasks[], string>({
        query: (queryString: string) => `tasks/group-tasks/?${queryString}`,
        providesTags: ["group-tasks"],
      }),
      getTasksStatisticsByDays: builder.query<ITasksStatisticsResponse, string>({
        query: (queryString: string) => `tasks/statistics-by-days/?${queryString}`,
        providesTags: ["tasks/statistics-by-days"],
      }),
      getTasksStatisticsByShifts: builder.query<ITasksStatisticsByShift[], string>({
        query: (queryString: string) => `tasks/statistics-by-shifts/?${queryString}`,
        providesTags: ["tasks/statistics-by-shifts"],
      }),
      getTasksStatisticsByTeams: builder.query<ITasksStatisticsByTeam[][], string>({
        query: (queryString: string) => `tasks/statistics-by-teams/?${queryString}`,
        providesTags: ["tasks/statistics-by-teams"],
      }),
      createMonthSchedule: builder.mutation({
        query(body) {
          return {
            url: `month-schedule/`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["month-schedule", "employees"],
      }),
      getUnitShifts: builder.query<IUnitShift[], string>({
        query: (queryString: string) => `unit-shifts/?${queryString}`,
        providesTags: ["unit-shifts"],
        transformResponse: extractResults,
      }),
      getWorkingRhythm: builder.query<IResponseBody<IWorkingRhythm>, string>({
        query: (queryString: string) => `working-rhythm/?${queryString}`,
        providesTags: ["working-rhythm"],
      }),
      createGroup: builder.mutation({
        query(body) {
          return {
            url: `/month-schedule/${body.monthScheduleId}/groups/`,
            method: "POST",
            body: { group: body.group },
          };
        },
        invalidatesTags: [
          "month-schedule",
          "month-schedule/available-locations",
          "month-schedule/available-employees",
          "employees",
          "schedule-stats-employees",
        ],
      }),
      updateGroup: builder.mutation({
        query(body) {
          return {
            url: `/month-schedule/${body.monthScheduleId}/groups/${body.groupId}/`,
            method: "PATCH",
            body: { group: body.group },
          };
        },
        invalidatesTags: [
          "month-schedule",
          "month-schedule/available-locations",
          "month-schedule/available-employees",
          "employees",
          "schedule-stats-employees",
        ],
      }),
      deleteGroup: builder.mutation({
        query(body: { monthScheduleId: number; groupId: number }) {
          return {
            url: `/month-schedule/${body.monthScheduleId}/groups/${body.groupId}/`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["month-schedule", "employees", "schedule-stats-employees"],
      }),
      getMonthPlanPermission: builder.query<IPermissionResponseBody, string>({
        query: (queryString: string) => `month-plan/permission/?${queryString}`,
        providesTags: ["month-plan/permission"],
      }),
      getMontScheduleAvailableEmployees: builder.query<IResponseBody<IEmployee>, string>({
        query: (monthScheduleId: string) => `month-schedule/${monthScheduleId}/available-employees/`,
        providesTags: ["month-schedule/available-employees"],
      }),
      getMontScheduleAvailableLocations: builder.query<IResponseBody<IDictionaryItem>, string>({
        query: (monthScheduleId: string) => `month-schedule/${monthScheduleId}/available-locations/`,
        providesTags: ["month-schedule/available-locations"],
      }),
      createGroupScheduledDay: builder.mutation({
        query: (payload) => ({
          url: `month-schedule/${payload.monthScheduleId}/groups/${payload.groupId}/create_scheduled_day/`,
          method: "POST",
          body: payload.body,
        }),
        invalidatesTags: ["month-schedule", "employees"],
      }),
      deleteGroupScheduledDay: builder.mutation({
        query: (payload) => ({
          url: `month-schedule/${payload.monthScheduleId}/groups/${payload.groupId}/delete_scheduled_day/`,
          method: "POST",
          body: payload.body,
        }),
        invalidatesTags: ["month-schedule", "employees"],
      }),
      createEmployeeScheduledDay: builder.mutation({
        query: (payload) => ({
          url: `month-schedule/${payload.monthScheduleId}/groups/${payload.groupId}/employees/${payload.employeeId}/create_scheduled_day/`,
          method: "POST",
          body: payload.body,
        }),
        invalidatesTags: ["month-schedule", "employees"],
      }),
      deleteEmployeeScheduledDay: builder.mutation({
        query: (payload) => ({
          url: `month-schedule/${payload.monthScheduleId}/groups/${payload.groupId}/employees/${payload.employeeId}/delete_scheduled_day/`,
          method: "POST",
          body: payload.body,
        }),
        invalidatesTags: ["month-schedule", "employees"],
      }),
      getAddresses: builder.query<IAddress[], string>({
        query: (queryString: string) => `addresses/?${queryString}`,
        providesTags: ["addresses"],
        transformResponse: extractResults,
      }),
      getAddress: builder.query<IAddress, number>({
        query: (id) => `addresses/${id}/`,
        providesTags: ["address"],
      }),
      getPermissions: builder.query<IPermission, string>({
        query: (queryString: string) => `auth/permissions/?${queryString}`,
        providesTags: ["auth/permissions"],
      }),
      getShiftPlan: builder.query<IShiftPlan[], string>({
        query: (queryString: string) => `/shift-plan/?${queryString}`,
        providesTags: ["shift-plan"],
      }),
      getAvailableShiftPlanStatuses: builder.query<
        IAvailableShiftPlanStatuses,
        IAvailableShiftPlanStatusesQueryPayload
      >({
        query(payload: IAvailableShiftPlanStatusesQueryPayload) {
          const queryString = qs.stringify(payload);
          return `/shift-plan/available-statuses/?${queryString}`;
        },
        providesTags: ["shift-plan/available-statuses"],
      }),
      getShiftPlanPermissions: builder.query<string[], string>({
        query: (queryString: string) => `/shift-plan/permissions/?${queryString}`,
        providesTags: ["shift-plan/permissions"],
      }),
      changeShiftPlanStatus: builder.mutation({
        query: (payload: IChangeShiftPlanStatusPayload) => ({
          url: `shift-plan/change-status/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: [
          "shift-plan",
          "dashboard/shift-statistic/tasks",
          "dashboard/shift-statistic",
          "task",
          "shift-plan/available-statuses",
        ],
      }),
      getCustomers: builder.query<IDictionaryItem[], string>({
        query: (queryString: string) => `/customer/?${queryString}`,
        providesTags: ["customers"],
        transformResponse: extractResults,
      }),
      getResponsibleGroups: builder.query<IDictionaryItem[], string>({
        query: (queryString: string) => `itsm-groups/?${queryString}`,
        providesTags: ["itsm-groups"],
        transformResponse: extractResults,
      }),
      getEquipmentsTree: builder.query<IEquipmentsTreeItem[], string>({
        query: (queryString: string) => `equipments/tree/?${queryString}`,
        providesTags: ["equipments/tree"],
      }),
      getEquipmentCommentJournal: builder.query<IEquipmentCommentJournalItem[], string>({
        query: (queryString: string) => `equipments/${queryString}/comment-journal/`,
        providesTags: ["equipments/comment-journal"],
      }),
      getServices: builder.query<IDictionaryItem[], string>({
        query: (queryString: string) => `services/?${queryString}`,
        providesTags: ["services"],
        transformResponse: extractResults,
      }),
      getVideoHelp: builder.query<IVideoHelp[], string>({
        query: () => `video-help/`,
        providesTags: ["video-help"],
        transformResponse: extractResults,
      }),
      getVideoHelpFile: builder.mutation({
        query(id: number) {
          return {
            url: `/video-help/${id}/download/`,
            method: "GET",
            responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
          };
        },
      }),
      createVacationOrSickLeaveDays: builder.mutation<string, IChangeCreateVacationOrSickLeaveDaysPayload>({
        query(payload: IChangeCreateVacationOrSickLeaveDaysPayload) {
          const { monthScheduleId, groupId, id, startDate, endDate, scheduleDayType } = payload;
          return {
            url: `month-schedule/${monthScheduleId}/groups/${groupId}/employees/${id}/create_other_types_days/`,
            method: "POST",
            body: {
              start_date: startDate,
              end_date: endDate,
              day_type: scheduleDayType,
            },
          };
        },
        invalidatesTags: ["month-schedule"],
      }),
      getPrintTasksForm: builder.query<string, IPrintTasksFormProps>({
        query(props: IPrintTasksFormProps) {
          const { queryProps, unitsNames } = props;
          return {
            url: `tasks/print-tasks-form/?${qs.stringify(queryProps, { indices: false })}`,
            responseHandler: async (response) => {
              if (response.ok) {
                const fileLink = document.createElement("a");
                fileLink.href = window.URL.createObjectURL(await response.blob());
                fileLink.target = "blank";
                const unsafeFilename = `${unitsNames?.join(" ")} ${queryProps.date_from} - ${queryProps.date_to}`;
                fileLink.download = unsafeFilename.replace(".", "");
                fileLink.click();
              }
            },
          };
        },
      }),
      getPerfomanceRatingAverageShiftTasksQuantity: builder.query<number, TQueryProps>({
        query: ({ slice, ...rest }: TQueryProps) =>
          `report/performance-rating/${slice}-average-shift-tasks-quantity/?${qs.stringify(rest)}`,
        providesTags: ["perfomance-rating/average-shift-tasks-quantity"],
      }),
      getPerformanceRatingUsefulTimeFactor: builder.query<number, TQueryProps>({
        query: ({ slice, ...rest }: TQueryProps) =>
          `report/performance-rating/${slice}-useful-time-factor/?${qs.stringify(rest)}`,
        providesTags: ["performance-rating/useful-time-factor"],
      }),
      getPerfomanceRatingHallOfFame: builder.query<IPerfomanceRatingHallOfFame, TQueryProps>({
        query: ({ slice, ...rest }: TQueryProps) =>
          `report/performance-rating/${slice}-hall-of-fame/?${qs.stringify(rest)}`,
        providesTags: ["perfomance-rating/hall-of-fame"],
      }),
      getPerfomanceRatingTree: builder.query<IPerfomanceRatingTree[], TQueryProps>({
        query: ({ slice, ...rest }: TQueryProps) =>
          `report/performance-rating/${slice}-rating-tree/?${qs.stringify(rest)}`,
        providesTags: ["report/perfomance-rating/rating-tree"],
      }),
      getPerfomanceRatingTasksByShift: builder.query<IPerfomanceRatingTasksByShift[], TQueryProps>({
        query: ({ slice, ...rest }: TQueryProps) =>
          `report/performance-rating/${slice}-tasks-by-shift/?${qs.stringify(rest)}`,
        providesTags: ["report/perfomance-rating/tasks-by-shift"],
      }),
      getPerfomanceRatingTeamLeadsRating: builder.query<ILeadsRating[], TQueryProps>({
        query: ({ slice, ...rest }: TQueryProps) =>
          `report/performance-rating/${slice}-team-leads-rating/?${qs.stringify(rest)}`,
        providesTags: ["report/perfomance-rating/team-leads-rating"],
      }),
      getPerfomanceRatingShiftLeadsRating: builder.query<ILeadsRating[], TQueryProps>({
        query: ({ slice, ...rest }: TQueryProps) =>
          `report/performance-rating/${slice}-shift-leads-rating/?${qs.stringify(rest)}`,
        providesTags: ["report/perfomance-rating/shift-leads-rating"],
      }),
      getTasksMountedReport: builder.query<string, { unit: number }>({
        query(queryProps: { unit: number }) {
          return {
            url: `tasks/mounted-report/?${qs.stringify(queryProps)}`,
            responseHandler: async (response) => {
              if (response.ok) {
                const fileLink = document.createElement("a");
                fileLink.href = window.URL.createObjectURL(await response.blob());
                fileLink.target = "blank";
                const disposition = await response.headers.get("content-disposition");
                const fileName = getFileNameFromContentDisposition(disposition);
                fileLink.download = fileName ?? "report";
                fileLink.click();
              }
            },
          };
        },
      }),
      getTeamsShiftTasksReport: builder.query<string, ITeamsShiftTasksReportQueryProps>({
        query(queryProps: ITeamsShiftTasksReportQueryProps) {
          return {
            url: `teams/shift_tasks_report/?${qs.stringify(queryProps)}`,
            responseHandler: async (response) => {
              if (response.ok) {
                const fileLink = document.createElement("a");
                fileLink.href = window.URL.createObjectURL(await response.blob());
                fileLink.target = "blank";
                const disposition = await response.headers.get("content-disposition");
                const fileName = getFileNameFromContentDisposition(disposition);
                fileLink.download = fileName ?? "report";
                fileLink.click();
              }
            },
          };
        },
      }),
      getTeamsToCopy: builder.query<ITeamCopyModalState["teamsToCopy"], string>({
        query: (queryString) => `/teams/copy/?${queryString}`,
        providesTags: ["teams/teams-to-copy"],
      }),
      getUiPermissions: builder.query<IUiPermission[], string>({
        query: () => `/ui-elements/ui-elements-permissions/`,
        providesTags: ["ui-elements/ui-elements-permissions"],
        keepUnusedDataFor: 60,
      }),
      updateUiPermissions: builder.mutation<IUiPermission[], IUiPermissionBodyPayload[]>({
        query(payload: IUiPermissionBodyPayload[]) {
          return {
            url: "/ui-elements/is-user-allowed-to-use-this-ui-elements/",
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["ui-elements/ui-elements-permissions"],
      }),
      createUnitPreferences: builder.mutation<IUnitPreferences, Omit<IUnitPreferences, "id">>({
        query(payload) {
          return {
            url: `unit-preferences/`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["unit-preferences"],
      }),
      deleteUnitPreferences: builder.mutation<IUnitPreferences, number>({
        query(id) {
          return {
            url: `unit-preferences/${id}/`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["unit-preferences"],
      }),
      updateUnitPreferences: builder.mutation<IUnitPreferences, IUnitPreferences>({
        query(unitPreferences) {
          return {
            url: `unit-preferences/${unitPreferences.id}/`,
            method: "PUT",
            body: unitPreferences,
          };
        },
        invalidatesTags: ["unit-preferences"],
      }),
      getUnitPreferences: builder.query<IUnitPreferences[], Omit<IUnitPreferences, "id" | "name" | "preferences">>({
        query({ unit, key }) {
          return {
            url: `unit-preferences/?unit=${unit}&key=${key}`,
          };
        },
        providesTags: ["unit-preferences"],
      }),
      getWorkTypes: builder.query<TWorkType[], ApiSliceEnum | null>({
        query: (arg) => {
          switch (arg) {
            case ApiSliceEnum.lso:
              return "/lso-work-types";
            case ApiSliceEnum.lku:
              return "/lku-work-types";
            case ApiSliceEnum.vols:
              return "/vols-work-types";
            case ApiSliceEnum.slpi:
              return "/slpi-work-types";
            case ApiSliceEnum.pspa:
            case ApiSliceEnum.pspaKf:
              return "/work-types";
            case ApiSliceEnum.oossa:
              return "/oossa-work-types";

            default:
              throw new Error("Wrong api slice");
          }
        },
        providesTags: ["work-types"],
        transformResponse: (response: IResponseBody<TWorkType>, meta, arg) => response.results,
      }),
      getWhoami: builder.query<IWhoami, string>({
        query: () => `/auth/whoami/`,
        providesTags: ["auth/whoami"],
      }),
      getWorkRegimes: builder.query<IWorkRegime[], number>({
        query: (unitId: number) => `units/${unitId}/work-regimes/`,
        providesTags: ["units/work-regimes"],
        transformResponse: extractShifts,
      }),
      getItsmServiceOfferinge: builder.query<IItsmServiceOfferinge[], string>({
        query: () => `/itsm-service-offerings/`,
        providesTags: ["itsm-service-offerings"],
        transformResponse: extractResults,
      }),
      getDailySummaryReport: builder.query<IDailySummaryReport, string>({
        query: (queryString) => `/report/daily-summary?${queryString}`,
        providesTags: ["report/daily-summary"],
      }),
      getDailySummaryReportExist: builder.query<IDailySummaryReportExist, string>({
        query: (queryString) => `/report/daily-summary-is-exist?${queryString}`,
        providesTags: ["report/daily-summary-is-exist"],
      }),
      updateDailySummaryReport: builder.mutation<IDailySummaryReport, IUpdateDailySummaryReportBody>({
        query(payload) {
          return {
            url: `report/daily-summary`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["report/daily-summary"],
      }),
      extendPreviousMonth: builder.mutation<any, IExtendPreviousMonthQueryPayload>({
        query(payload) {
          return {
            url: `month-schedule/${payload.id}/extend-previous-month/`,
            method: "PUT",
            body: payload.prevMonth,
          };
        },
        invalidatesTags: ["month-schedule"],
      }),
      getCustomerDepartments: builder.query<ICustomerDepartment[], string>({
        query: (queryString) => `customer-departments/?${queryString}`,
        providesTags: ["customer-departments"],
        transformResponse: extractResults,
      }),
      getCustomerDepartment: builder.query<ICustomerDepartment, string | number>({
        query: (id) => `customer-departments/${id}/`,
        providesTags: ["customer-department"],
      }),
      getUnitDepartments: builder.query<IUnitDepartment[], string>({
        query: (queryString) => `unit-departments/?${queryString}`,
        providesTags: ["unit-departments"],
      }),
      getManufacturers: builder.query<IDictionaryItem[], void>({
        query: () => `manufacturers/`,
        providesTags: ["manufacturers"],
        transformResponse: extractResults,
      }),
      getMatomoEnvironment: builder.query<string, string>({
        query: () => `matomo-environment/`,
        providesTags: ["matomo-environment"],
      }),
      getScheduleStatsEmployees: builder.query<IScheduleStatsEmployees, string>({
        query: (queryString) => `schedule-stats-employees/?${queryString}`,
        providesTags: ["schedule-stats-employees"],
      }),
      getEtoMonthPlansStat: builder.query<IEtoMonthPlanStat[], string>({
        query: (queryString) => `eto-month-plans/eto-statistic/?${queryString}`,
        providesTags: ["eto-month-plans/eto-statistic"],
      }),
      updateEtoStep: builder.mutation<null, IUpdateEtoStepPayload>({
        query: ({ step }) => ({
          url: `eto-steps/${step.id}/`,
          method: "PATCH",
          body: {
            ...step,
          },
        }),
        invalidatesTags: (_result, _error, arg) => [{ type: "task", id: arg.taskId }],
      }),
      createEtoMonthPlans: builder.mutation<null, ICreateEtoMonthPlansPayload>({
        query: (payload) => ({
          url: `eto-month-plans/create-plans/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["eto-month-plans/eto-statistic", "eto-month-plans"],
      }),
      approveEtoMonthPlans: builder.mutation<null, ICreateEtoMonthPlansPayload>({
        query: (payload) => ({
          url: `eto-month-plans/approve/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["eto-month-plans/eto-statistic", "eto-month-plans"],
      }),
      undoApproveEtoMonthPlans: builder.mutation<null, ICreateEtoMonthPlansPayload>({
        query: (payload) => ({
          url: `eto-month-plans/undo-approve/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["eto-month-plans/eto-statistic", "eto-month-plans"],
      }),
      updateEtoMonthPlan: builder.mutation<null, IUpdateEtoMonthPlanPayload>({
        query: (payload) => {
          const { id, ...rest } = payload;
          return {
            url: `eto-month-plans/${id}/`,
            method: "PATCH",
            body: rest,
          };
        },
        invalidatesTags: ["eto-month-plans"],
      }),
      getEtoMonthPlans: builder.query<IEtoMonthPlan[], string>({
        query: (queryString) => `eto-month-plans/?${queryString}`,
        providesTags: ["eto-month-plans"],
        transformResponse: extractResults,
      }),
      makeScheduleEtoMonthPlans: builder.mutation<null, ICreateEtoMonthPlansPayload>({
        query: (payload) => ({
          url: `eto-month-plans/make-schedule/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["eto-month-plans"],
      }),
      createEtoStepDriverSignature: builder.mutation<{ id: number }, ICreateEtoStepSignaturePayload>({
        query: (payload) => {
          const { id, body } = payload;
          return {
            url: `eto-steps/${id}/driver-signature/`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: (result, error, payload) => [{ type: "task", id: payload.taskId }],
      }),
      createEtoStepMechanicSignature: builder.mutation<{ id: number }, ICreateEtoStepSignaturePayload>({
        query: (payload) => {
          const { id, body } = payload;
          return {
            url: `eto-steps/${id}/mechanic-signature/`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: (result, error, payload) => [{ type: "task", id: payload.taskId }],
      }),
      getEmployeeSignature: builder.query<IEmployeeSignature, number>({
        query: (id) => ({
          url: `employee-signatures/${id}/`,
          responseHandler: async (response) => {
            const data: IEmployeeSignature = await response.json();

            if (data.signature) {
              const signatureUrl = new URL(data.signature);
              data.signature = `${baseUrl}minio/${data.signature.slice(signatureUrl.origin.length + 1)}`;
            }

            return data;
          },
        }),
      }),
      // --------------------------------------------------------
      // Не добавляйте сюда специфические для сервисов функции!!!
      // Для этого есть oossaApiSlice и т.п.
      // Не добавляйте сюда специфические для задач и т.п. отделенные функции!!!
      // Для этого есть taskApiSlice и др.
      // Лучше уберите оставшиеся специфические функции отсюда в соответствующее место.
    }),
  });

export const {
  useGetEmployeesQuery,
  useGetUnitsQuery,
  useGetTasksQuery,
  useGetShiftTasksQuery,
  useGetEquipmentTypesQuery,
  useGetTasksFilteredQuery,
  useGetTransportStatMutation,
  useMonthPlanFindOrCreateTaskMutation,
  useGetTransportQuery,
  useGetScheduledTaskCountQuery,
  useGetRequiredTaskCountQuery,
  useGetKanbanBoardQuery,
  useGetLocationsQuery,
  useGetLocationQuery,
  useGetSystemsQuery,
  useGetSystemQuery,
  useGetEquipmentsQuery,
  useGetHorizonsQuery,
  useCreateTokenMutation,
  useGetActByPeriodMutation,
  useGetActMutation,
  useGetMonthScheduleQuery,
  useGetOpenTasksQuery,
  useGetGroupTasksQuery,
  useGetTasksStatisticsByDaysQuery,
  useGetTasksStatisticsByShiftsQuery,
  useGetTasksStatisticsByTeamsQuery,
  useCreateMonthScheduleMutation,
  useGetUnitShiftsQuery,
  useGetWorkingRhythmQuery,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useGetMonthPlanPermissionQuery,
  useGetMontScheduleAvailableEmployeesQuery,
  useUpdateGroupMutation,
  useGetMontScheduleAvailableLocationsQuery,
  useGetMediaQuery,
  useCreateGroupScheduledDayMutation,
  useDeleteGroupScheduledDayMutation,
  useCreateEmployeeScheduledDayMutation,
  useDeleteEmployeeScheduledDayMutation,
  useGetItsmClosingCodesQuery,
  useGetItsmDelayReasonsQuery,
  useGetItsmEquipmentTypesQuery,
  useGetItsmEquipmentQuery,
  useGetItsmEquipmentMalfunctionQuery,
  useGetItsmEquipmentMalfunctionReasonQuery,
  useGetAddressesQuery,
  useGetAddressQuery,
  useGetPermissionsQuery,
  useGetShiftPlanQuery,
  useChangeShiftPlanStatusMutation,
  useGetCustomersQuery,
  useGetResponsibleGroupsQuery,
  useGetUnitQuery,
  useGetEquipmentsTreeQuery,
  useGetEquipmentQuery,
  useGetEquipmentLastProblemQuery,
  useGetEquipmentsLastProblemsQuery,
  useGetEquipmentCommentJournalQuery,
  useGetEquipmentTypeQuery,
  useGetShiftPlanPermissionsQuery,
  useGetServicesQuery,
  useGetVideoHelpQuery,
  useGetVideoHelpFileMutation,
  useCreateVacationOrSickLeaveDaysMutation,
  useGetPrintTasksFormQuery,
  useLazyGetPrintTasksFormQuery,
  useGetPerfomanceRatingAverageShiftTasksQuantityQuery,
  useGetPerfomanceRatingHallOfFameQuery,
  useGetPerfomanceRatingTreeQuery,
  useGetPerfomanceRatingTasksByShiftQuery,
  useGetPerfomanceRatingTeamLeadsRatingQuery,
  useGetPerfomanceRatingShiftLeadsRatingQuery,
  useLazyGetTasksMountedReportQuery,
  useLazyGetTeamsShiftTasksReportQuery,
  useCreateUnitPreferencesMutation,
  useGetUnitPreferencesQuery,
  useDeleteUnitPreferencesMutation,
  useUpdateUnitPreferencesMutation,
  useGetWorkTypesQuery,
  useGetWhoamiQuery,
  useGetWorkRegimesQuery,
  useGetItsmServiceOfferingeQuery,
  useGetDailySummaryReportQuery,
  useUpdateDailySummaryReportMutation,
  useGetDailySummaryReportExistQuery,
  useLazyGetDailySummaryReportQuery,
  useGetAvailableShiftPlanStatusesQuery,
  useGetUiPermissionsQuery,
  useUpdateUiPermissionsMutation,
  useExtendPreviousMonthMutation,
  useGetCustomerDepartmentsQuery,
  useGetUnitDepartmentsQuery,
  useGetCustomerDepartmentQuery,
  useGetManufacturersQuery,
  useGetPerformanceRatingUsefulTimeFactorQuery,
  useGetMatomoEnvironmentQuery,
  useGetScheduleStatsEmployeesQuery,
  useUpdateEtoStepMutation,
  useGetEtoMonthPlansStatQuery,
  useCreateEtoMonthPlansMutation,
  useGetEtoMonthPlansQuery,
  useApproveEtoMonthPlansMutation,
  useUndoApproveEtoMonthPlansMutation,
  useUpdateEtoMonthPlanMutation,
  useMakeScheduleEtoMonthPlansMutation,
  useCreateEtoStepDriverSignatureMutation,
  useCreateEtoStepMechanicSignatureMutation,
  useGetTeamsToCopyQuery,
  useGetEmployeeSignatureQuery,
  useGetEtoTasksQuery,
  util,
} = apiSlice;
