import { useUpdatePspaKfTaskMutation } from "core/app/api/units/SGPS";
import { useUpdateLsoTaskMutation } from "core/app/api/units/LSO";
import { useUpdateLkuTaskMutation } from "core/app/api/units/LKU";
import { useUpdateOossaTaskMutation } from "core/app/api/units/OOSSA";
import { useUpdateOpinTaskMutation } from "core/app/api/task/opin";
import { useUpdateVolsTaskMutation } from "core/app/api/units/VOLS";
import { TaskDiscriminatorEnum } from "core/app/api/enums";
import { useUpdatePspaTaskMutation } from "core/app/api/units/PSPA";
import { useUpdateSlpiTaskMutation } from "core/app/api/units/SLPI";
import { useUpdateEtoTaskMutation } from "core/app/api/task";

export function useUpdateTask(discriminator?: TaskDiscriminatorEnum) {
  const pspa = useUpdatePspaTaskMutation();
  const pspakf = useUpdatePspaKfTaskMutation();
  const oossa = useUpdateOossaTaskMutation();
  const slpi = useUpdateSlpiTaskMutation();
  const eto = useUpdateEtoTaskMutation();
  const lso = useUpdateLsoTaskMutation();
  const lku = useUpdateLkuTaskMutation();
  const vols = useUpdateVolsTaskMutation();
  const opin = useUpdateOpinTaskMutation();
  if (!discriminator) {
    return pspa;
  }
  switch (discriminator) {
    case TaskDiscriminatorEnum.PSPA_TASK:
      return pspa;
    case TaskDiscriminatorEnum.PSPA_KF_TASK:
      return pspakf;
    case TaskDiscriminatorEnum.OOSSA_TASK:
      return oossa;
    case TaskDiscriminatorEnum.SLPI_TASK:
      return slpi;
    case TaskDiscriminatorEnum.ETO_PSPA_TASK:
      return eto;
    case TaskDiscriminatorEnum.LSO_TASK:
      return lso;
    case TaskDiscriminatorEnum.LKU_TASK:
      return lku;
    case TaskDiscriminatorEnum.VOLS_TASK:
      return vols;
    case TaskDiscriminatorEnum.OPIN_TASK:
      return opin;
    default:
      return pspa;
  }
}
