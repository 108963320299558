import { Input, Form, FormInstance } from "antd";
import { ReactElement } from "react";

import { StateNamesEnum } from "core/app/api/task/enums";

const { TextArea } = Input;

interface NoteTextAreaProps {
  disabled: boolean;
  form: FormInstance;
  required?: true;
}

function NoteTextArea({ disabled, form, required }: NoteTextAreaProps): ReactElement {
  return (
    <Form.Item
      label="КОММЕНТАРИЙ"
      name="note"
      required={required}
      rules={[
        {
          validator: (_, value) => {
            const newState = form.getFieldValue("newState");
            if ((newState === StateNamesEnum.OnPause || required) && !value) {
              return Promise.reject(new Error("Пожалуйста, введите КОММЕНТАРИЙ"));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <TextArea rows={5} disabled={disabled} />
    </Form.Item>
  );
}

export default NoteTextArea;
