import { Card, Divider, Space, Button } from "antd";
import { NavLink } from "react-router-dom";

import PageTitle from "core/widgets/PageTitle";
import { PageTitleContainer } from "core/widgets/PageTitleContainer";
import { AppRoutes } from "core/shared/constants.routes";
import imgArrowRight from "core/images/long-arrow-right.svg";

import "./styles.scss";

import JournalStats from "./JournalStats";
import JournalTable from "./JournalTable";

function TaskSignatureJournal() {
  return (
    <Space className="task-signature-journal w-100" direction="vertical" size={32}>
      <PageTitleContainer>
        <PageTitle title="ЖУРНАЛ ОТПРАВКИ" />
        <Button
          type="link"
          size="large"
          iconPosition="end"
          icon={
            <div style={{ marginLeft: 1, width: 37, height: 24 }}>
              <img src={imgArrowRight} alt="arrow" />
            </div>
          }
        >
          <NavLink to={AppRoutes.taskForShift}>
            <span className="button-content uppercase">Задачи на смену</span>
          </NavLink>
        </Button>
      </PageTitleContainer>
      <Space className="mb-2 w-100" direction="vertical">
        <Card className="task-signature-journal__content" bordered={false}>
          <JournalStats />
          <Divider />
          <JournalTable />
        </Card>
      </Space>
    </Space>
  );
}

export default TaskSignatureJournal;
