import { useRef, MutableRefObject, useEffect, useCallback } from "react";
import { Row, Col, Button, Form } from "antd";
import SignatureCanvas from "react-signature-canvas";

import { useSignatureStatus } from "core/shared/task/hooks/useSignatureStatus";
import Text from "core/lib/Text/Text";
import { dataUrlToFile } from "core/shared/tools/dataUrlToFile";
import { useNetworkState } from "core/shared/main/hooks/useNetworkState";
import { useAppDispatch } from "core/app/store";
import { addSignature } from "core/app/store/task-signature-slice";
import { useTeam } from "core/shared/tasks-for-shift/hooks/useTeam";
import dayjs, { apiDateFormat } from "core/shared/tools/dayjs";
import { ITask } from "core/app/api/task/interfaces";
import { useUploadTaskSignatureMutation } from "core/app/api/task";
import { SignatureSideEnum } from "core/app/api/enums";

import { ExecutorSelect } from "./ExecutorSelect";
import { CustomerSelect } from "./CustomerSelect";

interface IFormValues {
  fio: string;
  position: string;
  executor: string;
}

interface SignatureFormProps {
  task: ITask;
}

function SignatureForm({ task }: SignatureFormProps) {
  const dispatch = useAppDispatch();
  const [signatureStatus, setSignatureStatus] = useSignatureStatus();
  const team = useTeam();
  const { isOnline } = useNetworkState();

  const canvasRef = useRef() as MutableRefObject<SignatureCanvas>;
  const [request, result] = useUploadTaskSignatureMutation();
  const [form] = Form.useForm();

  const sendSignatureRequest = useCallback(
    async (values: IFormValues) => {
      if (canvasRef?.current && signatureStatus) {
        const dataUrl = canvasRef.current.toDataURL();
        const file = await dataUrlToFile(dataUrl, "signature");
        const formData = new FormData();
        if (signatureStatus === SignatureSideEnum.Executor) {
          formData.append("executor_id", values.executor);
        }
        if (signatureStatus === SignatureSideEnum.Customer) {
          formData.append("customer_representative_id", values.executor);
        }
        formData.append("signature", file);
        request({
          taskId: task.id,
          body: formData,
          side: signatureStatus,
        });
      }
    },
    [request, signatureStatus, task.id]
  );

  const updateSignatureQueue = useCallback(
    async (values: IFormValues) => {
      if (canvasRef?.current && signatureStatus) {
        const dataUrl = canvasRef.current.toDataURL();

        dispatch(
          addSignature({
            taskId: task.id,
            url: dataUrl,
            side: signatureStatus,
            transportName: task.transport?.name || "-",
            signatoryId: values.executor,
            shiftDate: dayjs(team?.date).format(apiDateFormat),
            shiftName: `№${team?.shift_number} - ${team?.team_lead_short_name}`,
          })
        );

        setSignatureStatus(null);
      }
    },
    [dispatch, signatureStatus, task.id, task.transport, team, setSignatureStatus]
  );

  const handleFinish = useCallback(
    async (values: IFormValues) => {
      if (!isOnline) {
        updateSignatureQueue(values);
      } else {
        sendSignatureRequest(values);
      }
    },
    [isOnline, sendSignatureRequest, updateSignatureQueue]
  );

  const handleClear = () => {
    canvasRef.current.clear();
  };

  useEffect(() => {
    if (result.isSuccess) {
      setSignatureStatus(null);
    }
  }, [result.isSuccess, setSignatureStatus]);

  return (
    <Form layout="vertical" initialValues={{ fio: "", position: "", executor: "" }} onFinish={handleFinish} form={form}>
      <Row justify="center">
        <Col>
          {signatureStatus === SignatureSideEnum.Customer && (
            <Text size="h4" boldness="regular">
              ЗАКАЗЧИК
            </Text>
          )}
          {signatureStatus === SignatureSideEnum.Executor && (
            <Text size="h4" boldness="regular">
              ИСПОЛНИТЕЛЬ
            </Text>
          )}
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button size="small" onClick={handleClear}>
            ОЧИСТИТЬ
          </Button>
        </Col>
      </Row>
      <Row className="mt-1 mb-1">
        <Col
          span={24}
          style={{
            border: "1px solid #d9d9d9",
          }}
        >
          <div>
            {!!canvasRef && (
              <SignatureCanvas
                ref={canvasRef}
                maxWidth={5}
                penColor="#0000FF"
                canvasProps={{ width: 648, height: 456, className: "sigCanvas" }}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {signatureStatus === SignatureSideEnum.Customer && <CustomerSelect form={form} />}
          {signatureStatus === SignatureSideEnum.Executor && <ExecutorSelect teamId={task.team_id} />}
        </Col>
      </Row>
      <Row gutter={36} className="mt-3">
        <Col span={12}>
          <Button
            style={{ width: "100%" }}
            onClick={() => {
              setSignatureStatus(null);
            }}
          >
            ОТМЕНИТЬ
          </Button>
        </Col>
        <Col span={12}>
          <Button loading={result.isLoading} htmlType="submit" style={{ width: "100%" }} type="primary">
            СОХРАНИТЬ
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export { SignatureForm };
