import { Row, Col, Divider, Space, Modal } from "antd";
import { ReactElement, useState, useCallback } from "react";
import Collapse from "@mui/material/Collapse";
import clsx from "clsx";

import Text from "core/lib/Text/Text";
import { ITask } from "core/app/api/task/interfaces";
import { isLkuTask, isLsoTask, isOossaTask, isPspaTask, isSlpiTask, isVolsTask } from "core/shared/task";
import {
  Number,
  RequestDescription,
  RequestCreationDatetime,
  State,
  RequestStatus,
  Location,
  Horizon,
  Address,
  System,
  EquipmentType,
  Initiator,
  EffortField,
  AccumulatorElapsedTime,
  Customer,
  SuspendedUntil,
  WorkTimeUsageHours,
  OossaObject,
  OossaSystem,
  LkuDepartment,
  LkuObject,
  VolsObject,
  VolsDepartment,
  VolsLineNumber,
  VolsEquipmentType,
} from "core/widgets/TaskModals/shared/Fields";
import { IcCollapse } from "core/widgets/IcCollapse";
import { ZabbixAlert } from "core/widgets/Equipment/ZabbixAlert";
import { formatHours } from "core/shared/main/tools/formatHours";
import { EffortTypeEnum } from "core/models/enums";

import { MaintenanceControl } from "../../shared/MaintenanceControl";

interface TaskModalRecoveryGeneralInfoProps {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке АВР или ЗНО. */
export default function TaskModalRecoveryGeneralInfo(props: TaskModalRecoveryGeneralInfoProps): ReactElement {
  const { task } = props;
  const [isFull, setFull] = useState(false);
  const [isTaskInfoOpen, setTaskInfoOpen] = useState(false);

  const handleCloseTaskInfo = useCallback(() => {
    setTaskInfoOpen(false);
  }, []);

  return (
    <>
      <ZabbixAlert equipmentId={task.equipment?.id} />
      <Row gutter={16}>
        <Col span={24}>
          <Number number={task.number} requestDescription={task.request_description} />
          {isLkuTask(task) && (
            <>
              <LkuDepartment task={task} />
              <LkuObject task={task} />
              <EquipmentType task={task} />
            </>
          )}
          <RequestDescription task={task} />
          {isVolsTask(task) && <VolsDepartment task={task} />}
        </Col>
      </Row>
      <Collapse in={isFull}>
        <Row gutter={16} className={clsx("mb-1", "mt-2")}>
          <Col span={12}>
            <RequestCreationDatetime task={task} />
            <State task={task} />
            <RequestStatus task={task} />
            {isVolsTask(task) && <VolsLineNumber task={task} />}
            {isVolsTask(task) && <VolsEquipmentType task={task} />}
          </Col>
          <Col span={12}>
            {isPspaTask(task) && (
              <>
                <Location task={task} />
                <Horizon task={task} />
                {!task.is_mounted && <Address task={task} />}
                <System task={task} />
                <EquipmentType task={task} />
              </>
            )}
            {isOossaTask(task) && (
              <>
                <OossaObject task={task} />
                <OossaSystem task={task} />
              </>
            )}
            {isVolsTask(task) && <VolsObject task={task} />}
            {(isSlpiTask(task) || isVolsTask(task)) && <Customer task={task} />}
            <Initiator task={task} />
            <EffortField
              task={task}
              effortType={
                isSlpiTask(task) || isLsoTask(task) || isLkuTask(task) ? EffortTypeEnum.MAN_HOURS : EffortTypeEnum.HOURS
              }
            />
            <AccumulatorElapsedTime task={task} />
            <SuspendedUntil task={task} />
            <WorkTimeUsageHours task={task} formatFn={isLkuTask(task) ? formatHours : undefined} />
            {isPspaTask(task) && (
              <>
                <br />
                <MaintenanceControl task={task} />
              </>
            )}
          </Col>
        </Row>
      </Collapse>
      <Divider>
        <div
          className="link"
          onClick={() => {
            setFull(!isFull);
          }}
        >
          <Space>
            <Text className="blue" size="label3">
              {isFull ? "СВЕРНУТЬ ДОПОЛНИТЕЛЬНУЮ ИНФОРМАЦИЮ" : "РАЗВЕРНУТЬ ДОПОЛНИТЕЛЬНУЮ ИНФОРМАЦИЮ"}
            </Text>
            <IcCollapse open={!isFull} reverse />
          </Space>
        </div>
        <Modal
          title={`Описание задачи ${task.number}`}
          open={isTaskInfoOpen}
          onOk={handleCloseTaskInfo}
          onCancel={handleCloseTaskInfo}
          cancelButtonProps={{ style: { display: "none" } }}
          okText="Закрыть"
        >
          <Text style={{ fontSize: "14px", lineHeight: "15px" }}>{task.request_description}</Text>
        </Modal>
      </Divider>
    </>
  );
}
