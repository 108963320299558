import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TaskAttachmentCategoryEnum } from "core/app/api/task/enums";
import { EtoTaskAttachmentCategoryEnum } from "core/app/api/task/eto/enums";

export type TBufferFileElement = {
  file: File;
  taskId: number;
  category: TaskAttachmentCategoryEnum | EtoTaskAttachmentCategoryEnum;
  key: string;
  compression: boolean;
};

type TInitialState = {
  isWorkStatus: boolean;
  countTotal: number;
  countStep: number;
  filesBuffer: TBufferFileElement[];
};

const initialState: TInitialState = {
  countTotal: 0,
  countStep: 0,
  isWorkStatus: false,
  filesBuffer: [],
};

export const FileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    countStepInc: (state) => {
      state.countStep += 1;
    },
    totalCountInc: (state, action: PayloadAction<number>) => {
      state.countTotal += action.payload;
    },
    clearCounts: (state) => {
      state.countTotal = 0;
      state.countStep = 0;
    },
    handleStatusWork: (state, action: PayloadAction<boolean>) => {
      state.isWorkStatus = action.payload;
    },
    fillTheBuffer: (state, action: PayloadAction<TBufferFileElement[]>) => {
      state.filesBuffer = [...state.filesBuffer, ...action.payload];
    },
    clearBuffer: (state, action: PayloadAction<string>) => {
      state.filesBuffer = state.filesBuffer.filter((item) => item.key !== action.payload);
    },
  },
});

export const { handleStatusWork, fillTheBuffer, totalCountInc, countStepInc, clearBuffer, clearCounts } =
  FileSlice.actions;
