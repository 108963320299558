import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
// eslint-disable-next-line import/no-unresolved
import { MutationLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { mimeWordDecode } from "emailjs-mime-codec";

import { apiSlice } from "..";
import { ITask } from "../task/interfaces";

export async function handleUpdateTaskQueryStarted(
  { rtkQueryCacheKey, ...taskUpdate }: any,
  {
    dispatch,
    queryFulfilled,
    getState,
  }: MutationLifecycleApi<any, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>, any, "api">
) {
  try {
    const updatedTask = await queryFulfilled;
    dispatch(
      apiSlice.util.updateQueryData("getOpenTasks", rtkQueryCacheKey, (cachedTasks) => {
        const cachedTaskToUpdate = cachedTasks.results.find((cachedTask: ITask) => cachedTask.id === taskUpdate.id);
        if (cachedTaskToUpdate) {
          Object.assign(cachedTaskToUpdate, updatedTask.data);
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export const TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE = [
  "tasks",
  "shifttask",
  "tasks-filtered",
  "kanban-board",
  "month-plan",
  "tasks/statistics-by-days",
  "dashboard/shift-statistic/tasks",
  "dashboard/shift-statistic/tasks/by-equipment-types",
  "dashboard/shift-statistic/tasks/by-objects/slpi",
  "equipments/maintenance",
  "transport/maintenance",
] as const;

export const getFileNameFromContentDisposition = (contentDisposition: string | null) => {
  if (contentDisposition) {
    const decoded = mimeWordDecode(contentDisposition);
    if (decoded.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(decoded);
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, "");
      }
    }
  }
  return null;
};

/** Убрать из имени файла заведомо некорректные символы. */
function safeFileName(unsafeFilename: string): string {
  return unsafeFilename.replace(/[/\\&+'":*?<>]/g, "");
}

/** Обработчик для загрузки файлов, например, отчетов.
 * @param fileName Желательное имя файла, оно должно быть без расширения.
 * @param tryGetFileNameFromBackend Попытаться получить имя файла из бэкенда? В этом случае, оно будет предпочтительным.
 */
export function createDownloadResponseHandler(fileName: string, tryGetFileNameFromBackend: boolean) {
  return async (response: Response) => {
    if (response.ok) {
      const fileLink = document.createElement("a");
      fileLink.href = window.URL.createObjectURL(await response.blob());
      fileLink.target = "blank";

      let unsafeFileName: string | null = null;
      if (tryGetFileNameFromBackend) {
        const disposition = await response.headers.get("content-disposition");
        // здесь получим имя файла с расширением
        unsafeFileName = getFileNameFromContentDisposition(disposition);
      }
      if (!unsafeFileName) unsafeFileName = fileName?.replace(".", "") || "downloaded_file";

      fileLink.download = safeFileName(unsafeFileName);
      fileLink.click();
    }
  };
}
