import { ReactElement } from "react";
import { Select, Form } from "antd";

import { IDictionaryItem } from "core/shared/interfaces";
import { useCustomers } from "core/shared/customer/hooks/useCustomers";
import { SlpiWorkTypeGroupEnum } from "core/app/api/units/SLPI/interfaces";
import { useWorkType } from "core/shared/main/hooks/useWorkType";
import { ApiSliceEnum } from "core/app/api/enums";
import { useApiSlice } from "core/shared/main/hooks/useApiSlice";
import { LsoWorkTypeGroupEnum } from "core/app/api/units/LSO/enums";

export function Customer(): ReactElement {
  const form = Form.useFormInstance();
  const { data, isFetching } = useCustomers();
  const workTypeId = Form.useWatch("workTypeId");
  const apiSlice = useApiSlice();
  const workType = useWorkType(workTypeId, apiSlice);
  const objectIsMissing = Form.useWatch("objectIsMissing");

  const options = (data || []).map((item: IDictionaryItem) => ({
    label: item.name,
    value: item.id,
  }));

  const getIsRequired = () => {
    if (apiSlice === ApiSliceEnum.slpi) {
      return (
        workType &&
        "group" in workType &&
        (workType?.group === SlpiWorkTypeGroupEnum.WORK ||
          (workType?.group === SlpiWorkTypeGroupEnum.RESEARCH && !objectIsMissing))
      );
    }
    if (apiSlice === ApiSliceEnum.lso) {
      return (
        workType &&
        "group" in workType &&
        (workType?.group === LsoWorkTypeGroupEnum.WORK ||
          (workType?.group === LsoWorkTypeGroupEnum.RESEARCH && !objectIsMissing))
      );
    }

    return false;
  };

  const handleChange = (val: number) => {
    form.setFieldValue("departmentId", null);
    form.setFieldValue("systemComplexId", null);
    form.setFieldValue("objectId", null);
  };

  const isRequired = getIsRequired();

  return (
    <Form.Item
      label="ЗАКАЗЧИК"
      name="customerId"
      colon={false}
      required={isRequired}
      rules={[
        {
          required: isRequired,
          message: "",
        },
      ]}
    >
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleChange}
      />
    </Form.Item>
  );
}
