import { Row, Col } from "antd";
import { useEffect, useState } from "react";

import Text from "core/lib/Text/Text";
import { useNetworkState } from "core/shared/main/hooks/useNetworkState";
import { ITaskSignature } from "core/app/store/task-signature-slice";
import { SignatureSideEnum } from "core/app/api/enums";
import { useGetTaskSignatureFileQuery } from "core/app/api/task";

interface SignatureCompletedProps {
  side: SignatureSideEnum;
  position: string;
  fio: string;
  taskId: number;
  localSignature?: ITaskSignature;
}

function SignatureCompleted({ side, position, fio, taskId, localSignature }: SignatureCompletedProps) {
  const { isOnline } = useNetworkState();
  const { data, isSuccess, isError } = useGetTaskSignatureFileQuery({
    taskId,
    side,
  });

  const [signatureSrc, setSignatureSrc] = useState<string | undefined>();

  useEffect(() => {
    if (!isOnline && localSignature) {
      setSignatureSrc(localSignature.url);
    } else if (data && isSuccess) {
      setSignatureSrc(data);
    }
  }, [data, isError, isSuccess, isOnline, localSignature]);

  let sideName = "";
  if (side === SignatureSideEnum.Customer) {
    sideName = "ЗАКАЗЧИК";
  }
  if (side === SignatureSideEnum.Executor) {
    sideName = "ИСПОЛНИТЕЛЬ";
  }
  return (
    <>
      <Row>
        <Col>
          <Text size="label1" boldness="semibold">
            {sideName}
          </Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={14} style={{ alignSelf: "center" }}>
          <Row>
            <Col>
              <Text className="uppercase" size="label4" boldness="semibold">
                {position}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text size="label1" boldness="black" className="blue">
                {fio}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <img style={{ maxWidth: "100%", border: "1px solid #d9d9d9" }} src={signatureSrc} alt="" />
        </Col>
      </Row>
    </>
  );
}

export { SignatureCompleted };
