import { useCallback } from "react";
import { Button } from "antd";
import { useLocation } from "react-router-dom";

import { useShowMenuButtonTaskSidebar } from "core/shared/main/hooks/useShowMenuButtonTaskSidebar";
import { useAppDispatch } from "core/app/store";
import { setTaskSidebarOpened } from "core/app/store/main-slice";
import { useTeamId } from "core/shared/tasks-for-shift/store/useTeamId";

export function TasksButton() {
  const showMenuButtonTaskSidebar = useShowMenuButtonTaskSidebar();
  const dispatch = useAppDispatch();
  const [, , disbanded] = useTeamId();
  const location = useLocation();
  const pathCheck = location.pathname === "/tasks-for-shift";
  const handleTaskBarClick = useCallback(() => {
    dispatch(setTaskSidebarOpened(true));
  }, [dispatch]);

  if (pathCheck && disbanded) return null;

  if (showMenuButtonTaskSidebar) {
    return (
      <Button type="link" onClick={handleTaskBarClick}>
        <span style={{ borderBottom: "1px solid", paddingBottom: "1px" }}>ЗАДАЧИ</span>
      </Button>
    );
  }
  return null;
}
