import { ReactElement } from "react";
import clsx from "clsx";

import { roundTo } from "core/shared/tools/math";
import { OossaSystemTypeEnum } from "core/app/api/units/OOSSA/enums";
import {
  IOossaMaintenancePlanFactStatistic,
  IOossaMaintenancePlanFactStatisticBySystem,
} from "core/app/api/units/OOSSA/interfaces";

import { getStatAmount } from "../../OossaMaintenance";

interface IBalanceProps {
  system: OossaSystemTypeEnum;
  data: IOossaMaintenancePlanFactStatistic;
}

function Balance({ data, system }: IBalanceProps) {
  return (
    <td>
      <span>
        {roundTo(getStatAmount(data.plans_for_the_month, system) - getStatAmount(data.facts_for_the_day, system), 1)}
      </span>
    </td>
  );
}

function Performance({ data, system }: IBalanceProps) {
  return (
    <td>
      <span>
        {roundTo(getStatAmount(data.facts_for_the_day, system) - getStatAmount(data.plans_for_the_day, system), 1)}
      </span>
    </td>
  );
}

interface IRowProps {
  title: string;
  data: IOossaMaintenancePlanFactStatisticBySystem[];
}

function Row({ data, title }: IRowProps) {
  return (
    <tr>
      <td>{title}</td>
      <td>{getStatAmount(data, OossaSystemTypeEnum.ASKUE)}</td>
      <td>{getStatAmount(data, OossaSystemTypeEnum.ASKUE_EE)}</td>
      <td>{getStatAmount(data, OossaSystemTypeEnum.SMZIS)}</td>
    </tr>
  );
}

interface IProps {
  data: IOossaMaintenancePlanFactStatistic;
}

export function TableOossa({ data }: IProps): ReactElement {
  if (!data) {
    return <div />;
  }

  return (
    <div className={clsx("maintenance-stats")}>
      <table>
        <tbody>
          <tr className="head">
            <td />
            <td width="22%">АСКУЭ</td>
            <td width="22%">АСКУЭ ЭЭ</td>
            <td width="22%">СМЗиС</td>
          </tr>
          <Row title="План на месяц" data={data.plans_for_the_month} />
          <Row title="План на тек. дату" data={data.plans_for_the_day} />
          <Row title="Факт на тек. дату" data={data.facts_for_the_day} />
          <tr>
            <td>Остаток</td>
            <Balance data={data} system={OossaSystemTypeEnum.ASKUE} />
            <Balance data={data} system={OossaSystemTypeEnum.ASKUE_EE} />
            <Balance data={data} system={OossaSystemTypeEnum.SMZIS} />
          </tr>
          <tr>
            <td>Факт минус план на тек. дату</td>
            <Performance data={data} system={OossaSystemTypeEnum.ASKUE} />
            <Performance data={data} system={OossaSystemTypeEnum.ASKUE_EE} />
            <Performance data={data} system={OossaSystemTypeEnum.SMZIS} />
          </tr>
        </tbody>
      </table>
    </div>
  );
}
