import { Row, Col } from "antd";
import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";
import {
  PlannedDate,
  Customer,
  LkuDepartment,
  Unit,
  AccumulatorElapsedTime,
  EquipmentType,
  LkuObject,
  EffortField,
} from "core/widgets/TaskModals/shared/Fields";
import { ZabbixAlert } from "core/widgets/Equipment/ZabbixAlert";
import { EffortTypeEnum } from "core/models/enums";

interface GeneralInfoProps {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке ТО стационарного оборудования ПСПА, СДГР, СЛПИ, ООССА. */
function GeneralInfo(props: GeneralInfoProps): ReactElement {
  const { task } = props;

  return (
    <>
      <ZabbixAlert equipmentId={task.equipment?.id} />
      <Row className="mb-1">
        <Col>
          <PlannedDate task={task} />
          <Customer task={task} />
          <Unit task={task} />
          <LkuDepartment task={task} />
          <LkuObject task={task} />
          <EquipmentType task={task} />
          <EffortField task={task} effortType={EffortTypeEnum.MAN_HOURS} />
          <AccumulatorElapsedTime task={task} />
        </Col>
      </Row>
    </>
  );
}

export default GeneralInfo;
