import { useContext } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { getDashboardShiftStatisticTasksByTeam } from "core/shared/dashboard/hooks/getDashboardShiftStatisticTasksByTeam";
import { useGetDashboardShiftStatisticTasksByTeamsLsoQuery } from "core/app/api/units/LSO";

export function useDashboardShiftStatisticTasksByTeamsLso() {
  const { queryString, teamId } = useContext(ShiftContext);
  const { data } = useGetDashboardShiftStatisticTasksByTeamsLsoQuery(queryString ?? skipToken);
  return getDashboardShiftStatisticTasksByTeam(data, teamId);
}
