import { useContext } from "react";
import qs from "qs";

import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { useUnitId } from "core/shared/unit/useUnitId";
import { apiDateFormat } from "core/shared/tools/dayjs";
import { useGetDashboardShiftStatisticTasksByObjectsLkuQuery } from "core/app/api/units/LKU";

export const useDashboardShiftStatisticTasksByObjectsLku = () => {
  const { date, shiftNum } = useContext(ShiftContext);
  const [unitId] = useUnitId();

  return useGetDashboardShiftStatisticTasksByObjectsLkuQuery(
    qs.stringify({
      unit_id: unitId,
      shift_num: shiftNum,
      date: date?.format(apiDateFormat),
    }),
    {
      skip: !(unitId && shiftNum && date),
    }
  );
};
