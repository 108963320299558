import { Row, Col } from "antd";
import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";
import {
  Customer,
  Location,
  Unit,
  Address,
  AccumulatorElapsedTime,
  TypeMaintenance,
} from "core/widgets/TaskModals/shared/Fields";
import { Deadline } from "core/widgets/TaskModals/shared/Fields/Deadline";

interface GeneralInfoProps {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке ТО стационарного оборудования ПСПА, СДГР, СЛПИ, ООССА. */
function GeneralInfo(props: GeneralInfoProps): ReactElement {
  const { task } = props;

  return (
    <Row className="mb-1">
      <Col>
        <Deadline task={task} />
        <Customer task={task} />
        <Unit task={task} />
        <Location task={task} />
        <Address task={task} />
        <AccumulatorElapsedTime task={task} />
        <TypeMaintenance task={task} />
      </Col>
    </Row>
  );
}

export default GeneralInfo;
