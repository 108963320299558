import { ReactElement } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import qs from "qs";
import { Col, Empty, Form, Row } from "antd";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useUnit } from "core/shared/unit/useUnit";
import { useGetDashboardLkuMaintenancePlanFactStatisticQuery } from "core/app/api/units/LKU";
import { LkuEquipmentTypeCodeEnum } from "core/app/api/units/LKU/enums";

import { Bars } from "../../Bars";
import { getStatAmount } from "..";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function BarsLku(): ReactElement {
  const unit = useUnit();
  const yearDate = Form.useWatch("year");
  const monthDate = Form.useWatch("month");
  const customerId = Form.useWatch("customer_id");
  const customerDepartmentId = Form.useWatch("customer_department_id");

  const queryParams = {
    unit_id: unit?.id,
    ...(yearDate && { year: yearDate.year() }),
    ...(monthDate && { month: monthDate.month() + 1 }),
    ...(customerId && { customer_id: customerId }),
    ...(customerDepartmentId && { customer_department_id: customerDepartmentId }),
  };
  const { data, isSuccess } = useGetDashboardLkuMaintenancePlanFactStatisticQuery(
    unit?.isLKU() ? qs.stringify(queryParams) : skipToken
  );

  if (!isSuccess) {
    return <Empty />;
  }

  const isCurrentDate = yearDate?.year() === new Date().getFullYear() && monthDate?.month() === new Date().getMonth();

  return (
    <Row>
      <Col span={12}>
        <Bars
          title="БКТ"
          month={getStatAmount(data.plans_for_the_month, LkuEquipmentTypeCodeEnum.BKT)}
          day={getStatAmount(data.plans_for_the_day, LkuEquipmentTypeCodeEnum.BKT)}
          fact={getStatAmount(data.facts_for_the_day, LkuEquipmentTypeCodeEnum.BKT)}
          isCurrentDate={isCurrentDate}
        />
      </Col>
      <Col span={12}>
        <Bars
          title="КРТ"
          month={getStatAmount(data.plans_for_the_month, LkuEquipmentTypeCodeEnum.KRT)}
          day={getStatAmount(data.plans_for_the_day, LkuEquipmentTypeCodeEnum.KRT)}
          fact={getStatAmount(data.facts_for_the_day, LkuEquipmentTypeCodeEnum.KRT)}
          isCurrentDate={isCurrentDate}
        />
      </Col>
    </Row>
  );
}
