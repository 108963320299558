import { ReactElement } from "react";
import { Row, Col } from "antd";

import Text from "core/lib/Text/Text";
import { safePercent } from "core/shared/tools/safePercent";
import { TaskTypesIdsEnum } from "core/app/api/task/enums";
import {
  IDashboardShiftStatisticTasksByEquipmentTypes,
  IDashboardShiftStatisticTasks,
} from "core/shared/dashboard/hooks/interfaces";

import SystemStatRow from "../../shared/SystemStatRow";
import TotalByTaskType from "../../shared/TotalByTaskType";
import { Total } from "../../shared/Total";
import ProgressBar from "../../shared/SystemStatRow/ProgressBar";

interface IStationaryProps {
  isMounted?: boolean;
  data: IDashboardShiftStatisticTasksByEquipmentTypes | undefined;
}

const subtractToZero = (reduced: number, deductible: number) => (reduced - deductible >= 0 ? reduced - deductible : 0);

function ByEquipmentTypes({ isMounted, data }: IStationaryProps): ReactElement {
  if (!data) {
    return <div />;
  }

  let statistic: IDashboardShiftStatisticTasks;
  if (isMounted) {
    statistic = data.mounted;
  } else {
    statistic = data.stationary;
  }

  const withoutType = {
    name: "НЕ УКАЗАНО",
    by_type: statistic.by_type?.map((byType) => ({
      writable: true,
      ...byType,
    })),
  };
  statistic.by_equipment_type?.forEach((equipmentType) => {
    equipmentType.by_type.forEach((byEquipmentTypeAndTaskType) => {
      const byTaskType = withoutType.by_type?.find((item) => item.name === byEquipmentTypeAndTaskType.name);
      if (byTaskType) {
        byTaskType.sheduled_tasks = subtractToZero(
          byTaskType.sheduled_tasks,
          byEquipmentTypeAndTaskType.sheduled_tasks
        );
        byTaskType.unsheduled_tasks = subtractToZero(
          byTaskType.unsheduled_tasks,
          byEquipmentTypeAndTaskType.unsheduled_tasks
        );
        byTaskType.closed_sheduled_tasks = subtractToZero(
          byTaskType.closed_sheduled_tasks,
          byEquipmentTypeAndTaskType.closed_sheduled_tasks
        );
        byTaskType.closed_unsheduled_tasks = subtractToZero(
          byTaskType.closed_unsheduled_tasks,
          byEquipmentTypeAndTaskType.closed_unsheduled_tasks
        );
      }
    });
  });

  return (
    <Row gutter={16}>
      <Col span={3}>
        <Total
          showTitle={!isMounted}
          fact={safePercent(statistic.closed_sheduled_tasks, statistic.sheduled_tasks)}
          plan={100 - safePercent(statistic.closed_sheduled_tasks, statistic.sheduled_tasks)}
        />
      </Col>
      <Col span={21}>
        <Row gutter={24}>
          <Col span={3} />
          <Col span={21}>
            <Row>
              <Col span={8}>
                <TotalByTaskType
                  title={isMounted ? undefined : "АВР"}
                  data={statistic.by_type?.find((item) => item.name === TaskTypesIdsEnum.RECOVERY)}
                />
              </Col>
              <Col span={8}>
                <TotalByTaskType
                  title={isMounted ? undefined : "ЗНО"}
                  data={statistic.by_type?.find((item) => item.name === TaskTypesIdsEnum.SERVICE_REQUEST)}
                />
              </Col>
              <Col span={8}>
                <TotalByTaskType
                  title={isMounted ? undefined : "ТО"}
                  data={statistic.by_type?.find((item) => item.name === TaskTypesIdsEnum.MAINTENANCE)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={24}>
          {!isMounted && (
            <Col span={24}>
              {statistic.by_equipment_type?.map((equipmentType) => (
                <div key={equipmentType.name}>
                  <SystemStatRow name={equipmentType.name} data={equipmentType.by_type} isMounted={isMounted} />
                </div>
              ))}
              <div>
                <SystemStatRow name={withoutType.name} data={withoutType.by_type} isMounted={isMounted} />
              </div>
            </Col>
          )}
          {isMounted && (
            <>
              <Col span={17}>
                {statistic.by_equipment_type?.map((equipmentType) => (
                  <div key={equipmentType.name}>
                    <SystemStatRow name={equipmentType.name} data={equipmentType.by_type} isMounted={isMounted} />
                  </div>
                ))}
                <div>
                  <SystemStatRow name={withoutType.name} data={withoutType.by_type} isMounted={isMounted} />
                </div>
              </Col>
              <Col span={7}>
                <div>
                  <Text size="body1" boldness="semibold">
                    СДО
                  </Text>
                  <ProgressBar
                    data={statistic.by_type?.find((item) => item.name === TaskTypesIdsEnum.MAINTENANCE)}
                    strokeColor="#32C5FF"
                  />
                </div>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export { ByEquipmentTypes };
