import { IDashboardShiftStatisticTasksByTeamsLkuTask } from "core/app/api/units/LKU/interfaces";
import { IDashboardShiftStatisticTasksByTeamsLsoTask } from "core/app/api/units/LSO/interfaces";
import { IDashboardShiftStatisticTasksByTeamsSlpiTask } from "core/app/api/units/SLPI/interfaces";
import { IDashboardShiftStatisticTasksByTeamsPspaTask } from "core/app/api/units/PSPA/interfaces";
import { IDashboardShiftStatisticTasksByTeamsOossaTask } from "core/app/api/units/OOSSA/interfaces";
import { IDashboardShiftStatisticTasksByTeamsVolsTask } from "core/app/api/units/VOLS/interfaces";

type TDashboardShiftStatisticTasksByTeamsTask =
  | IDashboardShiftStatisticTasksByTeamsLsoTask
  | IDashboardShiftStatisticTasksByTeamsLkuTask
  | IDashboardShiftStatisticTasksByTeamsVolsTask
  | IDashboardShiftStatisticTasksByTeamsSlpiTask
  | IDashboardShiftStatisticTasksByTeamsPspaTask
  | IDashboardShiftStatisticTasksByTeamsOossaTask;

export interface IGroup<T extends TDashboardShiftStatisticTasksByTeamsTask> {
  name: string;
  id: string;
  type: string;
  children?: T[];
}

export const isGroup = (
  record: IGroup<TDashboardShiftStatisticTasksByTeamsTask> | TDashboardShiftStatisticTasksByTeamsTask
): record is IGroup<TDashboardShiftStatisticTasksByTeamsTask> => {
  if (record.type === "group") {
    return true;
  }
  return false;
};
