import { extractResults } from "../../emptyApi";
import { ApiSliceEnum } from "../../enums";
import { handleUpdateTaskQueryStarted, TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE } from "../../tools";
import { TSendOtherPspaTaskBody } from "../PSPA/types";
import { pspaApiSlice } from "../PSPA";

import {
  ISgpsYearPlanMounted,
  ISgpsYearPlanStationary,
  ISgpsMonthPlanMounted,
  ISgpsMonthPlanStationary,
  IUpdateSgpsPlansByMonthPayload,
  IStatisticYear,
  IApproveSgpsMonthPlansPayload,
  ICreateSgpsMonthPlansCyclesPayload,
  TMakeSgpsMonthPlanAutoDistributionPayload,
  IMaintenanceTypeCycle,
  IUpdateSgpsMonthPlanMaintenanceTypePayload,
  IGroupTasksSgps,
} from "./interfaces";
import { SgpsCodeGroupEnum } from "./enums";

const sgpsApiSlice = pspaApiSlice
  .enhanceEndpoints({
    addTagTypes: ["sgps-group-tasks"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSgpsListSdo: builder.query<{ id: number; name: string }[], string>({
        query: (params) => `self-moving-types/?${params}`,
        transformResponse: extractResults,
      }),
      getSgpsAnnualStatistics: builder.query<IStatisticYear, string>({
        query: (queryString) => `pspa-kf-month-plans/stat-and-approved-plans/?${queryString}`,
        providesTags: ["pspa-kf-month-stat"],
      }),
      getSgpsMonthPlans: builder.query<ISgpsYearPlanMounted[] | ISgpsYearPlanStationary[], string>({
        query: (queryString) => `pspa-kf-year-plans/?${queryString}`,
        providesTags: ["pspa-kf-year-plans"],
      }),
      getSgpsPlansByMonth: builder.query<ISgpsMonthPlanMounted[] | ISgpsMonthPlanStationary[], string>({
        query: (queryString) => `pspa-kf-month-plans/?${queryString}`,
        providesTags: ["pspa-kf-month-plans"],
      }),
      updateSgpsPlansByMonth: builder.mutation<null, IUpdateSgpsPlansByMonthPayload>({
        query: (payload) => {
          const { id, ...rest } = payload;
          return {
            url: `pspa-kf-month-plans/${id}/`,
            method: "PATCH",
            body: rest,
          };
        },
        invalidatesTags: ["pspa-kf-month-plans", "pspa-kf-month-stat"],
      }),
      approveSgpsMonthPlans: builder.mutation<null, IApproveSgpsMonthPlansPayload>({
        query: (payload) => ({
          url: `pspa-kf-month-plans/approve/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["pspa-kf-month-plans", "pspa-kf-month-stat"],
      }),
      undoApproveSgpsMonthPlans: builder.mutation<null, IApproveSgpsMonthPlansPayload>({
        query: (payload) => ({
          url: `pspa-kf-month-plans/undo-approve/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["pspa-kf-month-plans", "pspa-kf-month-stat"],
      }),
      createSgpsMonthPlansCycles: builder.mutation<any, ICreateSgpsMonthPlansCyclesPayload>({
        query: (payload) => ({
          url: `pspa-kf-year-plans/auto-cycle-distribution/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["pspa-kf-month-plans", "pspa-kf-year-plans", "pspa-kf-month-stat"],
      }),
      makeSgpsMonthPlanAutoDistribution: builder.mutation<void, TMakeSgpsMonthPlanAutoDistributionPayload>({
        query: (payload) => ({
          url: `/pspa-kf-month-plans/auto-maintenance-days-distribution/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["pspa-kf-month-plans", "pspa-kf-month-stat", "pspa-kf-year-plans"],
      }),
      updatePspaKfTask: builder.mutation({
        query: ({ rtkQueryCacheKey, ...task }) => ({
          url: `pspa-kf-tasks/${task.id}/`,
          method: "PATCH",
          body: task,
        }),
        async onQueryStarted(args, queryContext) {
          await handleUpdateTaskQueryStarted(args, queryContext);
        },
        invalidatesTags: (_result, _error, arg) => [
          { type: "task", id: arg.id },
          "pspa-kf-month-plans",
          "pspa-kf-month-stat",
          "pspa-kf-year-plans",
          ...TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE,
        ],
      }),
      createPspaTask: builder.mutation<
        any,
        { pspaSlice: ApiSliceEnum.pspa | ApiSliceEnum.pspaKf; body: TSendOtherPspaTaskBody }
      >({
        query: ({ body, pspaSlice }) => ({
          url: `${pspaSlice}-tasks/`,
          method: "POST",
          body,
        }),
        invalidatesTags: [
          "tasks",
          "open-tasks",
          "shifttask",
          "tasks-filtered",
          "kanban-board",
          "pspa-kf-month-plans",
          "pspa-kf-month-stat",
          "pspa-kf-year-plans",
        ],
      }),
      getMaintenanceTypesCycle: builder.query<IMaintenanceTypeCycle[], string>({
        query: (queryString) => `maintenance-types/maintenance-cycle/?${queryString}`,
        providesTags: ["maintenance-types/maintenance-cycle"],
      }),
      updateSgpsMonthPlanMaintenanceType: builder.mutation<void, IUpdateSgpsMonthPlanMaintenanceTypePayload>({
        query: (payload) => {
          const { id, ...rest } = payload;
          return {
            url: `pspa-kf-month-plans/${id}/set-maintenance-type/`,
            method: "POST",
            body: rest,
          };
        },
        invalidatesTags: [
          "pspa-kf-month-plans",
          "pspa-kf-month-stat",
          "pspa-kf-year-plans",
          "pspa-kf-scheduled-task-count",
        ],
      }),

      getGroupTasksSGPS: builder.query<IGroupTasksSgps[], { code: SgpsCodeGroupEnum; queryString?: string }>({
        query: ({ code, queryString }) => `/pspa-kf-tasks/group-tasks/${code}/?${queryString}`,
        providesTags: ["sgps-group-tasks"],
      }),
    }),
  });

export const {
  useGetGroupTasksSGPSQuery,
  useGetSgpsListSdoQuery,
  useGetSgpsMonthPlansQuery,
  useGetSgpsPlansByMonthQuery,
  useUpdateSgpsPlansByMonthMutation,
  useGetSgpsAnnualStatisticsQuery,
  useApproveSgpsMonthPlansMutation,
  useCreateSgpsMonthPlansCyclesMutation,
  useUndoApproveSgpsMonthPlansMutation,
  useMakeSgpsMonthPlanAutoDistributionMutation,
  useUpdatePspaKfTaskMutation,
  useGetMaintenanceTypesCycleQuery,
  useUpdateSgpsMonthPlanMaintenanceTypeMutation,
  useCreatePspaTaskMutation,
} = sgpsApiSlice;
