/** Состояние задания (карточки на выполнение работ). */
export enum StateNamesEnum {
  New = "Новая",
  InWork = "В работе",
  OnPause = "На паузе",
  Closed = "Закрыта",
}

export enum TaskSnapMomentEnum {
  APPROVAL = "APPROVAL",
  CLOSURE = "CLOSURE",
  ADDING_TO_THE_TEAM = "ADDING_TO_THE_TEAM",
  REMOVE_FROM_TEAM = "REMOVE_FROM_TEAM",
  TEAM_DISBAND = "TEAM_DISBAND",
}

/** Типы заявок на обслуживание (перечисление). */
export enum TaskTypesIdsEnum {
  /** Не используется? ("Из планирования"). */
  PREVENTIVE = "PREVENTIVE",
  /** Техническое обслуживание (ТО). */
  MAINTENANCE = "MAINTENANCE",
  /** Аварийно-восстановительная работа (АВР), INC-заявка из ITSM Сириус. */
  RECOVERY = "RECOVERY",
  /** Прочее (задача на дополнительные работы). */
  OTHER = "OTHER",
  /** Запрос на обслуживание (ЗНО), RITM-заявка из ITSM Сириус). */
  SERVICE_REQUEST = "SERVICE_REQUEST",
  /** Ежедневное техническое обслуживание "Хозяек" */
  ETO = "ETO",
  /** Заявка созданные надёжниками */
  OPIN = "OPIN",
}

export enum UrgencyEnum {
  Low = 0,
  Medium = 1,
  High = 2,
  Critical = 3,
}

/** Группировка (подзаголовок) для работ чек-листа карточки ТО. */
export enum CheckItemGroupsEnum {
  /** Раздел "Работоспособность". */
  Performance = 1,
  /** Раздел "Работа". */
  Work = 2,
}

export enum TaskAttachmentCategoryEnum {
  WorkPlace = "work_place",
  Before = "before_or_in_progress",
  After = "after_completion",
  FilesByTask = "files_by_task",
  Vandalism = "vandalism",
}
export enum CreatioTaskTypeEnum {
  RECOVERY = "incident_task",
  SERVICE_REQUEST = "sc_task",
}
