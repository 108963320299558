import { useUpdateTask } from "core/shared/task/hooks/useUpdateTask";
import { IDictionaryItem } from "core/shared/interfaces";
import { TaskDiscriminatorEnum } from "core/app/api/enums";
import { StateNamesEnum } from "core/app/api/task/enums";
import { EtoTaskStepNameEnum } from "core/app/api/task/eto/enums";
import { IEtoTask } from "core/app/api/task/eto/interfaces";

import { useTask } from "../useTask";
import { useGetTaskStatesQuery } from "../../../../app/api/task";

export function useTakeTaskInWork(taskId: number) {
  const { data: task } = useTask(taskId);
  const [updateTaskRequest] = useUpdateTask(task?.discriminator);
  const { data: { results: states } = {} } = useGetTaskStatesQuery("");
  const state = states?.find((item: IDictionaryItem) => item.name === StateNamesEnum.InWork);

  if (!task || !state) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }

  const body: Record<string, any> = {
    id: task.id,
    state_id: state.id,
  };

  if (task.discriminator === TaskDiscriminatorEnum.ETO_PSPA_TASK) {
    const newStep = (task as unknown as IEtoTask).steps.find(
      (step) => step.step_name === EtoTaskStepNameEnum.PRE_SHIFT_CHECK
    );
    if (newStep) {
      body.current_step_id = newStep.id;
    }
  }
  const updateFn = () => {
    updateTaskRequest(body);
  };

  return updateFn;
}
