import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, FormInstance, Modal, Row, Space } from "antd";
import { each, snakeCase } from "lodash";
import { ReactElement, useEffect, useState } from "react";

import { useAppDispatch } from "core/app/store";
import { useUpdateTask } from "core/shared/task/hooks/useUpdateTask";
import { IDictionaryItem } from "core/shared/interfaces";
import { setTask } from "core/app/store/task-slice";
import { ITask } from "core/app/api/task/interfaces";
import { isITSM } from "core/shared/task/hooks/useTask";
import dayjs, { getTimeFromHours } from "core/shared/tools/dayjs";
import Text from "core/lib/Text/Text";
import { hasEmptyAttachmentsCategory } from "core/shared/task";
import { useNetworkState } from "core/shared/main/hooks/useNetworkState";
import { useDisabled } from "core/shared/task/hooks/useDisabled";
import { useGetTaskAttachmentsQuery, useGetTaskStatesQuery } from "core/app/api/task";
import { StateNamesEnum, TaskAttachmentCategoryEnum } from "core/app/api/task/enums";

import { EmptyAttachmentWarning } from "../../shared/Media/EmptyAttachmentWarning";

export function prepareFields(fieldsValue: Record<string, any>) {
  const fieldsToUpdate: Record<string, any> = {};
  each(fieldsValue, (fieldValue, key) => {
    fieldsToUpdate[snakeCase(key)] = fieldValue;
  });
  if (fieldsToUpdate.elapsed_time && typeof fieldsToUpdate.elapsed_time === "number") {
    // eslint-disable-next-line camelcase
    const { elapsed_time } = fieldsToUpdate;
    fieldsToUpdate.elapsed_time = getTimeFromHours(elapsed_time);
  }
  if (!fieldsToUpdate.no_photo) {
    fieldsToUpdate.no_photo_reason = "";
    fieldsToUpdate.no_photo_reason_type_id = null;
  }

  return fieldsToUpdate;
}

interface RecoveryAndOtherTaskStateButtonsProps {
  task: ITask;
  form: FormInstance;
}

/** Кнопки состояния для задания на АВР, ЗНО, доп. работы. */
function RecoveryAndOtherTaskStateButtons({ task, form }: RecoveryAndOtherTaskStateButtonsProps): ReactElement {
  const dispatch = useAppDispatch();
  const { data: { results: states } = {} } = useGetTaskStatesQuery("");
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task?.discriminator);
  const [newStateName, setNewStateName] = useState<StateNamesEnum>();
  const { isOnline } = useNetworkState();
  const { data: attachments } = useGetTaskAttachmentsQuery(task.id);
  const taskDisabled = useDisabled({ skipStatusCheck: true });
  const disabled = taskDisabled || !isOnline;
  const values = Form.useWatch([], form);

  const handleClick = (onOk: () => void) => {
    if (isITSM(task)) {
      Modal.confirm({
        title: <Text boldness="black">Внимание</Text>,
        icon: <ExclamationCircleOutlined />,
        content: "Данное действие приведёт к изменению заявки в Creatio",
        okText: "Подтвердить",
        cancelText: "Отменить",
        onOk,
      });
    } else {
      onOk();
    }
  };

  const changeStatus = (stateName: StateNamesEnum, closingDate: string | null = null) => {
    setNewStateName(stateName);
    if (!states) {
      return;
    }
    const newState = states.find((state: IDictionaryItem) => state.name === stateName);
    if (task && newState) {
      const fieldsToUpdate = prepareFields(values);
      updateTaskRequest({
        id: task.id,
        ...fieldsToUpdate,
        state_id: newState.id,
        closing_date: closingDate,
      });
    }
  };

  const handleCloseClick = () => {
    const hasVandalActions = values?.is_vandalism_detected;
    const handleOk = () => {
      handleClick(() => {
        changeStatus(StateNamesEnum.Closed, dayjs().format());
      });
    };
    if (hasEmptyAttachmentsCategory(attachments, hasVandalActions)) {
      const categories = Object.values(TaskAttachmentCategoryEnum).filter(
        (category) => hasVandalActions || category !== TaskAttachmentCategoryEnum.Vandalism
      );
      Modal.confirm({
        title: <Text boldness="black">Внимание</Text>,
        icon: <ExclamationCircleOutlined />,
        content: <EmptyAttachmentWarning attachments={attachments} categories={categories} />,
        okText: "Подтвердить",
        cancelText: "Отменить",
        onOk: handleOk,
      });
    } else {
      handleOk();
    }
  };

  useEffect(() => {
    if (updateTaskResult.data) {
      dispatch(setTask(updateTaskResult.data));
    }
  }, [dispatch, updateTaskResult.data]);

  if (task?.state && task.state.name !== StateNamesEnum.Closed) {
    return (
      <Row justify="end" className="mb-2">
        <Col>
          <Space>
            {task.state.name !== StateNamesEnum.InWork && (
              <Button
                onClick={() => {
                  handleClick(() => {
                    changeStatus(StateNamesEnum.InWork);
                  });
                }}
                type="primary"
                disabled={disabled}
                loading={updateTaskResult.isLoading && newStateName === StateNamesEnum.InWork}
              >
                ВЗЯТЬ В РАБОТУ
              </Button>
            )}
            {task.state.name === StateNamesEnum.InWork && (
              <>
                <Button
                  onClick={() => {
                    form.setFieldValue("newState", StateNamesEnum.OnPause);
                    form.validateFields().then(() => {
                      handleClick(() => {
                        changeStatus(StateNamesEnum.OnPause);
                      });
                    });
                  }}
                  type="primary"
                  disabled={disabled}
                  loading={updateTaskResult.isLoading && newStateName === StateNamesEnum.OnPause}
                >
                  ПРИОСТАНОВИТЬ
                </Button>
                <Button
                  onClick={() => {
                    form.setFieldValue("newState", StateNamesEnum.Closed);
                    form.validateFields().then(() => {
                      handleCloseClick();
                    });
                  }}
                  type="primary"
                  loading={updateTaskResult.isLoading && newStateName === StateNamesEnum.Closed}
                  disabled={disabled}
                >
                  ЗАКРЫТЬ ЗАДАЧУ
                </Button>
              </>
            )}
          </Space>
        </Col>
      </Row>
    );
  }
  return <div />;
}

export default RecoveryAndOtherTaskStateButtons;
