import { Row, Col, Form, InputNumber } from "antd";
import { ReactElement } from "react";

import Text from "core/lib/Text/Text";
import { CheckListItem } from "core/widgets/TaskModals/shared/CheckListItem";
import { IMaintenanceExecution } from "core/app/api/interfaces";
import { useDisabled } from "core/shared/task/hooks/useDisabled";

interface Props {
  maintenanceExecution: IMaintenanceExecution;
  index: number;
}

export function MaintenanceExecution({ maintenanceExecution, index }: Props): ReactElement {
  const isDisabled = useDisabled();

  return (
    <Row gutter={16}>
      <Col span={13}>
        <Form.Item name={["maintenance_execution", index, "id"]} label="id" hidden>
          <InputNumber />
        </Form.Item>
        {/* На форме не используется, но на бэке это поле обязательное */}
        <Form.Item name={["maintenance_execution", index, "values"]} label="values" hidden>
          <InputNumber />
        </Form.Item>
        <div className="mb-3">
          <div className="mb-1">
            <Text size="label1" boldness="semibold">
              РАБОТЫ
            </Text>
          </div>
          {maintenanceExecution.checklist.map((checkItem, checkItemIndex) => (
            <Form.Item
              style={{ marginBottom: 8 }}
              key={checkItem.id}
              name={["maintenance_execution", index, "checklist", checkItemIndex]}
            >
              <CheckListItem show disabled={isDisabled} />
            </Form.Item>
          ))}
        </div>
      </Col>
    </Row>
  );
}
