import { Form } from "antd";
import qs from "qs";

import { useAppSelector } from "core/app/store";
import { useGetItsmCategoriesQuery } from "core/app/api/task";

import { taskTypeToCategoryGroupFilterMap } from "../constants";

export function useItsmCategories() {
  const isMounted = Form.useWatch("isMounted");
  const { createTaskModalType } = useAppSelector((state) => state.temp);
  let typeGroupCategory: string | undefined;
  if (createTaskModalType) {
    typeGroupCategory = taskTypeToCategoryGroupFilterMap[createTaskModalType];
  }

  return useGetItsmCategoriesQuery(
    qs.stringify({
      is_deactivated: false,
      is_mounted: isMounted,
      type: typeGroupCategory,
    })
  );
}

export function useRequiredCustomerApproval() {
  const requestCategoryId = Form.useWatch("requestCategoryId");
  const { data } = useItsmCategories();
  const requestCategory = data?.find((item) => item.id === requestCategoryId);
  return !!requestCategory?.required_customer_approval;
}
