import { ReactElement } from "react";

import { ITask } from "core/app/api/task/interfaces";

import { Field } from "../Field";

interface EffortProps {
  task: ITask;
}

/** Оставшиеся плановые трудозатраты по системе. Используется в карточке задачи (но не при создании задачи). */
export function TaskModalEffortBySystemField({ task }: EffortProps): ReactElement {
  return <Field label="План. трудоз. по системе (остаток)" value={`${task.effort_by_lso} чч.`} valueClassName="blue" />;
}
