import qs from "qs";
import { Form } from "antd";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetOossaSystemsQuery, useGetOossaWorkTypesQuery } from "core/app/api/units/OOSSA";
import { IOossaObjectTag } from "core/app/api/units/OOSSA/interfaces";
import { OossaSystemTypeEnum, OossaWorkTypeGroupEnum } from "core/app/api/units/OOSSA/enums";

import { FIELD_NAMES } from "./constants";

export function useIsFieldRequired(fieldName: string): boolean {
  const workTypeId = Form.useWatch(FIELD_NAMES.WORK_TYPE);
  const systemId = Form.useWatch(FIELD_NAMES.SYSTEM);
  const objectId = Form.useWatch(FIELD_NAMES.OBJECT);

  const { data: workTypes } = useGetOossaWorkTypesQuery("");
  const workType = workTypes?.find((item) => item.id === workTypeId);

  const { data: systems } = useGetOossaSystemsQuery(qs.stringify({ object_id: objectId }) ?? skipToken);
  const system = systems?.results?.find((item) => item.id === systemId) as IOossaObjectTag;

  if (workType?.group === OossaWorkTypeGroupEnum.REPAIR) {
    if (system?.code_enum === OossaSystemTypeEnum.SMZIS) {
      return fieldName !== FIELD_NAMES.TECH_NODE;
    }

    if ([OossaSystemTypeEnum.ASKUE, OossaSystemTypeEnum.ASKUE_EE].includes(system?.code_enum)) {
      return fieldName !== FIELD_NAMES.EQUIPMENT;
    }

    return true;
  }

  return false;
}
