import { createContext } from "react";

import { ITask } from "core/app/api/task/interfaces";

export interface ILsoMaintenanceContextValues {
  showErrorMessage: boolean;
  setShowErrorMessage: (show: boolean) => void;
  isTaskRequestLoading: boolean;
  isTaskRequestError: boolean;
  task?: ITask;
}

export const LsoMaintenanceContext = createContext<ILsoMaintenanceContextValues>({
  showErrorMessage: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowErrorMessage: () => {},
  isTaskRequestLoading: false,
  isTaskRequestError: false,
});
