import { Row, Col, Form } from "antd";
import { ReactElement } from "react";

import Text from "core/lib/Text/Text";
import { CheckListItem } from "core/widgets/TaskModals/shared/CheckListItem";
import { ICheckListItem } from "core/app/api/units/SGPS/interfaces";
import { useDisabled } from "core/shared/task/hooks/useDisabled";

interface Props {
  checkList: ICheckListItem[];
}

export function CheckList({ checkList }: Props): ReactElement {
  const isDisabled = useDisabled();

  return (
    <Row gutter={16}>
      <Col span={13}>
        <div className="mb-3">
          <div className="mb-1">
            <Text size="label1" boldness="semibold">
              РАБОТЫ
            </Text>
          </div>
          {checkList.map((checkItem, checkItemIndex) => (
            <Form.Item style={{ marginBottom: 8 }} key={checkItem.id} name={["checklist", checkItemIndex]}>
              <CheckListItem show disabled={isDisabled} />
            </Form.Item>
          ))}
        </div>
      </Col>
    </Row>
  );
}
