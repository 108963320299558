import { Tag } from "antd";

import { LKU_EQUIPMENT_TYPE_CODES_TAG_COLORS } from "core/app/api/units/LKU/constants";

interface IObjectNameProps {
  object: {
    object_name: string;
    equipment_type_name: string;
    code_enum: string;
  };
}

export function LkuObjectName({ object }: IObjectNameProps) {
  return (
    <>
      {object.object_name}
      {object.code_enum && (
        <>
          <br />
          <Tag color={LKU_EQUIPMENT_TYPE_CODES_TAG_COLORS[object.code_enum]}>{object.equipment_type_name}</Tag>
        </>
      )}
    </>
  );
}
