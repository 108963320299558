import { ReactElement } from "react";

import { useTask } from "core/shared/task/hooks/useTask";

import SgpsContent from "./Content";
import WarningModal from "./WarningModal";

import "../../styles.scss";
import "./styles.scss";

/** Обертка:
 * либо карточка технического обслуживания для навесного оборудования СГПС,
 * либо предупреждающее сообщение про удаление карточки. */
export default function SgpsMountedTaskForm(): ReactElement {
  const task = useTask();

  return (
    <>
      {!!task && <SgpsContent />}
      <WarningModal />
    </>
  );
}
