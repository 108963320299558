import { Button, Space } from "antd";

import { useStateButtonsDisabled } from "core/widgets/AssistanceCarTaskModal/hooks/useDisabled";
import { useCurrentStep } from "core/widgets/AssistanceCarTaskModal/hooks/useCurrentStep";
import { StateNamesEnum } from "core/app/api/task/enums";
import { EtoTaskStepNameEnum } from "core/app/api/task/eto/enums";
import { IEtoTask } from "core/app/api/task/eto/interfaces";

import { useStateButtonsHandlers } from "./hooks/useStateButtonsHandlers";

type TStateButtonsProps = {
  task: IEtoTask;
};

function StateButtons({ task }: TStateButtonsProps) {
  const disabled = useStateButtonsDisabled();
  const { handleCloseClick, handleTakeInWork, handleFinishETO, updateTaskResult } = useStateButtonsHandlers(task);
  const { isLoading } = updateTaskResult;
  const currentStep = useCurrentStep();

  return (
    task.state && (
      <Space className="w-100" direction="vertical">
        {task.state.name === StateNamesEnum.New && (
          <Button
            className="w-100"
            onClick={() => {
              handleTakeInWork();
            }}
            type="primary"
            disabled={disabled}
            loading={isLoading}
            size="large"
          >
            ВЗЯТЬ В РАБОТУ
          </Button>
        )}
        {task.state.name === StateNamesEnum.InWork && currentStep?.step_name !== EtoTaskStepNameEnum.POST_SHIFT_CHECK && (
          <Button
            size="large"
            className="w-100"
            type="primary"
            onClick={() => {
              handleFinishETO();
            }}
            disabled={disabled}
            loading={isLoading}
          >
            ЗАВЕРШИТЬ ЕТО
          </Button>
        )}
        {task.state.name === StateNamesEnum.InWork && currentStep?.step_name === EtoTaskStepNameEnum.POST_SHIFT_CHECK && (
          <Button
            className="w-100"
            onClick={() => {
              handleCloseClick();
            }}
            type="primary"
            disabled={disabled}
            loading={isLoading}
            size="large"
          >
            ЗАКРЫТЬ ЗАДАЧУ
          </Button>
        )}
      </Space>
    )
  );
}

export default StateButtons;
