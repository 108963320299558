import { Col, Row, Form } from "antd";
import { useState } from "react";
import clsx from "clsx";

import Text from "core/lib/Text/Text";
import { RadioGroup } from "core/shared/components";
import icCommentDefault from "core/images/ic-comment-default.svg";
import icCommentDefaultSuccess from "core/images/ic-comment-default-green.svg";
import icCommentSelected from "core/images/ic-comment-selected.svg";
import icCommentSelectedSuccess from "core/images/ic-comment-selected-green.svg";
import { useFieldsDisabled } from "core/widgets/AssistanceCarTaskModal/hooks/useDisabled";
import { useStepFieldsDisabled } from "core/widgets/AssistanceCarTaskModal/hooks/useStepDisabled";
import { EtoTaskStepNameEnum } from "core/app/api/task/eto/enums";
import { IAssistanceCarCheck } from "core/app/api/task/eto/interfaces";

import "./styles.scss";
import TextField from "./TextField";

interface Props {
  check: IAssistanceCarCheck;
  formPath: Array<string | number>;
  stepName: EtoTaskStepNameEnum;
}

function CheckListItem({ check, formPath, stepName }: Props) {
  const { check: label } = check;
  const [showComment, setShowComment] = useState(false);
  const taskFieldsDisabled = useFieldsDisabled();
  const stepFieldsDisabled = useStepFieldsDisabled(stepName);
  const disabled = taskFieldsDisabled || stepFieldsDisabled;

  const form = Form.useFormInstance();
  const errorsField = form.getFieldError([...formPath, "value"]);
  const commentValue = Boolean(form.getFieldValue([...formPath, "comment"]));
  const commentSelected = commentValue ? icCommentSelectedSuccess : icCommentSelected;
  const commentDefault = commentValue ? icCommentDefaultSuccess : icCommentDefault;
  const radioItems = [
    { key: "1", label: "Да", value: true },
    { key: "0", label: "Нет", value: false },
  ];
  return (
    <Row className="radio-field-wrapper" justify="space-between" gutter={[16, 8]} align="top">
      <Col span={16}>
        <Text className="radio-field" size="label2" boldness="bold">
          {label}
        </Text>
      </Col>
      <Col span={2} onClick={() => setShowComment((show) => !show)}>
        <img
          className="radio-field-icon"
          src={showComment ? commentSelected : commentDefault}
          alt={showComment ? "Скрыть комментарий" : "Показать комментарий"}
        />
      </Col>
      <Col span={6}>
        <Form.Item hidden name={[...formPath, "check"]} />
        <Form.Item hidden name={[...formPath, "uuid"]} />
        <Form.Item
          className={clsx({ error: Boolean(errorsField.length) })}
          preserve
          name={[...formPath, "value"]}
          required
          rules={[
            {
              validator: (_, value) => {
                if (value === null) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <RadioGroup disabled={disabled} size="middle" items={radioItems} uppercase={false} />
        </Form.Item>
      </Col>
      <Col
        span={16}
        style={{
          display: showComment ? "block" : "none",
        }}
      >
        <Form.Item name={[...formPath, "comment"]}>
          <TextField disabled={disabled} />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default CheckListItem;
