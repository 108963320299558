import { Tag } from "antd";

import { OpinStateEnum } from "core/app/api/task/opin/enums";
import { OpinStateMap } from "core/shared/opin";

interface OpinStateTagProps {
  state: OpinStateEnum;
}

export function OpinStateTag({ state }: OpinStateTagProps) {
  let color = "geekblue";
  if (state === OpinStateEnum.REJECTED) {
    color = "red";
  }
  if (state === OpinStateEnum.ACCEPTED) {
    color = "green";
  }
  return <Tag color={color}>{OpinStateMap.get(state)}</Tag>;
}
